jQuery(document).ready(function() {

 	$('#cturno').change(function () {
 			 //$('#Add').prop("disabled",true);
 				 $('#divservicios').hide();
 				 $('#carrito').hide();
 				 $('#loaderservicios').show();
				 var fecha=$('#datepicker').val();
 				 var cturno=$('#cturno').val();
 				 $.post("servicios.php",{"fecha":fecha,"cturno":cturno},function(htmlservicios){
 						 $("#divservicios").html(htmlservicios);
 						 $('#loaderservicios').hide();
 						 $("#divservicios").show();
             $('#loadercarrito').show();
 						 $.post("carrito.php",{},function(htmlcarrito){
 								 $("#carrito").html(htmlcarrito);
                 $('#loadercarrito').hide();
 								 $('#carrito').show();
 						 });
 				 });

 	});

	$('#datepicker').change(function () {

		$('#divservicios').hide();
		$('#carrito').hide();
		var fecha=$('#datepicker').val();
		$.post("turno-reserva.php",{"fecha":fecha},function(htmlturnos){
			$("#divturnos").html(htmlturnos);
		});
				 
		// var cturno=$('#cturno').val();
		// $.post("servicios.php",{"fecha":fecha,"cturno":cturno},function(htmlcarrito){
		// 	$("#divservicios").html(htmlcarrito);
		// 	$('#loaderservicios').hide();
		// 	$("#divservicios").show();
		// 	$('#loadercarrito').show();
		// 	$.post("carrito.php",{},function(htmlcarrito){
		// 		$("#carrito").html(htmlcarrito);
		// 		$('#loadercarrito').hide();
		// 		$('#carrito').show();
		// 	});
		// });
	
  	});

	//CALENDARIO

	$(function() {
	    $( "#datepicker" ).datepicker({
	    //changeMonth: true,
	    //changeYear: true,
			defaultDate: -1,
	    showOtherMonths: true,
	    selectOtherMonths: true,
	    minDate: 0,
	    maxDate: new Date(2021, 12 - 1, 31),
	    //showOn: "button",
	    //buttonImage: "calendario/calendar.gif",
	    //buttonImageOnly: true,
	    buttonText: "Selecciona Fecha",
	    dateFormat:"dd/mm/yy",
	    dayNames: [ "Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado" ],
	    dayNamesMin: [ "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa" ],
	    monthNamesShort: [ "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
			monthNames:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
	    firstDay: 1
	    });

	 });

});
