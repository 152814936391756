<template>
  <div v-if="showAlert" class="alert-container">
    <b-alert v-model="showAlert" variant="success" dismissible fade>
      {{message}}
    </b-alert>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    showAlert: Boolean
  }

}
</script>

<style lang='scss' scoped>

.alert-success {
  position: fixed;
  left: 7%;
  top: 22%;
  width: 40vw;
  margin: 40px;
  border: 2px solid rgb(104, 149, 95);
  font-size: 22px;
}
.alert-container {
  z-index: 100;
  position: fixed;
  left: 24%;
  top: 16%;
  width: 55vw;
  height: 260px;
  background: rgba(199, 199, 199, 0.116);
  backdrop-filter: blur(2px);
}
</style>