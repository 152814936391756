<template>
  <div v-if="showCard === true" class="up-modal">
    <b-row class="justify-content-center card-row">
      <b-icon-x-square @click="closeModal" />
      
      <b-col col xs="12" lg="6" class="justify-content-center d-flex margin-all-10">
        <b-col 
          cols="12"
          class="employee-card pt-4"
        >
          <h4 class="mt-4"><strong>Cliente:</strong> {{first_name}} {{last_name}}</h4>
          <h4 class="mt-4"><strong>Dirección:</strong> {{province}}, {{address}}</h4>
          <br>
          <h4 class="mt-4"><strong>Detalle de la compra:</strong></h4>
  
            <div v-if="items.length === 0" class="col-md-12 tabla-compra-listado">
            <div class="table-hover tabla-servicios">
              <table class="table">
                <thead>
                  <tr>
                    <th class="td-servicio"></th>
                    <th class="td-cantidad"></th>
                    <th class="td-precio"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="td-servicio">No hay servicios seleccionados.</td>
                    <td class="td-cantidad">
                      <p></p>
                      <ul class="list-unstyled">
                        <li></li>
                        <li></li>
                      </ul>
                    </td>
                    <td class="td-precio"></td>
                  </tr>
              
                </tbody>
              </table>
            </div>
          </div>

          <div v-else>
            <div class="col-md-12 tabla-compra-listado">
              <div class="table-hover tabla-servicios">
                <ul class="list-inline text-left tabla-general">
                  <li>
                    <p class="tit">Fecha: <span>{{ date }}</span></p>
                 
                  </li>
                  <li>
                    <p class="tit">Turno: <span>{{ hour }}</span></p>
                    
                  </li>
                </ul>
                <table class="table table-responsive">
                  <thead>
                    <tr>
                      <th class="td-servicio">Servicios</th>
           
                      <th class="td-precio">Cantidad</th>
                      <th class="td-precio">Precio*</th>
                      <th class="td-precio">Valor</th>
                      <th class="td-borrar">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items" :key="item.id">
                      <td class="td-servicio">{{ item.name }} -</td>
                      <td class="td-precio">{{ item.quantity }}</td>
                      <td class="td-precio">{{ formatPrice(item.price) }} €</td>
                      <td class="td-precio">{{ formatPrice(item.price * item.quantity) }} €</td>

                    </tr>
                    <tr >
                      <td class="td-servicio">Desplazamiento</td>
                      <td >1</td>
                      <td class="td-precio"> {{formatPrice(feeValue)}} €</td>
                      <td class="td-precio"> {{formatPrice(feeValue)}} €</td>
                    </tr>
                    <tr class="total">
                      <td
                        class="td-total"
                        style="padding-bottom: 25px;"
                        colspan="3"
                      >
                        <span>TOTAL:</span>
                        {{ formatPrice(totalPrice) }} €
                      </td>
                      <td class="td-borrar" style="padding-bottom: 25px;" >
                        
                      </td>
                    </tr>
                    
                  </tbody>
                  
                </table>
                <div v-html="htmlBtnPay"></div>
                <br>
                <br>
              </div>
            </div>
          </div>
      

        </b-col>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { API_INFO } from "@/constants.js";
import axios from "axios";
import delay from "delay";

export default {
  
  props: {
    
    showCard: Boolean,
    items: Array,
    date: String,
    hour: String,
    clients: Number,
    showTable: Boolean,
    first_name: String,
    last_name: String,
    province: String,
    address:String,
    htmlBtnPay: String,
    feeValue: Number,
    totalPrice: Number
  },
  data: () => ({
    code: "cd30",
    codeValid: false,
    codeValidAlert: false,
    codeErrorAlert: false,
    user: {}
  }),
  mounted() {
    if(localStorage.getItem("data") != '') {
      this.user = localStorage.getItem("data");
    }
  },
  methods: {
    closeModal() {
      this.$emit("close-modal")
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },    
  },
};
</script>

<style lang="scss" scoped>
.margin-all-10{
  margin: 10px;
}
.card-row {
  position: relative;
  top: 20vh;
  // max-height: 84vh;
  // overflow: auto; 
}

.employee-card {
  background:rgb(241, 241, 241);
  border-radius: 15px;
  border: 4px solid rgb(151, 159, 173);
  min-height: 270px;
  width: 25vw;
  p {
    font-weight: 700;
    margin-bottom: 10px;
  }
  h3 {
    position: relative;
    right: -8%;
  }
}

.bi-x-square {
  width: 34px;
  height: 34px;
  position: absolute;
  top: -5%;
  right: 45px;
  cursor: pointer;
  background: antiquewhite;
  z-index: 130;
  &:hover {
    background: rgba(255, 117, 98, 0.822);
  }
}
button {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
  position: relative;
  right: -30%;
  top: -20%;
}
@media (max-width: 600px) {
  .employee-card {
    width: 100% !important;
    p {
      font-weight: 700;
      margin-bottom: 10px;
    }
    h3 {
      position: relative;
      right: -8%;
    }
  }

  .card-row{
    top: 10vh;
  }

}
</style>
