<template>
  <div v-show="addLocation === true" class="up-modal">
    <b-row class="justify-content-center">
      <b-col class="justify-content-center d-flex">
        <b-row class="mt-5 location-row">
          <p class="text-center card-title mt-5"> ALTA UBICACIÓN SERVICIOS A DOMICILIO</p>
          <b-col cols="12" class="justify-content-center d-flex mb-2">
            <td class="">
              <tr>Centro:</tr>
              <tr>Provincia:</tr>
              <tr>Localidad:</tr>
              <tr>Código Postal:</tr>
              <tr>Importe Adicional:</tr>
              <tr>Activo:</tr>
              <tr>Servicios a Excluir:</tr>
            </td>
            <td class="">
              <tr class="pt-0">
                <div v-if="centers !== ''">
                  <b-form-select v-model="center" size="lg">
                    <b-form-select-option value="null">Seleccionar centro</b-form-select-option>
                    <b-form-select-option 
                      v-for="i in centers"
                      :key="i.id"
                      :value="i.id"
                    >
                      {{i.name}}
                    </b-form-select-option>
                  </b-form-select>
                </div>
              </tr>
              <tr>{{selectedProvince}}</tr>
              <tr><input v-model="locality" type="text" class="" /></tr>
              <tr><input v-model="cp" type="text" class="" /></tr>
              <tr><input v-model="ratePercent" type="text" class="" /></tr>
              <tr class="checkbox">
                <b-form-checkbox v-model="active" switch size="lg" />
              </tr>
              <tr>
                <div v-if="services !== ''">
                  <b-form-select 
                    v-model="excludedServ"
                    multiple
                    :select-size="1"
                  >
                    <b-form-select-option 
                      v-for="i in services"
                      :key="i.id"
                      :value="i.id"
                    >
                      {{i.name}}
                    </b-form-select-option>
                  </b-form-select>
                </div>
              </tr>             
            </td>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-button 
              v-if="location !== ''"
              @click="delItem(location.id)"
              class="mt-5 mx-4"
              variant="danger"
            >
              Eliminar
            </b-button> 
            <b-button @click="closeModal()" class="mt-5 mx-4" variant="outline-primary">
              Cancelar
            </b-button>
            <b-button @click="createOrEditLocation" class="mt-5 mx-4" variant="success">
              <b-icon-check2-square class="mr-3" />
              Guardar Cambios
            </b-button>            
          </b-col>          
        </b-row>
      </b-col>
    </b-row>
    <AlertError 
      :message="errorMessage"
      :showAlert="showAlertError"
    />
  </div>
</template>
<script>
import { API_INFO } from "@/constants.js";
import AlertError from "@/components/alerts/AlertError.vue"

import axios from "axios";
import delay from 'delay';

export default {

  props: {
    addLocation: Boolean,
    location: Object,
    centers: Array,
    services: Array
  },
  components: {
    AlertError
  },
  data() {
    return {
      id: "",
      locality: "",
      cp: "",
      ratePercent: "",
      excludedServ: [],
      center: null,
      active: false,

      initLocality: "",
      initCp: "",
      initRatePercent: "",
      initExcludedServ: [],
      initCenter: null,
      initActive: false,

      reqMethod: "",
      patchBody: {},
      successMessage: "",
      errorMessage: "",
      showAlertError: false
    };
  },
  watch: {
    addLocation() {
      this.checkLocationExist()
    } 
  },
  computed: {
    selectedProvince() {
      if(this.center !== null & this.centers !== "") {
        var selected = this.centers.filter(el => el.id === this.center)
      }
      try {
        return selected[0].province
      } catch {
        return selected
      }
    }
  },
  methods: {
    closeModal(update) {
      this.province = "",
      this.locality = "",
      this.cp = "",
      this.ratePercent = "",
      this.excludedServ = [],
      this.center = null,
      this.active = false
      if(update) {
        this.$emit("close-modal-location-update", {
          message: this.successMessage
        })
      } else {
        this.$emit("close-modal-location")
      }
      this.successMessage = ""
    },
    checkLocationExist() {
      if(Object.keys(this.location).length > 0) {
        this.id = this.location.id
        this.center = this.location.center_id
        this.locality = this.location.locality
        this.cp = this.location.postal_code
        this.ratePercent = this.location.fee.slice(0, -3)
        this.active = this.location.active
        this.location.excluded_services.forEach(el => {
          this.excludedServ.push(el.id)
        })

        this.initCenter = this.location.center_id
        this.initLocality = this.location.locality
        this.initCp = this.location.postal_code
        this.initRatePercent = this.location.fee.slice(0, -3)
        this.initActive = this.location.active
        this.location.excluded_services.forEach(el => {
          this.initExcludedServ.push(el.id)
        })
        this.reqMethod = "PATCH"
      } else {
        this.reqMethod = "POST"
      }
    },
    checkModifiedField() {
      this.patchBody = {}
      if(this.initCenter != this.center) {
        this.patchBody.center_id = this.center
      }
      if(this.initLocality != this.locality) {
        this.patchBody.locality = this.locality
      }
      if(this.initCp != this.cp) {
        this.patchBody.postal_code = this.cp
        this.patchBody.locality = this.locality
      }
      if(this.initRatePercent != this.ratePercent) {
        this.patchBody.fee = this.ratePercent
      }
      if(this.initActive != this.active) {
        this.patchBody.active = this.active
      }
      if(this.initExcludedServ != this.excludedServ) {
        this.patchBody.excluded_services = this.excludedServ
      }
    },
    delItem(id) {
      var conf = confirm("want to delete?")
      if(conf) {
        this.deleteLocation(id)
      }
    },
    async deleteLocation(id) {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.delete(
          API_INFO + "/dashboard/center-postal-code/" + `${id}/`,
          options
        );
        if (resp.status === 204) {
          this.successMessage = "¡Elemento eliminado!"
          this.closeModal(true)
        }
      } catch (e) {
        console.log(e);
        this.errorMessage = "No ha sido posible procesar su petición"
        this.showAlertError = true
        await delay(3600)
        this.showAlertError = false
      }
    },
    async createOrEditLocation() {
      var resp
      const options = {
        headers: { Authorization: "JWT " + localStorage.accessToken },
      }
      const body = {
        center_id: this.center,
        postal_code: this.cp,
        locality: this.locality,
        fee: this.ratePercent,
        active: this.active,
        excluded_services: this.excluded_services
      }
      try {
        if(this.reqMethod === "POST") {
          resp = await axios.post(
            API_INFO + "/dashboard/center-postal-code/",
            body,
            options
          );
          console.log("post");
        } else {
          this.checkModifiedField()
          console.log("patch");
          resp = await axios.patch(
            API_INFO + `/dashboard/center-postal-code/${this.id}/`,
            this.patchBody,
            options
          );
        }
        if (resp.status === 201) {
          console.log("OK!");
          this.successMessage = "exitosamente!"
          this.closeModal(true)
        } else if (resp.status === 200) {
          console.log("OK!");
          this.successMessage = "¡Elemento guardado exitosamente!"
          this.closeModal(true)
          this.patchBody = {}
        }
      } catch (e) {
        let message = []
        let errorDetail = ""
        for(let i in e.response.data) {
          errorDetail = ""
          errorDetail = i + ": " + e.response.data[i][0]
          message.push(errorDetail)
        }
        this.errorMessage = message
        this.showAlertError = true
        await delay(4600)
        this.showAlertError = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.location-row {
  border-radius: 20px !important;
  border: 4px solid #316d9e;
  background:  #eeeeee;
  padding: 15px 15px 35px 15px;
  max-width: 630px;
  position: relative;
  top: 9vh;
  td {
    width: 42%;
    position: relative;
    right: 5%;
    &:last-of-type {
      tr {
        border: 1px solid #000;
        background: #d9edf7;
        &:last-of-type {
          height: 75px;
          div select {
            min-height: 65px;
          }
        }
      }
    }
  }
  tr {
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 5px;
    text-align: center;
    display: block;
    margin-top: 4px;
    height: 37px;
  }
}
button {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
}
input {
  border: none;
  background: transparent;
  height: 22px;
  text-align: center;
  &:focus {
    outline: none;
  }
}
select {
  max-height: 31px;
  background:#d9edf7;
  border: none;
  font-size: 15.5px;
  text-align-last:center;
  color: rgb(27, 27, 27);
}
.checkbox {
  border: none !important;
  background: transparent !important;
  div .custom-control-label::before,
  .custom-control-label::after {
    display: none !important;
  }
}

</style>
