<template>
  <div class="full-container">
  <NavBar />
  <b-container fluid class="h-100 gray">

    <b-row class="title">
      <b-col 
        col
        xs="12"
        md="6"
        class="d-flex justify-content-center div-main"
      >
        <h1 class="m-auto">Alta de ubicaciones.</h1>
      </b-col>
      <b-col cols="6" class="d-flex justify-content-center hidden-xs" >
        <h1 class="m-auto">Alta de servicios a domicilio.</h1>
      </b-col>
    </b-row>
    <br >
    <b-row>
      <b-col col xs="12" md="6" class="d-flex justify-content-center search title2">
        <table class="search">
          <tr>
            <td>Código postal:</td>
            <td><input v-model="filterCP" type="text" class="filterInput" /></td>
            <td rowspan="2" colspan="2">
              <b-button @click="getLocations" class="search-button" variant="success">
                <b-icon-search class="icon" />
              </b-button>
              <b-button @click="clearSearch" v-if="showClearSearch"  class="search-button" variant="danger">
                <b-icon-trash class="icon" />
              </b-button>
            </td>
          </tr>
          <tr>
            <td>  Provincia:</td>
            <td>
              <b-form-select v-model="filterProvince" size="lg">
                <b-form-select-option value="null">Seleccionar provincia</b-form-select-option>
                <b-form-select-option 
                  v-for="i in provinces"
                  :key="i.id"
                  :value="i.id"
                >
                  {{i.name}}
                </b-form-select-option>
              </b-form-select>
            </td>
          </tr>

          
        </table>
      </b-col>
 
    </b-row>
    <br>
    <b-row class="">
      <b-col col xs="12" md="6" class=" justify-content-center location-col">
        <b-row class="justify-content-center " v-if="locations.length == 0">
          <b-col 
            cols="12"
            class="justify-content-center "            
          >
            No se encontraron datos en la búsqueda.
          </b-col>
          <b-col 
            cols="12"
            class="d-flex justify-content-center p-4 mt-5"
            style="max-height: 75px;height: 75px; background-color: #c1c3b9;"
          >
            <b-button 
              @click="upLocation"
              class="mx-5"
              variant="success"
            >
              <b-icon-plus-circle class="mr-3" />
              Alta Ubicación
            </b-button>
            <b-button 
              @click="exportLocations"
              class="mx-5" 
              variant="info"
            >
              <b-icon-download class="mr-3" />
              Exportar
            </b-button>
          </b-col>
        </b-row>
        <b-row class="justify-content-center " v-if="locations.length > 0">
          <b-col 
            cols="12"
            class="justify-content-center "            
          >
            <table class="table table-responsive">
            <td class="long-td">
              <th class="blue-header">Provincia</th>
              <tr 
                v-for="i in locations"
                :key="i.id"
                @click="getLocationDetail(i.id)"
              >
                {{i.province === "" ? "-" : i.province}}
              </tr>
            </td>
            <td class="long-td">
              <th class="blue-header">Localidad</th>
              <tr 
                v-for="i in locations"
                :key="i.id"
                @click="getLocationDetail(i.id)"
              >
                {{i.locality === "" ? "-" : i.locality}}
              </tr>
            </td>
            <td>
              <th class="blue-header">CP</th>
              <tr 
                v-for="i in locations"
                :key="i.id"
                @click="getLocationDetail(i.id)"
              >
                {{i.postal_code}}
              </tr>
            </td>
            <td>
              <th class="light-header">A Domicilio</th>
              <tr 
                v-for="i in locations"
                :key="i.id"
                @click="getLocationDetail(i.id)"
              >
                {{i.active}}
              </tr>
            </td>
            <td>
              <th class="light-header">Servicios Excluidos</th>
              <tr 
                v-for="(i, v) in locations"
                :key="v"
                @click="getLocationDetail(i.id)"
              >
                {{i.excluded_services ?
                  i.excluded_services.length !== 0 ?
                  showExcludedServiceName(v) :
                  "-" :
                  "-"
                }}
              </tr>
            </td>
            <td>
              <th class="light-header">Importe Adicional</th>
              <tr 
                v-for="i in locations"
                :key="i.id"
                @click="getLocationDetail(i.id)"
              >
                {{i.fee.slice(0, -3)}} €
              </tr>
            </td>
            </table>
          </b-col> 
          
          <b-col 
            cols="12"
            class="d-flex justify-content-center p-4"
            style="max-height: 75px;height: 75px; background-color: #c1c3b9;"
          >
            <b-button 
              @click="upLocation"
              class="mx-5"
              variant="success"
            >
              <b-icon-plus-circle class="mr-3" />
              Alta Ubicación
            </b-button>
            <b-button 
              @click="exportLocations"
              class="mx-5" 
              variant="info"
            >
              <b-icon-download class="mr-3" />
              Exportar
            </b-button>
          </b-col>
        </b-row>          
      </b-col>
      <b-col  col xs="12" md="6" class=" justify-content-center " >
        <b-col cols="12" class="d-flex justify-content-center visible-xs margin-top-20" >
          <h1 class="m-auto">Alta de servicios a domicilio.</h1>
        </b-col>

        <b-col class="p-0 " v-bind:class="{ divServices: services.length > 4 }">
          <b-row 
            v-for="i in services"
            :key="i.id"
            @click="getServiceDetail(i.id)"
            class="w-100 service-row m-0 my-2"
          >
            <div cols="4" class="long-col">
              <h3>{{i.name}}</h3>
              <p><strong>Grupo:</strong> {{i.group}}</p>
              <p><strong>Duración:</strong> {{i.duration}} minutos</p>
              <p><strong>Descripción:</strong> {{i.description}}</p>
              

            </div>
            <div class="long-col">
              <p><strong>Equipamiento:</strong> 
                <span
                  v-for="j in i.equipment"
                  :key="j.id"
                >
                {{j.name}}, 
                </span>
              </p>
            </div>
            <div class="short-col">
              <h3>{{i.identifier}}</h3>
            </div>
            <div 
              v-if="i.image"
              class="img-col short-col"              
              :style="`background: url('${i.image}')`"
            >
            </div>
          </b-row>
        
         
        </b-col>

            <b-col 
              class="justify-content-center p-4 mt-5"
              style="max-height: 75px;height: 75px; background-color: #c1c3b9;"
            >
              <b-button 
                @click="getCenters();
                        showAddService = !showAddService;"
                class="mx-5"
                variant="success"
              >
                <b-icon-plus-circle class="mr-3" />
                Alta Servicio
              </b-button>
              <b-button
                class="mx-5"
                variant="info"
                @click="exportServices"
              >
                <b-icon-download class="mr-3" />
                Exportar
              </b-button>              
            </b-col>
 
      </b-col>
      
      <AddLocation 
        :addLocation.sync="showAddLocation"
        :location="locationDetail"
        :centers="centers"
        :services="services"
        @close-modal-location-update="closeModalLocationAndUpdate"
        @close-modal-location="closeModalLocation"
      />
      <AddService 
        :addService="showAddService"
        :service="serviceDetail"
        :centers="centers"
        :locations="locations"
        @close-modal-service="closeModalService"
      />
      <AlertSuccess
        :message="alertMessage"
        :showAlert="showAlertSuccess"
      />
    </b-row>
    <br>

  </b-container>
  </div>
</template>

<script>
import AddLocation from "@/components/dashboard/AddLocation.vue"
import AddService from "@/components/dashboard/AddService.vue"
import AlertSuccess from "@/components/alerts/AlertSuccess.vue"
import NavBar from "@/components/NavBar.vue"
import { API_INFO } from "@/constants.js";
import axios from "axios";
import delay from 'delay';


export default {
  components: {
    AddLocation,
    AddService,
    AlertSuccess,
    NavBar
  },
  data() {
    return {
      center_name: "Málaga",
      filter_options: "employee",
      showEmplCard: true,
      showAddLocation: false,
      showAddService: false,
      services: [],
      serviceDetail: {},
      locations: [],
      locationDetail: {},
      centers: [],
      provinces: "",
      filterCP: "",
      filterProvince: null,
      alertMessage: "",
      showAlertSuccess: false,
      showClearSearch: false,
      validationShowLocationDetail: false,
      returnedCp: []
    }
  },
  created() {
    this.getServices()
    this.getLocations()
    this.getProvinces()
  },
  methods: {
    upLocation(){
      if(this.validationShowLocationDetail == false){
        this.locationDetail = ''
        this.validationShowLocationDetail = true;
      }
      this.showAddLocation = true;
      
      this.getCenters()
    },
    showExcludedServiceName(i) {
      var names = ""
      for( var j in this.locations[i].excluded_services){
        names = names +  this.locations[i].excluded_services[j].name + ","
      }
      names = names.slice(0, -1);
      return names
    },
    async getServices() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(API_INFO + "/dashboard/services/", options);
        if (resp.status === 200) {
          this.services = resp.data
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getServiceDetail(id) {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/services/" + `${id}/`,
          options
        );
        if (resp.status === 200) {
          this.serviceDetail = resp.data
          // await this.filterCpResponse(this.serviceDetail.enable_postal_codes)
          this.getCenters()
          this.showAddService = true
        }
      } catch (e) {
        console.log(e);
      }
    },
    async exportServices() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        }, responseType: "blob" ,
      };
      try {
        const resp = await axios.get(API_INFO + "/dashboard/services/export/", options);
        if (resp.status === 200) {
          const url = window.URL.createObjectURL(new Blob([resp.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", "Services.xlsx")
          document.body.appendChild(link)
          link.click()
        }
      } catch (e) {
        console.log(e);
      }
    },
    async exportLocations() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        }, responseType: "blob" ,
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/center-postal-code/export/",
          options
        );
        if (resp.status === 200) {
          //console.log(resp.data)
          const url = window.URL.createObjectURL(new Blob([resp.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", "centerPostalCode.xlsx")
          document.body.appendChild(link)
          link.click()
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getLocations() {
      let resp
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        if(this.filterCP === "" & this.filterProvince === null) {
          resp = await axios.get(API_INFO + "/dashboard/center-postal-code/", options);
        } else if(this.filterCP !== "" & this.filterProvince === null) {
          resp = await axios.get(
            API_INFO + "/dashboard/center-postal-code/" + `?postal_code=${this.filterCP}`,
            options);

        } else if(this.filterCP === "" & this.filterProvince !== null) {
          resp = await axios.get(
            API_INFO + "/dashboard/center-postal-code/" + `?province_id=${this.filterProvince}`,
            options);

        } else {
          resp = await axios.get(
            API_INFO +
            "/dashboard/center-postal-code/" +
            `?postal_code=${this.filterCP}&province_id=${this.filterProvince}`,
            options);
        }
        if (resp.status === 200) {
          this.locations = resp.data
          // this.filterCpResponse()
          if(this.filterCP !== "" || this.filterProvince !== null){
            this.showClearSearch = true
          }
          
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getLocationDetail(id) {
      this.getCenters()
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/center-postal-code/" + `${id}/`,
          options
        );
        if (resp.status === 200) {
          this.locationDetail = resp.data
          this.showAddLocation = true
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getProvinces() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        if(this.provinces === "") {
          const resp = await axios.get(
            API_INFO + "/provinces/",
            options
          );
          if (resp.status === 200) {
            this.provinces = resp.data
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getCenters() {
      const options = {
        headers: {Authorization: "JWT " + localStorage.accessToken}
      };
      try {
        if(this.centers.length === 0) {
          const resp = await axios.get(API_INFO + "/dashboard/centers/", options);
          if (resp.status === 200) {
            this.centers = resp.data
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async closeModalService(message) {
      this.showAddService = false
      this.getServices()
      this.serviceDetail = ""
      this.centers = ""
      if(message["message"] !== "") {
        this.alertMessage = message["message"]
        this.showAlertSuccess = true
        await delay(3500)
        this.showAlertSuccess = false
      }
    },
    async closeModalLocationAndUpdate(message) {
      this.showAddLocation = false
      this.getLocations()
      this.locationDetail = ""
      if(message["message"] !== "") {
        this.alertMessage = message["message"]
        this.showAlertSuccess = true
        await delay(3500)
        this.showAlertSuccess = false
      }
    },
    closeModalLocation() {
      this.showAddLocation = false
      this.locationDetail = ""
    },
    clearSearch() {
      this.filterCP = "";
      this.filterProvince = null;
      this.showClearSearch = false;
      this.getLocations();
    },
    // filterCpResponse(codes) {
    //   let existingCp = []
    //   if(!codes) {
    //     this.locations.forEach(el => {
    //       this.returnedCp.push(el.postal_code)
    //     })
    //   } else {
    //     codes.forEach(el => {
    //       if(this.returnedCp.includes(el)) {
    //         existingCp.push(el)
    //       }
    //     })
    //   }
    //   this.serviceDetail.enable_postal_codes = existingCp
    // }
  }
}
</script>

<style lang='scss' scoped>
.full-container{
  width: 100%;
  position: relative;
}
p {
  font-weight: 500;
}
.gray {
  background-color: #9ea09266 !important;
  min-height: 54vw;
}
.title {
  height: 140px;
  background: #b5b8a78f;
  h1 {
    font-size: 2.5em;
  }
}
.title2 {
  padding: 10px;
  background: #b5b8a78f;
  font-size: 2rem;
  display: flex;
  justify-content: space-evenly;
}
.border {
  border: 1px solid rgb(93, 93, 93)!important;
  background: #F1F1F1;
}

hr {
  height: 170px;
  position: relative;
  top: -20px;
  border-right: dashed 3px rgb(177, 177, 177);
}
.icon {
  font-size: 35px !important;
}
.search-button {
  margin-left: 10px;
  border-radius: 8px;
}
.blue-header {
  background: rgba(104, 167, 250, 0.849);
  height: 55px;
}
.light-header {
  background: rgb(255, 234, 177);
  height: 55px;
}
.location-col {
  th, tr {
    border: 1px solid #000;
    display: block;
    padding: 12px 15px;
    text-align: center;
  }
  tr {
    background: #F1F1F1;
    height: 55px;
  }
}
.long-td {
  width: 17%;
}
button {
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
}
.serv-col {
  width: 100%  !important;
  position: relative;
  top: -103px;
}
.service-row {
  height: fit-content;
  border: 3px solid rgb(106, 147, 224);
  border-radius: 20px;
  background: #F1F1F1;
}
.long-col {
  flex: 0 0 28.333333%;
  max-width: 28.333333%;
  padding: 12px 0px 12px 12px;
  h3 {
    text-align: center;
    font-weight: 900;
    margin: 5px;
    position: relative;
    right: 10px;
    word-wrap: break-word;
  }
  p {
    margin: 8px 0px 10px;
    word-wrap: break-word;
  }
  &:nth-of-type(2) {
    padding-top: 4.8%;
  }
}
.short-col {
  flex: 0 0 21.66666%;
  max-width: 21.66666%;
  padding: 10px 0px;
  overflow-wrap: break-word;
  &:nth-last-of-type(2) {
    padding-top: 3.7%;
  }
  &:last-of-type {
    background-size: 85% 85% !important;
    background-repeat: no-repeat !important;
    background-position: 15px 15px !important;
  }
}
.filterInput {
  width: 100%;
  text-align: center;
  &:focus {
    border: none;
  }
}

.divServices{
  height: 600px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.div-main{
  border-right:2px dashed #000;
}
.visible-xs{
  visibility: hidden ;
}
.margin-top-20{
  margin-top: 20px;
}
@media (max-width: 600px) {
  .visible-xs{
    visibility: inherit;
  }
  .hidden-xs{
    visibility: hidden ;
  }

  .div-main{
    border-right: 0px;
  }

  .serv-col {
    width: 100%  !important;
    position: relative;
    top: 0;
    margin-top: 30px;
  }

  .mx-5{
    margin: 5px !important;
    padding: 5px !important;
  }
}
</style>
