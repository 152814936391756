export const API_INFO = process.env.VUE_APP_ROOT_API

export var initEvents = [
  {
    id: 1,
    title: "MIMMA45",
    start: "2021-03-29 09:00:00",
    end: "2021-03-29 11:00:00",
    backgroundColor: "#FF5733"
  },
  {
    id: 2,
    title: "EMERGE",
    start: "2021-03-29 10:00:00",
    end: "2021-03-29 11:05:00",
    backgroundColor: "#0053B8"
  }
]
export var initEvents2 = [
  {
    id: 1,
    title: "MIMMA45",
    start: "2021-03-29 09:00:00",
    end: "2021-03-29 11:00:00",
    backgroundColor: "#FF5733"
  }
]