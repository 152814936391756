<template>
  <b-container class="inputs">
    <b-row style="min-height: 500px;" class="justify-content-center p-4 ml-1">
      <b-col
        lg="9"
        md="12"
        sm="12"
        cols="12"
        class="d-flex justify-content-center baseline"
      >
        <b-row class="baseline mb-5">
          <b-col class="h-25" cols="12">
            <h2 class="text-center">DATOS RESERVA</h2>
            <br />
          </b-col>
          <b-col cols="12">
            <h4 class="text-center">
              El coste del servicio puede llevar suplementos por desplazamiento en función de la distancia al Hammam. Introduce aquí la dirección completa donde disfrutar de la experiencia HALMMA y podrás consultar los servicios disponibles en tu localidad
            </h4>
            <br /><br />
          </b-col>
          <b-col cols="12" class="d-flex  mb-5">
            <b-row>
              <transition name="fade">
                <p v-show="streetName && !streetNum" class="num-advice">
                  Ingresa aquí el número de la calle
                </p>
              </transition>
              <b-col lg="8" md="8" sm="12" xs="12" class="input-group">
                <input
                  class="form-control"
                  id="autocomplete"
                  placeholder="Calle"
                  type="text"
                  @input="initAutocomplete($event)"
                />
                <span class="input-group-addon">
                  <b-icon-geo-alt-fill />
                </span>
              </b-col>
              <b-col lg="4" md="4" sm="12" xs="12" class="p-0">
                <input
                  id="piso"
                  class="input-basic date-hour-inputs"
                  placeholder="Piso y letra"
                  type="text"
                  v-model="flat"
                />
                <b-form-checkbox
                  v-if="flat !== ''"
                  v-model="hasElevator"
                  class="mt-4"
                  switch
                >
                  ¿El edificio cuenta con ascensor?
                </b-form-checkbox>
              </b-col>
              <b-col cols="12" class="mt-5">
                <b-row>
                  <b-col cols="12" class="d-flex p-0">
                    <div style="width: 33%" class="mr-4">
                      <input
                        class="show-data-input mr-5"
                        v-model="province"
                        disabled="true"
                        placeholder="Provincia"
                      />
                    </div>
                    <div style="width: 40%">
                      <input
                        class="show-data-input cp-input"
                        v-model="cp"
                        disabled="true"
                        placeholder="CP"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" class="mt-5 p-0">
                <br /><br />
                <b-row>
                  <!-- <b-col cols="6" xs="10">
                    <b-form-select
                      v-model="personNumber"
                      size="lg"
                      class="my-3 persons-input input"
                      disabled
                    >
                      <b-form-select-option
                        value="null"
                        class="placeholder-font"
                      >
                        Nº de personas
                      </b-form-select-option>
                      <b-form-select-option value="1">1</b-form-select-option>
                    </b-form-select>
                  </b-col> -->
                  <b-col cols="10" sm="6" class="ml-5 ml-sm-0 mt-4 mt-sm-0">
                    <b-form-group class="mt-2">
                      <b-form-checkbox
                        class="placeholder-font"
                        v-model="specialServ"
                        value="1"
                        unchecked-value=""
                      >
                        Quiero recibirlo en mi hotel
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-if="showPortInput == true"
                        class="my-2 placeholder-font"
                        v-model="specialServ"
                        value="2"
                      >
                        Quiero recibirlo en mi embarcación
                      </b-form-checkbox>
                    </b-form-group>
                    <input
                      v-if="specialServ !== ''"
                      class="input-basic date-hour-inputs"
                      placeholder="Nombre del establecimiento"
                      type="text"
                      v-model="specialServDetail"
                    />
                  </b-col>
                  <b-button
                    v-if="streetNum !== '' & cp !== ''"  
                    @click="getNearCenter()"                        
                    class="btn-fill-dark mt-5"
                    style="position:relative;left:14px"
                  >
                    Consultar servicios
                  </b-button>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <div v-show="notCovered" class="mb-5">
            <h3>
              Actualmente no disponemos de atención a domicilio en esta zona, lo
              invitamos a disfrutar de nuestros servicios en cualquiera de
              nuestros centros:
            </h3>
          </div>
          <HammamCenters v-if="showHamButtons2" />
          <b-row class="date-hour-col mt-5 " v-if="showDate">
            <b-col cols="12">
              <br />
              <h3 class="text-center mt-5">
                SELECCIONA FECHA Y HORA PARA TU RESERVA
              </h3>
            </b-col>
            <b-col cols="9">
              <br /><br />
              <p>Fecha</p>
              <b-input-group size="lg" class="mb-2">
                <b-form-datepicker
                  class="input-basic date-hour-inputs"
                  v-model="date"
                  placeholder="Fecha"
                  :start-weekday="1"
                  @input="checkAvailableHours"
                ></b-form-datepicker>
              </b-input-group>
              <b-alert
                v-model="chooseOtherDay"
                variant="light"
                dismissible
                fade
              >
                No hay servicios disponibles para ese día, por favor, selecciona otro.
              </b-alert>
            </b-col>
            <b-col cols="9">
              <br /><br />
              <p>Hora</p>
              <b-input-group size="lg" class="mb-2 d-flex ">
                <b-form-select
                  class="date-hour-inputs mb-5"
                  v-model="hour"
                  :options="options"
                  :disabled="unavailableDate"
                  @input="
                    sendData();
                    checkHourExist();
                  "
                ></b-form-select>
                <br /><br />
                <b-alert
                  v-model="showAvailableHours"
                  variant="light"
                  dismissible
                  fade
                >
                  No hay ningún servicio disponible a esa hora, por favor,
                  escoja otro horario o selecciona otro día.
                </b-alert>
                <!-- <b-row class="p-0" v-show="showAvailableHours">
                  <b-col class="p-0">
                  
                      <b-button      
                        v-for="i in availableHours" 
                        :key="i"
                        value="i"             
                        @click="
                          hour = i;
                          checkHourExist();
                        "                        
                        class="hour-btn"
                      >
                        {{ i }} hs
                      </b-button>
                    </div> 
                  </b-col>
                </b-row>-->
                <b-alert v-model="hourLimit" variant="light" dismissible fade>
                  Nuestro horario de servicio de masajes a domicilio es de 11:00
                  a 19:00, por favor introduzca una nueva solicitud dentro del
                  horario laboral o bien disfrute de una experiencia única en
                  uno de nuestros centros:
                </b-alert>
                <HammamCenters v-if="showHamButtons2" />
              </b-input-group>
              <!-- <a
                class="btn-outline-dark"
                v-show="hour !== '' && !unavailableDate && !hourLimit"
                @click="
                  sendData();
                  checkHourExist();
                "
              >
                Servicios
              </a> -->
              <br /><br /><br />
            </b-col>
            <br /><br />
          </b-row>
        </b-row>
      </b-col>
      <ModalAlert
        :showCard="showMsg"
        :title="messageAlert"
        :varian="varianAlert"
        @close-modal="() => showMsg = false"
      />
    </b-row>
  </b-container>
</template>

<script>
import { getCenter, getAvailableHours } from "@/api/EventService.js";
import delay from "delay";
import HammamCenters from "@/components/HammamCenters.vue";
import ModalAlert from "@/components/alerts/ModalAlert.vue";

// CSS
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  name: "DataInputs",
  components: {
    HammamCenters,
    //VueTimepicker,
    ModalAlert,
  },
  props: {
    links: Array,
  },
  data: () => ({
    persons: 0,
    streetName: "",
    streetNum: "",
    province: "",
    locality: "not found",
    cp: "",
    coordinates: { long: "", lat: "" },
    centerId: "",
    feeValue: 0,
    date: "",
    hour: "null",
    specialServ: "",
    specialServDetail: "",
    personNumber: 1,
    showHourAlert: false,
    showHamButtons: false,
    showHamButtons2: false,
    showDate: false,
    notCovered: false,
    showAvailableHours: false,
    availableHours: false,
    flat: "",
    unavailableDate: true,
    chooseOtherDay: false,
    showMsg: false,
    messageAlert: '',
    varianAlert: '',
    selectedAddress: "",
    validationDateTime: false,
    hasElevator: false,
    options: [],
    autocomplete: "",
    autocompleteInited: false
  }),
  watch: {
    streetName() {
      this.selectedAddress = this.streetName + " " +
          this.streetNum + ", " +
          this.province + ", " + "España"
    }
  },
  computed: {
    centerInputsFilled() {
      if (
        this.streetName !== "" &&
        this.province !== "" &&
        this.personNumber !== null &&
        this.streetNum !== ""
      ) {
        if (this.personNumber !== "null") {
          return false;
        }
        return true;
      }
      return true;
    },
    hourLimit() {
      let hourInt = parseInt(this.hour.substring(0, 2));
      let minutes = parseInt(this.hour.substring(3, 5));
      if (hourInt < 11 || hourInt > 19) {
        this.showButtons();
        return true;
      } else if (hourInt === 19 && minutes > 0) {
        this.showButtons();
        return true;
      }
      this.hideButtons();
      return false;
    },
    showPortInput() {
      if(this.links[7]) {
        return false
      }
      return true
    },
    // findCenter() {
    //   if (
    //     this.streetNum !== "" &&
    //     this.cp &&
    //     !isNaN(parseInt(this.personNumber))
    //   ) {
    //     this.getNearCenter();
    //     return "executed";
    //   }
    //   return "NOT EXEC";
    // },
  },
  mounted() {
    this.initAutocomplete();
  },
  methods: {
    async initAutocomplete(e) {
      if(e.target.value.length > 7 & !this.autocompleteInited) {
        this.autocompleteInited = true
        this.autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById("autocomplete"),
          { types: ["address"] }
        );
        this.autocomplete.setFields(["address_component"]);
        this.autocomplete.addListener("place_changed", this.fillInAddress);
      }
    },
    async fillInAddress() {
      const place = this.autocomplete.getPlace();
      for (const component of place.address_components) {
        const addressType = component.types[0];
        switch (addressType) {
          case "street_number":
            this.streetNum = component.long_name;
            break;
          case "route":
            this.streetName = component.long_name;
            break;
          case "locality":
            this.locality = component.long_name;
            break;
          case "administrative_area_level_2":
            this.province = component.long_name;
            break;
          case "postal_code":
            this.cp = component.long_name;
            break;
        }
      }
      await delay(0);
      this.geocodeAddress();
    },
    geocodeAddress() {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: this.selectedAddress }, (results, status) => {
        if (status === "OK") {
          this.coordinates["long"] = results[0].geometry.location.lng();
          this.coordinates["lat"] = results[0].geometry.location.lat();
        } else {
          console.log("request error: ", status);
        }
      });
    },
    async getNearCenter() {
      var body = {
        address: this.streetName + " " + this.streetNum,
        people: this.personNumber,
        postal_code: this.cp,
        locality: this.locality,
        coordinates: this.coordinates,
        province: this.province,
        country: "España",
        is_lift: this.hasElevator,
        special_service_detail: this.specialServDetail
      };
      if (this.specialServ !== "") {
        body = { ...body, ...{ special_service_id: this.specialServ } };
      }
      if (this.flat !== "") {
        body = { ...body, ...{ building_floor: this.flat } };
      }
      var req = await getCenter(body);
      if (req.status === 200) {
        if (!req.data["links"]) {
          this.showDate = true;
          this.showHamButtons2 = false;
          this.notCovered = false;
          this.closeComponents();
          if (
            localStorage.prospectId ||
            localStorage.prospectId !== null ||
            localStorage.prospectId !== undefined
          ) {
            localStorage.removeItem("prospectId");
          }
          localStorage.setItem("prospectId", req.data.prospect_id);
        } else {
          this.showHamButtons2 = true;
          this.notCovered = true;
          this.showDate = false;
          this.closeComponents();
        }
        this.centerId = req.data.center_id;
        this.feeValue = req.data.fee;
      } else {
        console.log("error de consulta");
        console.log(req);
      }
    },
    async checkAvailableHours() {
      this.chooseOtherDay = false;
      this.unavailableDate = false;
      this.validationDateTime = false;
      let hoy = new Date();
      var m = hoy.getMonth() + 1;
      var d = hoy.getDate();
      
        if (m<10){
          m = "0"+m ;
        }
        if(d<10){
          d = "0"+d
        }
      var fecha = hoy.getFullYear() + '-' + m + '-' + d
      let fechaCalcular = new Date(this.date);
      let fechaHoy = new Date(fecha);
      
      let diferencia = fechaCalcular.getTime() - fechaHoy.getTime();
      let diasTranscurridas = diferencia /(1000*60*60*24);
      /* HABILITAR PARA LA VALIDACION DE FECHA */
      if(parseInt(diasTranscurridas) < 2) {
        if (this.date < fecha) {
          this.showMsg = true;
          this.messageAlert = 'Cerradas las reservas para esta fecha. Por favor, selecciona una fecha posterior.';
          this.varianAlert = 'warning';
          return false;
        }
        if (fecha != this.date) {
          if (hoy.getHours() > 18){
            this.showMsg = true;
            this.messageAlert = 'Cerradas las reservas para esta fecha. Por favor, selecciona una fecha posterior.';
            this.varianAlert = 'warning';
            return false;
          }
        } else {
          this.showMsg = true;
          this.messageAlert = 'Cerradas las reservas para esta fecha. Por favor, selecciona una fecha posterior.';
          this.varianAlert = 'warning';
          return false;
        }
        
      } 
        const payload = {
          center_id: this.centerId,
          postal_code: this.cp,
          date: this.date,
        };
        this.toogleLoading(true);
        var req = await getAvailableHours(payload);
        if (req.status === 200) {
          if (req.data[this.date].length > 0) {
            const dayServices = req.data[this.date];
            this.options = [];
            dayServices.forEach(hour => {
              this.options.push({'value':hour[0].substring(0,5), 'text': hour[0].substring(0,5)})
            });
            this.availableHours = dayServices.map((k) => k[0].substring(0,5));
            this.unavailableDate = false;
            this.chooseOtherDay = false;
            this.toogleLoading(false);
            
          } else {
            this.options = [];
            this.chooseOtherDay = true;
            this.unavailableDate = true;
            this.toogleLoading(false);
          }
        } else {
          this.toogleLoading(false);
          console.log("error de consulta");
          console.log(req);
        }
      
      
    },
    checkHourExist() {
      if (this.validationDateTime == true){
        let hourInt = parseInt(this.hour.substring(0, 2));
        if (hourInt < 18){
          this.showMsg = true;
          this.messageAlert = 'Se debe seleccionar un horario superior a las 18:00 o cambiar de fecha.';
          this.varianAlert = 'warning';
          return false;
        }
      }
      var exist1 = this.availableHours.find(el => el === this.hour)      
      if(exist1 === undefined) {
        this.showAvailableHours = true
      } else {
        this.showAvailableHours = false
        this.getServices()
      }
    },
    sendData() {
      this.$emit("send-data", {
        address:
          this.flat !== ""
            ? this.streetName + " " + this.streetNum + ", " + this.flat
            : this.streetName + " " + this.streetNum,
        province: this.province,
        cp: this.cp,
        persons: this.personNumber
      });
    },
    getServices() {
      this.$emit("get-services", {
        cp: this.cp,
        centerId: this.centerId,
        date: this.date,
        time: this.hour,
        feeValue: this.feeValue,
      });
    },
    showButtons() {
      this.showHamButtons = true;
    },
    hideButtons() {
      this.showHamButtons = false;
    },
    closeComponents() {
      this.$emit("close-components");
    },
    toogleLoading(state){

      this.$emit("toogle-loading", state);
    }
  },
};
</script>

<style lang="scss" scoped>
$hammam-blue: #82c5bf;
.inputs {
  font-family: "Chap", Helvetica, Arial, sans-serif;
}
select {
  @media (max-width: 576px) {
    min-width: 160px;
  }
}
.btn:hover {
  color: #fff;
}
li:hover {
  background: rgb(248, 194, 194);
}
.autocomplete {
  width: 50%;
}

#locationField {
  display: flex;
  flex-direction: row;
  @media (max-width: 411px) {
    flex-direction: column;
  }
}

#autocomplete {
  left: 0px;
  min-width: 75%;
  margin-bottom: 20px;
  height: 45px !important;
  background-color: transparent;
  @media (max-width: 411px) {
    width: 260px;
  }
}
.advice {
  top: 6%;
  left: 10%;
  color: #c2c2c2;
  span {
    cursor: pointer;
    color: $hammam-blue;
    font-weight: 900;
  }
}

.num-advice {
  text-align: center;
  position: absolute;
  left: 10%;
  top: -30px;
  color: #dc4130;
  border-color: #1d2124;
}
.hour-btn {
  margin: 20px;
  width: 22%;
  font-size: 1.5rem;
  text-transform: none;
  background: rgb(226, 226, 226);
  color: #89898a;
}

.ml-5 {
  @media (max-width: 576px) {
    margin-left: unset !important;
  }
}

.input-group {
  input {
    width: 80%;
    border-radius: 0px;
    border: 1px solid rgb(93, 93, 93);
  }
  span {
    font-size: 30px;
    position: relative;
    top: 4px;
    border-radius: 0px;
    border: 1px solid rgb(93, 93, 93);
    svg {
      position: relative;
      top: 3px;
    }
    @media (max-width: 400px) {
      display: none;
    }
  }
}

.cp-input {
  width: 25%;
  @media (max-width: 767px) {
    margin-left: 40px;
    width: 40%;
  }
  @media (max-width: 576px) {
    margin-left: 80px;
    width: 40%;
  }
}
.input {
  border-radius: 0px;
  border: 1px solid rgb(93, 93, 93);
  width: 70%;
  height: 46px;
}
#piso {
  width: 44.5%;
  min-width: 84px;
  height: 45px !important;
  background-color: transparent;
}
.date-hour-col {
  box-shadow: 0px 0px 12px 3px rgb(0 0 0 / 8%);
  width: 105%;
  min-width: 105%;
  background-color: white;
  @media (max-width: 576px) {
    position: relative;
  }
}
.date-hour-inputs {
  max-width: 75% !important;
  min-width: 180px;
}
.persons-input {
  cursor: not-allowed;
}

.b-row {
  margin-left: 0px;
  margin-right: 0px;
}

footer {
  padding: 0;
}

.b-calendar-grid-weekdays {
  .row {
    margin: unset;
  }
}
.custom-select {
  background-color: transparent !important;
}

.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: $hammam-blue !important;
}

h3 {
  @media (max-width: 576px) {
    font-size: 18px;
  }
}

.vue__time-picker {
  width: inherit;
  font-size: 18px !important;
  font-family: inherit;
  .dropdown ul li:not([disabled]).active {
    background: #90ddd7 !important;
    color: #fff;
  }
}

.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #90ddd7 !important;
  color: #fff;
}

#hour-input {
  display: flex;
  align-items: center;
}

.fill-field {
  min-width: 238px;
}
// transition effect
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
