<template>
  <div>
    <div v-show="cart.length > 0 && !accepted" class="tabla-compra">
      <button class="btn btn-comprar" @click="managePurchaseForm">
        Aceptar
      </button>
    </div>
    <transition name="slide-fade">
      <section v-show="accepted" class="tabla-compra">
        <div class="container">
          <div id="carrito" class="row" style="">
            <section class="seleccion" id="datosclientereserva">
              <div class="container">
                <div class="row-md-12">
                  <ul class="list-inline text-left tabla-general"></ul>
                  <h4>
                    Completa el siguiente Formulario
                  </h4>
                  <div class="col-md-12 seleccion tabla-compra-listado">
                    <form class="form-horizontal row" v-on:submit.prevent>
                      <div class="col-md-4 form-li" v-show="!logged">
                        <label for="email" class="control-label">E-mail:</label>
                        <input
                          v-model="email"
                          type="email"
                          class="form-control"
                          id="email"
                          name="email"
                        />
                      </div>
                      <div class="col-md-12 form-li"></div>
                      <div class="col-md-4 form-li" v-show="!logged">
                        <label for="email" class="control-label">Nombre:</label>
                        <input
                          v-model="first_name"
                          type="text"
                          class="form-control"
                          id="nombre"
                          name="nombre"
                        />
                      </div>
                      <div class="col-md-4 form-li" v-show="!logged">
                        <label for="email" class="control-label"
                          >Apellidos:</label
                        >
                        <input
                          v-model="last_name"
                          type="text"
                          class="form-control"
                          id="apellidos"
                          name="apellidos"
                        />
                      </div>
                      <div class="col-md-12 form-li"></div>
                      <div class="col-md-10 form-li">
                        <label class="control-label form-label"
                          >Dirección:</label
                        >
                        <input
                          type="text"
                          class="form-control float-left long"
                          disabled="true"
                          v-model="direction[0]"
                        />
                      </div>
                      <div class="col-md-12 form-li"></div>
                      <div class="col-md-4 form-li" v-show="!logged">
                        <label for="pais" class="control-label">Pais:</label>
                        <input
                          type="text"
                          class="form-control"
                          disabled="true"
                          v-model="direction[1]"
                        />
                      </div>
                      <div
                        class="col-md-4 form-li"
                        id="divprovincia"
                        v-show="!logged"
                      >
                        <label for="email" class="control-label"
                          >Provincia*:</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          disabled="true"
                          v-model="direction[2]"
                        />
                      </div>
                      <div class="col-md-4 form-li" id="divcp" v-show="!logged">
                        <label for="email" class="control-label">CP*</label>
                        <input
                          type="text"
                          class="form-control"
                          disabled="true"
                          v-model="direction[3]"
                        />
                      </div>
                      <div class="col-md-12 form-li"></div>
                      <div class="col-md-4 form-li" v-show="!logged">
                        <label for="email" class="control-label"
                          >Tlfno Contacto:</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="tlfno"
                          name="tlfno"
                          v-model="phone"
                        />
                      </div>
                      <div class="col-md-4 form-li" v-show="!logged">
                        <label for="email" class="control-label">NIF:</label>
                        <input v-model="nif_nie" type="text" class="form-control" />
                      </div>
                      <div class="col-md-12 form-li"></div>
                      <div class="col-md-10 form-li">
                        <label class="control-label form-label">
                          Alergias o consideraciones relevantes:
                        </label>
                        <input
                          type="text"
                          class="form-control float-left long"
                          style="margin: 20px 0px 0px;"
                          v-model="health_considerations"
                        />
                        <div class="col-md-12 form-li"></div>
                      </div>
                      <div class="col-md-12 form-li mb-3"></div>
                      <div style="width:100%">
                        <div v-show="!logged">
                          <div class="col-md-10 form-li">
                            <label for="text"
                              >Si deseas registrarte en nuestra web, rellena los
                              siguientes campos</label
                            >
                            <div class="col-md-12 form-li"></div>
                          </div>

                          <div class="col-md-4 form-li">
                            <label for="email" class="control-label"
                              >Contraseña:</label
                            >
                            <input
                              type="password"
                              class="form-control"
                              id="password"
                              v-model="password"
                            />
                          </div>
                          <div class="col-md-4 form-li">
                            <label for="email" class="control-label"
                              >Repite Contraseña:</label
                            >
                            <input
                              type="password"
                              class="form-control"
                              id="password_confirm"
                              v-model="password2"
                            />
                          </div>
                        </div>          
                      </div>
                      
                      <div class="col-md-12 form-li mb-3"></div>
                      <div style="width:100%">
   
                        <div class="col-md-12 form-li">
                          <br />
                          <b-button
                            variant="primary"
                            class="terms pull-right"
                            @click="legalConditions = true"
                          >
                            <b-form-checkbox
                              value="accepted"
                              v-model="okTerms"
                              size="lg"
                              @click="legalConditions = true"
                            >
                              Términos legales
                            </b-form-checkbox>
                            <div class="showTerms"></div>
                          </b-button>
                        </div>
                      </div>

                      <!--
                      <div class="col-md-4 form-li">
                        <Payment @send-payment-method="setPaymentMethod" />
                      </div>
                      :disabled="
                            okTerms !== 'accepted' || paymentMethod == ''
                          "
                      -->
                      <div class="col-md-12">
                        <button
                          class="btn btn-comprar pull-right"
                          @click="finalizePurchase"
                          
                        >
                          Confirmar Reserva
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </transition>
    <Stepper
      :legalConditions="legalConditions"
      @close-modal="closeModal()"
      @send-success="acceptTerms()"
    />

    <ModalAlert
      :showCard="showAlert"
      :title="message"
      :varian="varian"
      @close-modal="() => showAlert = false"
    />
    <div v-if="successPayment">
      <h3>
        El pago se realizó con éxito
        <b-icon icon="credit-card" aria-hidden="true"></b-icon>
      </h3>
    </div>
  </div>
</template>

<script>
import Stepper from "@/components/legalConditions/Stepper.vue";
import ModalAlert from "@/components/alerts/ModalAlert.vue";
// import Payment from "@/components/payment/index.vue";
import delay from "delay";
import axios from "axios";
import { API_INFO } from "@/constants.js";

export default {
  components: {
    Stepper,
    ModalAlert,
    // Payment,
  },
  props: {
    cart: Array,
    direction: Array,
    accepted: Boolean,
    idClient: Number,
    date: String,
    hour: String,
    centerId: Number,
    feeValue: Number
  },
  data: () => ({
    legalConditions: false,
    showAlert: false,
    logged: false,
    paymentMethod: "",
    successPayment: false,
    okTerms: "not accepted",
    message: '',
    varian: '',
    email: '',
    nif_nie: '',
    phone: '',
    last_name: '',
    first_name: '',
    password: '',
    password2: '',
    health_considerations: '',
  }),
  methods: {
    managePurchaseForm() {
      const tokenExist = localStorage.getItem("accessToken");
      if (tokenExist) {
        this.logged = true;
      }
      this.accepted = true;
    },
    setPaymentMethod(event) {
      this.paymentMethod = event.picked;
    },
    async finalizePurchase() {
      if (this.okTerms == 'accepted'){
        if (this.logged == true) {
          this.reservationRegister();
        } else {
          this.verificationClient()
        }
        
      } else {
        this.message = "Por favor, acepte los terminos y condiciones."
        this.showAlert = true;
        this.varian = 'warning';
      }

    },
    async verificationClient(){
      try {
        const resp = await axios.get(
          API_INFO + "/clients/validation/?email="+this.email
        );
        if (resp.status === 200) {

          this.idClient = resp.data.client_id
          this.last_name= resp.data.last_name
          this.first_name= resp.data.first_name
          this.reservationRegister()

        } else {
          console.log("entro")
          this.registerClient()
        }
      } catch {
            this.registerClient()
      }
    },
    async registerClient(){
      try {
        if (this.password !== this.password2) {
          this.message = "No coinciden las contraseñas."
          this.showAlert = true;
          this.varian = 'warning';
          return false;
        }

        if (this.password == ''){
          this.password = 'd3f@u1t';
          this.password2 = 'd3f@u1t' ;
        }
        const resp = await axios.post(
          API_INFO + "/clients/register/",
          {
            email: this.email,
            nif_nie: this.nif_nie,
            phone: this.phone,
            last_name: this.last_name,
            first_name: this.first_name,
            address: this.direction[0],
            postal_code: this.direction[3],
            province: null,
            country_id: 1,
            health_considerations: this.health_considerations,
            password: this.password,
            password2: this.password2,
            prospect_id: localStorage.prospectId,
            terms_accepted: true,
            news_accepted: true,
          }
        );
        if (resp.status === 201) {
          // console.log(resp.data)
          this.idClient = resp.data.id
          this.reservationRegister();
        } else {
          this.message = resp.data
          this.showAlert = true;
          this.varian = 'warning';
        }
      } catch (error){
        if (error.response.status == 500){
          this.message = "El cliente ya existe por favor inicie sesión.";
          this.showAlert = true;
          this.varian = 'warning';
        } else {
          this.message = "Complete los campos.";
          this.showAlert = true;
          this.varian = 'warning';
        }
      }
    },
    async reservationRegister() {
      let validated = 0
      if (this.logged == true) {
        validated = 1
      } 
      try {
        let service_ids = []
        let service_quantitys = []
        let total = 0
        this.cart.forEach(item => {
          service_ids.push(item.id)
          service_quantitys.push(item.quantity)
          total = total + parseFloat(item.price * item.quantity)
        });
        total = total + this.feeValue;
        
        const resp = await axios.post(
          API_INFO + "/reservations/",
          {
            service_ids: service_ids,
            service_quantitys: service_quantitys,
            client_id: this.idClient,
            date: this.date,
            time: this.hour,
            center_id: this.centerId,
            prospect_id: localStorage.prospectId,
            address: this.direction[0],
            postal_code: this.direction[3],
            province: this.direction[2],
            country: this.direction[1],
            total: total,
            validated: validated,
            fee_value: this.feeValue,
            reception: false,
            hammam_user: null
            
          }
        );
        if (resp.status === 201) {
          if (this.logged == false) {
            const client_data = {
              last_name: this.last_name,
              first_name: this.first_name,
              client_id: this.idClient
            }
            this.$emit("name-client", 
              client_data
            );
          }
          
          this.$emit("confirm-reservation", 
            resp.data
          );

        }
      } catch (error){
        if (error.response.status == 500){
          this.message = "Lo sentimos, el servidor no ha podido procesar su consulta, inténtelo mas tarde."
          this.showAlert = true;
          this.varian = 'warning';
        } else {
          this.message = "Por favor intentelo de nuevo."
          this.showAlert = true;
          this.varian = 'warning';
        }
      }
    },
    acceptTerms() {
      this.legalConditions = false;
      this.okTerms = "accepted";
    },
    closeModal() {
      this.legalConditions = false;
    },
    hideTable() {
      this.$emit("update:accepted", "");
    },
  },
};
</script>

<style lang="scss" type="text/css" scoped>
.form-label {
  width: 13% !important;
  @media only screen and (max-width: 767px) and (min-width: 720px) {
    width: 168px !important;
  }
}
.long {
  width: 100% !important;
  position: relative;
  left: 5px;
  @media (min-width: 720px) {
    width: 65.6% !important;
  }
  @media only screen and (max-width: 767px) and (min-width: 720px) {
    width: 302.4px !important;
  }
}
.seleccion {
  padding-top: 0px;
}
.btn-block {
  width: 30%;
  height: 45px;
  font-size: 1.5rem;
  margin: auto;
  position: relative;
  top: -20px;
}
.tabla-compra {
  display: flex;
  justify-content: center;
}
h3 {
  text-align: center;
}
.terms {
  width: 30%;
  font-size: 1.6rem;
  height: 50px;
  &:hover {
    background: #667688;
  }
  .custom-checkbox {
    position: relative;
    top: 4px;
  }
  @media (max-width: 767px) {
    width: 47%;
  }
  @media (max-width: 390px) {
    width: 82%;
  }
}
.showTerms {
  cursor: pointer;
  background: rgba(202, 202, 202, 0);
  position: relative;
  top: -20px;
  width: 80%;
  height: 90%;
  z-index: 100;
}
//transition effect
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0.6;
}
</style>
