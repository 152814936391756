<template>
<div class="full-container">
  <NavBar />
  <b-container fluid class="h-100 gray" :class="showHeader ? '': 'px-0'">
    <b-row v-if="showHeader" class="title mt-4">
      <h1 @click="showSelectCenter = true" class="m-auto">Calendario de reservas y horarios</h1>
    </b-row>
    <b-icon-menu-button
      v-if="role === 'administrator' || role === 'supervisor'"
      @click="showFiltersModal = true;
              teamFilter = '';
              employeeFilter = ''"
      class="filters-button menu-icons"     
    />
    <b-icon-calendar2-week
      @click="showSumReservationsModal = true"
      class="menu-icons table-icon"
      style="left:35px"
      :style="[
                showHourDayButtons == false ||
                showScheduleSelectors == true ?
                {'display': 'none'} : ''
              ]"
    />
    <b-col>
      <b-row 
        :style="[
                  showHourDayButtons == true ||
                  showScheduleSelectors == true ?
                  {'display': 'none'} : ''
                ]"
        class="mt-5"
      >
        <b-col>
          <div class="hour-title">
            <h3>Total de horas:</h3>  
          </div>
        </b-col>
      </b-row>
      <b-row class="w-100 m-0 days-row">
        <div 
          v-for="(i,l) in totalHoursReservations"
          :key="[l + '*']"
          class="hours-col"
          :style="[
                    showHourDayButtons == true ||
                    showScheduleSelectors == true ?
                    {'display': 'none'} : ''
                  ]"
        >
          <div>
            <div 
              v-for="(k,j) in i"
              :key="j"
              class="hours-div show-reserv py-3"
            >
              <ul>
                <li>
                  {{k}}
                </li>
              </ul>
            </div>
          </div>
          <div v-if="i.length == 0">
            <div class="hours-div no-reserv py-3">
              <ul>
                <li>
                  No hay reservas para la fecha
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div v-for="(i,v) in currentDates" :key="v" class="day-col px-2">
          <div :style="[!showScheduleSelectors ? {'visibility': 'collapse'} : '']">
            <div v-for="(j,m)  in i" :key="m">
              <div v-if="!showHourDayButtons">
                <b-form-select
                  v-model="currentDates[v][m]"
                  size="lg"
                  class="my-3 input px-2"
                  @change="confirmDate(m, $event)"
                >
                  <b-form-select-option
                    value="null"
                    class=""
                  >
                    Aplicar horario
                  </b-form-select-option>
                  <b-form-select-option value="1">Mañana</b-form-select-option>
                  <b-form-select-option value="2">Tarde</b-form-select-option>
                  <b-form-select-option value="3">Descansa</b-form-select-option>
                </b-form-select>
              </div>
              <div v-else>
                <b-icon-list @click="openModalScheduleChanges(k,v)" />
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </b-col>
    <FullCalendar 
      class="calendar mt-4"
      :options="calendarOptions"
      ref="fullCalendar"
    />
    <CalendarFilters
      :showModal="showFiltersModal"
      :employees="centerEmployees"
      :teams="centerTeams"
      @close-modal="showFiltersModal = false"
      @filter-by="applyFilters"
    />
    <EventDetail
      :showModal="showEventModal"
      :reservation="reservationDetail"
      @close-modal="showEventModal = false"
    />
    <InputScheduleChange 
      :showModal="showScheduleChangeModal"
      :date="buttonDate"
      @close-modal="showScheduleChangeModal = false"
      @save-changes="confirmDate"
    />
    <DateInputs
      :showCard="showDateInputsModal"
      @close-modal="showDateInputsModal = false"
      @save-changes="applyScheduleChanges"
    />
    <SelectCenterAdmin
      :showModal="showSelectCenter"
      @close-modal="showSelectCenter = false"
      @apply-filter="filterByCenter"
    />
    <AlertError 
      :message="errorMessage"
      :showAlert="showAlertError"
    />
    <TableSumReservations
      :showModal="showSumReservationsModal"
      @close-modal="showSumReservationsModal = false"
      :tableDays="daysHoursTable"
      :employeeReservations="totalHoursReservations"
    />
  </b-container>
</div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'

import { API_INFO } from "@/constants.js";
import CalendarFilters from "@/components/dashboard/CalendarFilters.vue";
import EventDetail from "@/components/dashboard/EventDetail.vue";
import InputScheduleChange from "@/components/dashboard/InputScheduleChange.vue";
import DateInputs from "@/components/dashboard/DateInputs.vue"
import SelectCenterAdmin from "@/components/dashboard/SelectCenterAdmin.vue"
import TableSumReservations from "@/components/dashboard/TableSumReservations.vue"
import NavBar from "@/components/NavBar.vue"
import AlertError from "@/components/alerts/AlertError.vue"
// import { returnEvents } from "@/utils.js";
import axios from "axios";
import delay from 'delay';
import yesno from "yesno-dialog";

export default {
  components: {
    FullCalendar,
    CalendarFilters,
    EventDetail,
    InputScheduleChange,
    DateInputs,
    SelectCenterAdmin,
    NavBar,
    AlertError,
    TableSumReservations
  },
  data() {
    return {
      calendarOptions: {
        locale: 'es',
        firstDay: new Date().getDay(),
        plugins: [timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: '',
          center: 'title',
          right: 'prev,next'
        },
        initialView: 'timeGridWeek',
        events: this.returnEvents,
        slotMinTime: "09:00:00",
        slotMaxTime: "20:00:00",
        height: "1070px",
        expandRows: true ,
        dayHeaderFormat: { weekday: 'narrow', day: 'numeric' },
        eventClick: this.handleEventClick
      },
      width: window.innerWidth,
      reservationDetail: {},
      showFiltersModal: false,
      showEventModal: false,
      role: localStorage.role,
      centerEmployees: [],
      centerTeams: [],
      assignSchedule: null,
      currentDates: [],
      showScheduleChangeModal: false,
      buttonDate: "",
      reservResponse: "",
      employeesHours: "",
      employeesWorkingHours: [],
      reservations: [],
      teamFilter: "",
      employeeFilter: "",
      filteredEmployees: [],
      selectedEmployees: [],
      appliedFilter: "",
      selectedScheduleValue: "",
      selectedDate: "",
      showDateInputsModal: false,
      hour: {},
      beginDate: "",
      endDate: "",
      showSelectCenter: false,
      centerId: localStorage.centerId,
      showAlertError: false,
      errorMessage: "",
      totalHoursReservations: [],
      showScheduleSelectors: true,
      daysHoursTable: [],
      showTableSumReservations: true,
      showSumReservationsModal: false
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.width = window.innerWidth
    })
    if(this.role === "administrator") {
      this.showSelectCenter = true
    }
    this.getCenterEmployees()
    this.getCenterTeams()
  },
  computed: {
    showHeader() {
      if(this.width < 550) {
        return false
      }
      return true
    },
    showHourDayButtons() {
      if(this.width < 699) {
        return true
      }
      return false
    }
  },
  methods: {
    async getReservationDetail(id) {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/reservations/" + `${id}/`,
          options
        );
        if (resp.status === 200) {
          this.reservationDetail = resp.data
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getCenterEmployees() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      if(this.centerId !== null & this.centerId !== "null") {
        if(this.role === "supervisor" || this.role === "administrator") {
          try {
            const resp = await axios.get(
              API_INFO + "/dashboard/centers/employees/" +
              `?center_id=${this.centerId}`,
              options
            );
            if (resp.status === 200) {
              this.centerEmployees = resp.data
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    async getCenterTeams() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      if(this.centerId !== null & this.centerId !== "null") {
        if(this.role === "supervisor" || this.role === "administrator") {
          try {
            const resp = await axios.get(
              API_INFO + "/dashboard/centers/teams/" +
              `?center_id=${this.centerId}`,
              options
            );
            if (resp.status === 200) {
              this.centerTeams = resp.data
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    async getEmployeesReserv(begin_date, end_date) {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/reservations/employees/" +
          `?begin_date=${begin_date}&end_date=${end_date}`,
          options
        );
        if (resp.status === 200) {
          this.reservResponse = resp.data
          console.log(this.reservResponse);
        }
      } catch (e) {
        console.log(e);
        this.reservations = ["no hay reservas"]
      }
    },
    async getEmployeesHours(begin_date, end_date) {
      let resp
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        if(this.role === "administrator") {
          resp = await axios.get(
            API_INFO + "/dashboard/employees/working-hours/" +
            `?begin_date=${begin_date}&end_date=${end_date}&center_id=${this.centerId}`,
            options
          );
        } else {
          resp = await axios.get(
            API_INFO + "/dashboard/employees/working-hours/" +
            `?begin_date=${begin_date}&end_date=${end_date}`,
            options
          );
        }
        if (resp.status === 200) {
          this.employeesHours = resp.data
        }
      } catch (e) {
        console.log(e);
      }
    },
    async returnEvents(info, successCallback, failureCallback) {
      this.obtainCalendarDays()
      var startDate = new Date()
      var endDate = new Date();
      endDate.setDate(startDate.getDate() + 21);
      startDate.setDate(startDate.getDate() - 10);
      startDate = startDate.toISOString().split('T')[0]
      endDate = endDate.toISOString().split('T')[0]
      if(this.role === "employee" || this.employeeFilter !== "" & this.employeeFilter !== "null") {
        if(this.reservations.length === 0) {
          await this.getEmployeesReserv(startDate, endDate)
          for(var i in this.reservResponse) {
            for (const j in this.reservResponse[i]) {
              if(this.reservResponse[i][j]["center_id"] == this.centerId) {
                this.reservations.push(Object(
                  {
                    id: this.reservResponse[i][j]["reservation_id"],
                    title: this.reservResponse[i][j]["services"][0],
                    start: this.reservResponse[i][j]["start"],
                    end: this.reservResponse[i][j]["end"],
                    employeeId: this.reservResponse[i][j]["employee_id"],
                    backgroundColor: this.reservResponse[i][j]["backgroundColor"]
                  }
                ))
              }
            }
          }
          if(this.role === "employee") {
            this.employeeFilter = parseInt(localStorage.id) 
          }
          this.showScheduleSelectors = false
          successCallback(this.reservations)
          let calendarApi = this.$refs.fullCalendar.getApi()
          calendarApi.refetchEvents()
        } else if(this.employeeFilter === "all") {
          this.showScheduleSelectors = false
          successCallback(this.reservations)
        } else {
          let filteredReservations = this.reservations
          filteredReservations = filteredReservations.filter((el) => this.employeeFilter === el.employeeId);
          this.showScheduleSelectors = false
          successCallback(filteredReservations)
        }
      } else {
        if(this.employeesWorkingHours.length === 0 & this.employeeFilter === "") {
          await this.getEmployeesHours(startDate, endDate)
          for(let i in this.employeesHours) {
            for (const j in this.employeesHours[i]) {
              var activeEmployee
              if(Object.keys(this.employeesHours[i][j]["working_hours"]).length === 2) {
                let start = `${i} ` + this.employeesHours[i][j]["working_hours"]["begin"]
                let end = `${i} ` + this.employeesHours[i][j]["working_hours"]["end"]
                activeEmployee = {
                  id: this.employeesHours[i][j]["employee_id"],
                  title: this.employeesHours[i][j]["employee_name"],
                  start: start,
                  end: end,
                  team: this.employeesHours[i][j]["team"],
                  backgroundColor: "#839c49"
                }
                this.employeesWorkingHours.push(activeEmployee)
              }
              
            }
          }
          if(this.appliedFilter !== "") {
            let filter = {}
            filter["team"] = this.appliedFilter
            filter["employee"] = this.employeeFilter
            this.applyFilters(filter)
          } else {
            this.showScheduleSelectors = true
            successCallback(this.employeesWorkingHours)
          }
        } else if (this.employeesWorkingHours.length !== 0 & this.teamFilter === "") {
          this.showScheduleSelectors = true
          successCallback(this.employeesWorkingHours)
        } else {
          if(this.teamFilter === null || this.teamFilter === "all") {
            this.showScheduleSelectors = true
            successCallback(this.employeesWorkingHours)
          } else {
            this.filteredEmployees = this.employeesWorkingHours
            this.filteredEmployees = this.filteredEmployees.filter((el) => this.teamFilter === el.team);
            this.showScheduleSelectors = true
            successCallback(this.filteredEmployees)
          }
        }
      }
    },
    async handleEventClick(event) {
      await this.getReservationDetail(event.event._def.publicId)
      this.showEventModal = !this.showEventModal
    },
    async obtainCalendarDays() {
      await delay(0)
      let calendarApi = this.$refs.fullCalendar.getApi()
      let date = calendarApi.view.activeStart
      this.daysHoursTable = []
      // Se itera sobre 7 días a partir de la fecha actual para obtener la fecha qeu le corresponde a cada select.
      for(let i=0; i<7; i++) {
        var date2 = ""
        date2 = new Date(date.valueOf())
        date2 = date2.toISOString().split('T')[0]
        var obj = {}
        // A cada fecha se le da el valor "null" para mostrar la opción inicial en cada select.
        obj[date2] = "null"
        this.daysHoursTable.push(date2)
        this.currentDates.push(Object(obj))
        date.setDate(date.getDate() + 1)
      }
      if(!this.showScheduleSelectors) {
        this.sumEmployeesHours()
      }
      if(this.currentDates.length > 7) {
        this.currentDates = this.currentDates.slice(7)
      }
    },
    sumEmployeesHours(reservations) {
      let employeeReservsForDay = []
      let employeeReservs
      this.totalHoursReservations = []
      this.daysHoursTable.forEach(el => {
        if(this.reservResponse[el]) {
          let holdVar = this.reservResponse[el]
          employeeReservsForDay = []
          holdVar.forEach(elem => {
            employeeReservs = ""
            employeeReservs = holdVar.filter(el => el.employee_id == elem.employee_id)
            employeeReservs = employeeReservs.filter(el => el.center_id == this.centerId)
            if(this.employeeFilter !== "" & this.employeeFilter !== "null" & this.employeeFilter !== "all") {
              employeeReservs = employeeReservs.filter(el => el.employee_id == this.employeeFilter)
            }
            holdVar = holdVar.filter(el => el.employee_id !== elem.employee_id)
            if(employeeReservs.length !== 0) {
              let hoursCalculated = this.calculateReservsHours(employeeReservs)
              employeeReservsForDay.push(hoursCalculated)
            }
          })
          this.totalHoursReservations.push(employeeReservsForDay)
        } else {
          employeeReservsForDay = []
          this.totalHoursReservations.push(employeeReservsForDay)
        }
      })
    },
    calculateReservsHours(item) {
      let total = 0
      let employeeId
      item.forEach(el => {
        let start = el.start.slice(11)
        let end = el.end.slice(11)
        let start2 = new Date("01/01/2007 " + start)
        let end2 = new Date("01/01/2007 " + end)
        let hourDiff = end2.valueOf() - start2.valueOf();
        total += hourDiff
        employeeId = el.employee_id
      })
      total = this.millisecondsToTime(total)
      total = total.slice(0, -3)
      return "Empleado ID " + employeeId + ": " + total + " hs";
    },
    millisecondsToTime(milliseconds) {
      //Obtener horas
      let hours = milliseconds / (1000*60*60);
      let absoluteHours = Math.floor(hours);
      let h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
      //Obtener minutos
      let minutes = (hours - absoluteHours) * 60;
      let absoluteMinutes = Math.floor(minutes);
      let m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;
      //Obtener segundos
      let seconds = (minutes - absoluteMinutes) * 60;
      let absoluteSeconds = Math.floor(seconds);
      let s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;
      return h + ':' + m + ':' + s;
    },
    async confirmDate(date,e) {
      let msg = "¿Desea guardar los cambios para la fecha seleccionada o elegir rango de fechas?"
      if(this.teamFilter === "" || this.teamFilter === "all" || this.teamFilter === "null") {
        this.errorMessage = "Debe seleccionar un equipo para aplicar los cambios"
        this.showAlertError = true
        await delay(3600)
        this.showAlertError = false
      } else if (this.teamFilter !== "" & this.teamFilter !== "" & this.teamFilter !== "null") {
        let ok = await yesno({
          bodyText: msg,
          labelYes: "Fecha seleccionada",
          labelNo: "Elegir fechas"
        })
        this.selectedScheduleValue = e
        this.selectedDate = date
        if(ok) {
          this.applyScheduleChanges(date)
        } else {
          this.showDateInputsModal = true
        }
      }
    },
    async applyScheduleChanges(date) {
      this.setHourAndDate(date)
      this.selectedEmployees = this.employeesHours[this.selectedDate]
      this.selectedEmployees = this.selectedEmployees.filter(
        el => Object.keys(el.working_hours).length > 1
      )
      this.selectedEmployees = this.selectedEmployees.filter(
        el => el.team === this.teamFilter
      )
      for(let j of this.selectedEmployees) {
        let id = j.employee_id
        this.saveScheduleChanges(id)
      }
      this.appliedFilter = this.teamFilter.valueOf()
      this.employeesWorkingHours = []
      this.teamFilter = ""
      this.employeeFilter = ""
      let calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.refetchEvents()
      this.selectedScheduleValue = ""
    },
    async saveScheduleChanges(id) {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      const body = {
        begin_date: this.beginDate,
        end_date: this.endDate,
        begin_time: this.hour.begin,
        end_time: this.hour.end
      }
      try {
        const resp = await axios.post(
          API_INFO + "/dashboard/employees/" + 
          `${id}/assign-working-hours/`,
          body,
          options
        );
        if (resp.status === 200) {
          console.log("ok");
        }
      } catch {
        console.log("ERROR");
      }
    },
    openModalScheduleChanges(selectDate, value) {
      this.buttonDate = selectDate
      this.showScheduleChangeModal = true
    },
    applyFilters(filters) {
      this.teamFilter = filters["team"]
      this.employeeFilter = filters["employee"]
      let calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.refetchEvents()
    },
    setHourAndDate(date) {
      if(this.selectedScheduleValue == 1) {
        this.hour["begin"] = "10:00"
        this.hour["end"] = "14:00"
      } else if(this.selectedScheduleValue == 2) {
        this.hour["begin"] = "14:00"
        this.hour["end"] = "19:00"
      } else {
        this.hour["begin"] = {}
        this.hour["end"] = {}
      }
      if(typeof(date) === "object") {
        this.beginDate = date["start"]
        this.endDate = date["end"]
      } else {
        this.beginDate = date
        this.endDate = date
      }
    },
    filterByCenter(value) {
      this.centerId = value
      this.getCenterEmployees()
      this.getCenterTeams()
      let calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.refetchEvents()
    }
  }
}
</script>

<style lang='scss' scoped>
.full-container{
  width: 100%;
  position: relative;
}
.gray {
  background-color: #d6d6d666 !important;
}
.title {
  height: 140px;
  background: #b5b8a78f;
  h1 {
    font-size: 3.5em;
    text-align: center;
  }
}
.fc-theme-standard td, .fc-theme-standard th {
  border: 1px solid #9a0000 !important;
}
table {
  background-color: #f1f1f1 !important;
}
.calendar {
  margin-bottom: 20px;
  @media (max-width: 550px) {
    margin-bottom: 0px;
  }
}
.filters-button {
  position: absolute;
  top: 206px;
  left: 3px;
  background-color: #2B3D4F;
  color: #fff;
  @media (max-width: 698px) {
    top: 231px;
  }
  @media (max-width: 549px) {
    top: 82px;
  }
  @media (max-width: 429px) {
    top: 120px;
  }
}
.table-icon {
  position: absolute;
  top: 206px;
  left: 45px;
  @media (max-width: 698px) {
    top: 229px;
  }
  @media (max-width: 549px) {
    top: 80px;
  }
  @media (max-width: 429px) {
    left: 3px;
    top: 82px;
  }
}
.menu-icons {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  z-index: 100;
}
.day-col {
  position: relative;
  top: 90px;
  .bi-list {
    position: relative;
    top: 35px;
    left: 29px;
    width: 22px;
    height: 22px;
    background: #2C3E50;
    color: white;
    border-radius: 4px;
  }
}
.day-col {
  flex: 0 0 13.78%;
  max-width: 13.78%;
  @media (max-width: 1260px) {
    left: 14px
  }
  @media (max-width: 860px) {
    left: 22px
  }
  @media (max-width: 699px) {
    flex: 0 0 13.38%;
    max-width: 13.38%;
    left: 7px
  }
  @media (max-width: 550px) {
    flex: 0 0 13.78%;
    max-width: 13.78%;
    left: -3px
  }
  @media (max-width: 360px) {
    flex: 0 0 13.38%;
    max-width: 13.38%;
    left: -14px
  }
}
.hours-col {
  flex: 0 0 13.78%;
  max-width: 13.78%;
  @media (max-width: 1260px) {
    left: 14px;
    flex: 0 0 13.48%;
    max-width: 13.48%;
  }
}
.days-row {
  justify-content: flex-end;
}
.hour-title {
  text-align: center;
  width: 96.4%;
  height: 45px;
  float: right;
  border: 1px solid #6d6d6d;
  background: #f9f9f9;
  padding-bottom: 7px;
  @media (max-width: 1260px) {
    width: 94.4%;
  }
  h3 {
    position: relative;
    top: -10px;
  }
}
.hours-div {
  // width: inherit;
  border: 1.5px solid #4e4e4e;;
}
.show-reserv {
  background: #b7f3a8;
  li {
    list-style: none;
    font-weight: 900;
    font-size: 15px;
  }
  ul {
    margin-left: -30px;
    text-align: center;
  }
}
.no-reserv {
  background:#ffdede;
  li {
    list-style: none;
    font-weight: 900;
    font-size: 15px;
  }
  ul {
    margin-left: -30px;
    text-align: center;
  }
}

</style>