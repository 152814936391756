<template>
  <div v-if="showCard === true" class="up-modal" style="right: 0px">
    <b-row class="justify-content-center card-row">
      <b-icon-x-square @click="closeModal" />
      <b-col col xs="12" md="5" class="p-0 justify-content-center d-flex">
            <b-col 
              cols="10"
              class="employee-card"
              :class="type === 'ett' ? 'ett-card' : ''"
            >
              <!-- <br> -->
              <p 
                class="text-center card-title mt-5"
                :class="type === 'ett' ? 'ett-card-title' : ''"
              > 
              {{typeWorker.is_ett ? "ETT": "Empleado"}} ID 
              {{typeWorker.id}} - {{typeWorker.name}}
              </p>
              <b-row>
                <b-col col xs="12" md="3" class="img-col" >
                </b-col>
                <b-col col xs="12" md="9" class="text-wrap">
                  <p><strong>Nombre:</strong> {{typeWorker.name}}</p>
                  <p><strong>Dirección:</strong> {{typeWorker.address}}</p>
                  <p><strong>Email:</strong> {{typeWorker.email}}</p>
                  <p><strong>Teléfono:</strong> {{typeWorker.phone_number}}</p>
   
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <td>
                    <tr 
                      v-for="(i, j) in typeWorker.services"
                      :key="j"
                      class=""
                    >
                      {{i.name}}
                    </tr>
                  </td>
                </b-col>
              </b-row>
              <br>
              <p class="text-center turnos">Turnos/Citas</p>
            </b-col>
          </b-col>
    </b-row>
  </div>
</template>
<script>
import { API_INFO } from "@/constants.js";
import axios from "axios";

export default {
  
  props: {
    typeWorker: Array,
    showCard: Boolean,
    type: String
  },
  data() {
    return {
      name: "",
      description: "",

    };
  },
  mounted() {
    
  },
  methods: {
    closeModal() {
      this.$emit("close-modal")
    },
  },
};
</script>

<style lang="scss" scoped>

.text-wrap{
  flex-wrap: wrap
}
.text-wrap p{
  margin-bottom: 0px !important;
  font-size: 1em !important;
}
.card-row {
  position: relative;
  top: 20vh;
  // max-height: 84vh;
  // overflow: auto; 
}

.employee-card {
  background:rgb(241, 241, 241);
  border-radius: 65px;
  border: 4px solid rgb(75, 122, 209);
  p {
    font-weight: 700;
  }
}
.ett-card{
  border: 4px solid rgb(206, 120, 86) ;
}
.ett-card-title {
  background: #d4bfb5;
}
.turnos {
  background: rgb(122, 179, 65);
  width: 60%;
  border-radius: 4px;
  margin: 0px auto 10px;
  color: rgb(221, 221, 221);
}

.img-col {
  background: url(../../assets/img/iaFace.jpeg);
  background-size: contain;
  height: 6.8vw;
  width: 6.8vw;
  background-repeat: no-repeat;
  position: relative;
  left: 7%;
}
.bi-x-square {
  width: 34px;
  height: 34px;
  position: absolute;
  top: -35%;
  right: 45px;
  cursor: pointer;
  background: antiquewhite;
  z-index: 130;
  &:hover {
    background: rgba(255, 117, 98, 0.822);
  }
}
@media (max-width: 600px) {
  .img-col {

    height: 70px;
    width: 70px;
  
  }
}
</style>
