<template>
  <div>
    <div v-show="cart.length > 0 && !accepted" class="tabla-compra">
      <button class="btn btn-comprar" @click="managePurchaseForm">
        Aceptar
      </button>
    </div>
    <transition name="slide-fade">
      <section v-show="accepted" class="tabla-compra">
        <div class="container">
          <div id="carrito" class="row" style="">
            <section class="seleccion" id="datosclientereserva">
              <div class="container">
                <div class="row-md-12">
                  <div class="col-md-12 seleccion tabla-compra-listado">
                    <form class="form-horizontal row" v-on:submit.prevent>
                      
                      <div class="col-md-12">
                        <button
                          class="btn btn-comprar pull-right"
                          @click="reservationRegister"
                          
                        >
                          Confirmar Reserva
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </transition>
    <Stepper
      :legalConditions="legalConditions"
      @close-modal="closeModal()"
      @send-success="acceptTerms()"
    />

    <ModalAlert
      :showCard="showAlert"
      :title="message"
      :varian="varian"
      @close-modal="() => showAlert = false"
    />
    <div v-if="successPayment">
      <h3>
        El pago se realizó con éxito
        <b-icon icon="credit-card" aria-hidden="true"></b-icon>
      </h3>
    </div>
  </div>
</template>

<script>
import Stepper from "@/components/legalConditions/Stepper.vue";
import ModalAlert from "@/components/alerts/ModalAlert.vue";
// import Payment from "@/components/payment/index.vue";
import delay from "delay";
import axios from "axios";
import { API_INFO } from "@/constants.js";

export default {
  components: {
    Stepper,
    ModalAlert,
    // Payment,
  },
  props: {
    cart: Array,
    direction: Array,
    accepted: Boolean,
    idClient: Number,
    date: String,
    hour: String,
    centerId: Number,
    feeValue: Number,
    totalPrice: Number
  },
  data: () => ({
    legalConditions: false,
    showAlert: false,
    logged: false,
    paymentMethod: "",
    successPayment: false,
    okTerms: "not accepted",
    message: '',
    varian: '',
    email: '',
    nif_nie: '',
    phone: '',
    last_name: '',
    first_name: '',
    password: '',
    password2: '',
    health_considerations: '',
    idHammUser: localStorage.id
  }),
  methods: {
    managePurchaseForm() {
      const tokenExist = localStorage.getItem("accessToken");
      if (tokenExist) {
        this.logged = true;
      }
      this.accepted = true;
    },
    setPaymentMethod(event) {
      this.paymentMethod = event.picked;
    },
    async verificationClient(){
      try {
        const resp = await axios.get(
          API_INFO + "/clients/validation/?email="+this.email
        );
        if (resp.status === 200) {
          this.idClient = resp.data.client_id
          this.last_name= resp.data.last_name
          this.first_name= resp.data.first_name
          this.reservationRegister()
        } else {
          console.log("entro")
          this.registerClient()
        }
      } catch {
        this.registerClient()
      }
    },
    async registerClient(){
      try {
        if (this.password !== this.password2) {
          this.message = "No coinciden las contraseñas."
          this.showAlert = true;
          this.varian = 'warning';
          return false;
        }
        if (this.password == ''){
          this.password = 'd3f@u1t';
          this.password2 = 'd3f@u1t' ;
        }
        const resp = await axios.post(
          API_INFO + "/clients/register/",
          {
            email: this.email,
            nif_nie: this.nif_nie,
            phone: this.phone,
            last_name: this.last_name,
            first_name: this.first_name,
            address: this.direction[0],
            postal_code: this.direction[3],
            province: null,
            country_id: 1,
            health_considerations: this.health_considerations,
            password: this.password,
            password2: this.password2,
            prospect_id: localStorage.prospectId,
            terms_accepted: true,
            news_accepted: true,
          }
        );
        if (resp.status === 201) {
          this.idClient = resp.data.id
          this.reservationRegister();
        } else {
          this.message = resp.data
          this.showAlert = true;
          this.varian = 'warning';
        }
      } catch {
        this.error = true;
        await delay(4200);
        this.error = false;
      }
    },
    async reservationRegister() {
      try {
        let service_ids = []
        let service_quantitys = []
        this.cart.forEach(item => {
          service_ids.push(item.id)
          service_quantitys.push(item.quantity)
        });           
        const resp = await axios.post(
          API_INFO + "/reservations/",
          {
            service_ids: service_ids,
            service_quantitys: service_quantitys,
            client_id: this.idClient,
            date: this.date,
            time: this.hour,
            center_id: this.centerId,
            prospect_id: localStorage.prospectId,
            address: this.direction[0],
            postal_code: this.direction[3],
            province: this.direction[2],
            country: this.direction[1],
            total: this.totalPrice,
            fee_value: this.feeValue,
            reception: true,
            hammam_user: parseInt(this.idHammUser) 
          }
        );
        if (resp.status === 201) {
          if (this.logged == false) {
            const client_data = {
              last_name: this.last_name,
              first_name: this.first_name,
              client_id: this.idClient
            }
            this.$emit("name-client", 
              client_data
            );
          }
          this.$emit("confirm-reservation", 
            resp.data
          );
        }
      } catch (error){
        if (error.response.status == 500){
          this.message = "Lo sentimos, el servidor no ha podido procesar su consulta, inténtelo mas tarde."
          this.showAlert = true;
          this.varian = 'warning';
        } else {
          this.message = "Por favor, inténtalo de nuevo."
          this.showAlert = true;
          this.varian = 'warning';
        }
      }
    },
    acceptTerms() {
      this.legalConditions = false;
      this.okTerms = "accepted";
    },
    closeModal() {
      this.legalConditions = false;
    },
    hideTable() {
      this.$emit("update:accepted", "");
    },
  },
};
</script>

<style lang="scss" type="text/css" scoped>
.form-label {
  width: 13% !important;
  @media only screen and (max-width: 767px) and (min-width: 720px) {
    width: 168px !important;
  }
}
.long {
  width: 100% !important;
  position: relative;
  left: 5px;
  @media (min-width: 720px) {
    width: 65.6% !important;
  }
  @media only screen and (max-width: 767px) and (min-width: 720px) {
    width: 302.4px !important;
  }
}
.seleccion {
  padding-top: 0px;
}
.btn-block {
  width: 30%;
  height: 45px;
  font-size: 1.5rem;
  margin: auto;
  position: relative;
  top: -20px;
}
.tabla-compra {
  display: flex;
  justify-content: center;
}
h3 {
  text-align: center;
}
.terms {
  width: 30%;
  font-size: 1.6rem;
  height: 50px;
  &:hover {
    background: #667688;
  }
  .custom-checkbox {
    position: relative;
    top: 4px;
  }
  @media (max-width: 767px) {
    width: 47%;
  }
  @media (max-width: 390px) {
    width: 82%;
  }
}
.showTerms {
  cursor: pointer;
  background: rgba(202, 202, 202, 0);
  position: relative;
  top: -20px;
  width: 80%;
  height: 90%;
  z-index: 100;
}
//transition effect
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0.6;
}
</style>
