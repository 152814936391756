<template>
  <div class="full-container">
    <NavBar />
  
  <b-container fluid class="h-100 gray">

    <b-row class="title">
      <b-col 
        col
        xs="12"
        class="d-flex justify-content-center"
      >
        <h1 class="m-auto">Alerta de reservas</h1>
      </b-col>

    </b-row>
    <br >
    <b-row>
      <b-col cols="12"  class="justify-content-center">
          <h4>Citas a domicilio en las próximas 48 horas</h4>
      </b-col>
      <b-col cols="12"  class="d-flex justify-content-center" v-if="items_48.length > 0">
        <div class="table-responsive ">
          <b-button 
              @click="exportData(1)" variant="info"
            >
              <b-icon-download class="mr-3" />
              Exportar
          </b-button>
          <br>
          <b-table class="table table-responsive table-bordered table-hover margin_top" :fields="fields_48" responsive :items="items_48" @row-clicked="detailReservation" ></b-table>
        </div>
      </b-col>
      <b-col cols="12"  class="d-flex justify-content-center" v-else>
        <small>No se encontraron datos.</small>
      </b-col>
 
    </b-row>
    <br>
    <b-row>
      <b-col cols="12"  class="justify-content-center">
          <h4>Servicios asignados a masajistas ficticios</h4>
      </b-col>
      <b-col cols="12"  class="d-flex justify-content-center" v-if="items_fictisio.length > 0">
        <div class="table-responsive ">
          <b-button 
              @click="exportData(2)" variant="info"
            >
              <b-icon-download class="mr-3" />
              Exportar
          </b-button>
          <br>
          <b-table class="table table-responsive table-bordered table-hover margin_top" :fields="fields_48" responsive :items="items_fictisio" @row-clicked="detailReservation" ></b-table>
        </div>
      </b-col>
      <b-col cols="12"  class="d-flex justify-content-center" v-else>
        <small>No se encontraron datos.</small>
      </b-col>
 
    </b-row>
    <br>
    <b-row>
      <b-col cols="12"  class="justify-content-center">
          <h4>Consulta reservas</h4>
      </b-col>
      <b-col cols="12"  class="justify-content-center">
        <div class="search">
            <ul class="ul-search">
        
              <li><input
                  class="form-control"
                  placeholder="id servicio"
                  type="text"
                  v-model="id_servicio"
                /></li>
    
              <li> <b-form-datepicker
                  class="form-control"
                  v-model="date_Search"
                  placeholder="Fecha"
                  size="sm"
                  locale="es"
                  type="text"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  :start-weekday="1"
                ></b-form-datepicker>
                </li>

              <li> <input
                  class="form-control"
                  placeholder="masajista id"
                  type="text"
                  v-model="employee_search"
                /></li>
              
              <li> <input
                  class="form-control"
                  placeholder="cliente id"
                  type="text"
                  v-model="client_search"
                /></li>
                <li>
                  <b-button @click="getListReservationSearch"  variant="primary">
                    <b-icon-search />
                    Buscar
                  </b-button>
                </li>
            </ul>
            
        </div>
        <br>
        <br>
      </b-col>
      
      <b-col cols="12"  class="d-flex justify-content-center" v-if="items_search.length > 0">
        
        <div class="table-responsive margin-bottom-60">
          <b-button 
              @click="exportData(3)" variant="info"
            >
              <b-icon-download class="mr-3" />
              Exportar
          </b-button>
          <br>
          <b-table class="table table-responsive table-bordered table-hover margin_top" :fields="fields_48" responsive :items="items_search" @row-clicked="detailReservation" ></b-table>
        </div>
      </b-col>
      <b-col cols="12"  class="d-flex justify-content-center margin-bottom-60" v-else>
        <small>No se encontraron datos.</small>
      </b-col>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
    </b-row>
    <ModalAlert
        :showCard="showAlert"
        :title="alertMessage"
        :varian="varianAlert"
        @close-modal="() => showAlert = false"
      />
    
    <ModalReservationDetail
      :showModal="showModalDetail"
      :reservation="reservationDetail"
      :list_employee="list_employee"
      :showAlertSucces="false"
      :showAlert="false"
      @close-modal="showModalDetail = false"
      @alert="alertModal"
      @loading="loadingAll"
    />

    <AlertLoading
      :showLoading="showLoading"
    />
  </b-container>
  </div>
</template>

<script>

import ModalAlert from "@/components/alerts/ModalAlert.vue"
import AlertLoading from "@/components/alerts/AlertLoading.vue"
import ModalReservationDetail from "@/components/dashboard/ModalReservationDetail.vue"
import NavBar from "@/components/NavBar.vue"

import { API_INFO } from "@/constants.js";
import axios from "axios";
import delay from 'delay';


export default {
  components: {
    ModalAlert,
    ModalReservationDetail,
    AlertLoading,
    NavBar
  },
  data() {
    return {
      fields_48: [{
            label: 'ID',
            key: 'id',
            sortable: true
          },
          {
            label: 'FECHA',
            key: 'date',
            sortable: false
          },
          {
            label: 'DIRECCIÓN DESTINO',
            key: 'address',
            sortable: false
          },
          {
            label: 'DIRECCIÓN ORIGEN',
            key: 'origin_address',
            sortable: false
          },
          {
            label: 'LOCALIDAD',
            key: 'locality',
            sortable: false
          },
          {
            label: 'HORA SALIDA',
            key: 'end_massage',
            sortable: false
          },
          {
            label: 'ESTADO',
            key: 'status_detail',
            sortable: false
          },
          {
            label: 'MASAJISTA ASIGNADO',
            key: 'employee_name',
            sortable: false
          },
          {
            label: 'CLIENTE ID',
            key: 'client_id',
            sortable: false
          },

      ],
      items_48: [],
      items_fictisio: [],
      items_search: [],
      reservationDetail: [],
      list_employee: [],
      alertMessage: "",
      showAlert: false,
      showModalDetail:false,
      showLoading: false,
      varianAlert: '',
      id_servicio: '',
      date_Search: '',
      employee_search: '',
      client_search: '',
      fecha_hoy: '',
      fecha_next: '',
      is_administrator: false,
    }
  },
  created() {
    if(localStorage.getItem('role') == 'administrator'){
      this.is_administrator = true
    }
    this.dateCalculation()
    this.getReservation48()
    this.getReservationFicticio()
  },

  methods: {
    async getReservation48() {
      this.showLoading = true;
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {

        let data_search = 'begin_date='+this.fecha_hoy
          data_search += '&end_date='+this.fecha_next
          data_search += '&id_service='+this.id_servicio
          data_search += '&employee='+this.employee_search
          data_search += '&client='+this.client_search
          data_search += '&center='+localStorage.getItem('centerId')
          data_search += '&administrator='+this.is_administrator
          data_search += '&ficticio=0'
        const resp = await axios.get(API_INFO + "/dashboard/reservations/search/?"+data_search, options);
        if (resp.status === 200) {
          this.items_48 = resp.data
          this.showLoading = false;
        }
      } catch (e) {
        console.log(e);
        this.showLoading = false;
      }
    },
    async getReservationFicticio() {
      this.showLoading = true;
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        // 2021-03-11
        let data_search = 'begin_date='+this.fecha_hoy
          data_search += '&end_date='+this.fecha_next
          data_search += '&id_service='+this.id_servicio
          data_search += '&employee='+this.employee_search
          data_search += '&client='+this.client_search
          data_search += '&center='+localStorage.getItem('centerId')
          data_search += '&administrator='+this.is_administrator
          data_search += '&ficticio=1'
        const resp = await axios.get(API_INFO + "/dashboard/reservations/search/?"+data_search, options);
        if (resp.status === 200) {
          this.items_fictisio = resp.data
          this.showLoading = false;
        }
      } catch (e) {
        console.log(e);
        this.showLoading = false;
      }
    },
    detailReservation(row) {
      
      this.reservationDetail = row;
      this.getEmployeeList()
    },
    async getEmployeeList() {
      this.showLoading = true;
        const options = {
          headers: { 
            Authorization: "JWT " + localStorage.accessToken,
          },
        };
  
          const resp = await axios.get(API_INFO + "/dashboard/employees/working-hours/?begin_date="+this.reservationDetail.date+
                                      '&end_date='+this.reservationDetail.date, options);
          if (resp.status === 200) {
            // console.log(resp.data)
            const data_employee = resp.data[this.reservationDetail.date]
            data_employee.forEach(item => {
              
              this.list_employee.push({ value: item.employee_id, text: item.employee_name })
            });

            this.showLoading = false;
            this.showModalDetail = true;
          }

    },
    async getListReservationSearch() {
      if (this.id_servicio != '' || this.date_Search != '' || this.employee_search != '' || this.client_search != '')  {
        this.showLoading = true;
        const options = {
          headers: { 
            Authorization: "JWT " + localStorage.accessToken,
          },
        };
        try {
          let data_search = 'begin_date='+this.date_Search
          data_search += '&end_date='+this.date_Search
          data_search += '&id_service='+this.id_servicio
          data_search += '&employee='+this.employee_search
          data_search += '&client='+this.client_search
          data_search += '&center='+localStorage.getItem('centerId')
          data_search += '&administrator='+this.is_administrator
          data_search += '&ficticio=0'
          const resp = await axios.get(API_INFO + "/dashboard/reservations/search/?"+data_search, options);
          if (resp.status === 200) {
            // console.log(resp.data)
            if (resp.data) {
              this.items_search = resp.data;
            } else {
              this.items_search = [];
            }
            this.showLoading = false;
          }
        } catch (error) {
          this.showLoading = false;
          this.showAlert = true;
          this.alertMessage = 'por favor compruebe su conexión a internet.';
          this.varianAlert = 'warning';
        }
        
      } else {
        this.showLoading = false;
        this.showAlert = true;
        this.alertMessage = 'Se debe seleccionar valores para la búsqueda.';
        this.varianAlert = 'warning';
      }

    },
    alertModal(val){
      if (val == 1){
        this.showAlert = true;
        this.alertMessage = 'por favor compruebe su conexión a internet.';
        this.varianAlert = 'warning';
      }

      if (val == 2){
        this.showAlert = true;
        this.alertMessage = 'Datos guardados con éxito.';
        this.varianAlert = 'success';
      }

      if (val == 3){
        this.showAlert = true;
        this.alertMessage = 'Datos incorrectos, por favor revice la información ingresada.';
        this.varianAlert = 'warning';
      }
    },
    loadingAll(){
      this.getReservation48();
      this.getReservationFicticio()
      this.getListReservationSearch();
    },
    async exportData(val){
      this.showLoading = false;
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        }, responseType: "blob" ,
      };
      try {
        let data_search = ''
        if(val==1){
          data_search = 'begin_date='+this.fecha_hoy
          data_search += '&end_date='+this.fecha_next
          data_search += '&id_service='
          data_search += '&employee='
          data_search += '&client='
          data_search += '&center='+localStorage.getItem('centerId')
          data_search += '&administrator='+this.is_administrator
          data_search += '&ficticio=0'
        }

        if(val==2){
          data_search = 'begin_date='+this.fecha_hoy
          data_search += '&end_date='+this.fecha_next
          data_search += '&id_service='
          data_search += '&employee='
          data_search += '&client='
          data_search += '&center='+localStorage.getItem('centerId')
          data_search += '&administrator='+this.is_administrator
          data_search += '&ficticio=1'
        }

        if(val==3){
          data_search = 'begin_date='+this.date_Search
          data_search += '&end_date='+this.date_Search
          data_search += '&id_service='+this.id_servicio
          data_search += '&employee='+this.employee_search
          data_search += '&client='+this.client_search
          data_search += '&center='+localStorage.getItem('centerId')
          data_search += '&administrator='+this.is_administrator
          data_search += '&ficticio=0'
        }
        const resp = await axios.get(
          API_INFO + "/dashboard/reservations/export/?"+data_search,
          options
        );
        if (resp.status === 200) {
          //console.log(resp.data)
          this.showLoading = false;
          const url = window.URL.createObjectURL(new Blob([resp.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", "reservas.xlsx")
          document.body.appendChild(link)
          link.click()
        }
      } catch (e) {
        this.showLoading = false;
      }
    },
    dateCalculation(){
        let hoy = new Date();
        let mes = ''
        if ((hoy.getMonth() + 1) > 9) {
          mes = (hoy.getMonth() + 1)
        } else {
          mes = '0' + (hoy.getMonth() + 1)
        }
        let dia = ''
        if(hoy.getDate() > 9){
          dia = hoy.getDate()
        } else {
          dia = '0' + hoy.getDate()
        }
        this.fecha_hoy = hoy.getFullYear() + '-' + mes + '-' + dia
        let next_day = hoy.setDate(hoy.getDate() + 2);
        next_day = new Date(next_day);
        let mes2 = ''
        if ((next_day.getMonth() + 1) > 9) {
          mes2 = (next_day.getMonth() + 1)
        } else {
          mes2 = '0' + (next_day.getMonth() + 1)
        }
        let dia2 = ''
        if(next_day.getDate() > 9){
          dia2 = next_day.getDate()
        } else {
          dia2 = '0' + next_day.getDate()
        }
        this.fecha_next = next_day.getFullYear() + '-' + mes2  + '-' + dia2
    },
    
  }
}
</script>

<style lang='scss' scoped>
.full-container{
  width: 100%;
  position: relative;
}
.bg-hammam{
  background-color: #e18435;
}

.margin_top{
  margin-top: 10px !important;
}
.search {
  td:nth-of-type(2n+1) {
    width: 7vw;
  }
  td:nth-of-type(2n+2) {
    width: 12vw;
  }
  select {
    position: relative;
    top: 0px;
  }
}
.ul-search{
  list-style: none;
}

.ul-search li{
  float: left;
}
p {
  font-weight: 500;
}
.gray {
  background-color: #9ea09266 !important;
}
.title {
  height: 140px;
  background: #b5b8a78f;
  h1 {
    font-size: 2.5em;
  }
}
.title2 {
  padding: 10px;
  background: #b5b8a78f;
  font-size: 2rem;
  display: flex;
  justify-content: space-evenly;
}


.margin-top-20{
  margin-top: 20px;
}
footer{
  padding: 0px !important;
}
.margin-bottom-60{
  margin-bottom: 60px !important;
}
@media (max-width: 600px) {
  .ul-search li{
    float: none;
  }

}
</style>
