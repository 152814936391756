<template>
  
  <b-container fluid class="h-100 gray">
    <!-- <b-row class="justify-content-center">

      <div v-for="i in 7" :key="i" class="dia border border-info">
        <b-table striped hover :items="items"></b-table>
      </div>
    </b-row> -->
    <b-row class="title">
      <b-icon-arrow-left-circle-fill />
      <h1 class="m-auto">Disponibilidad semanal</h1>
      <b-icon-arrow-right-circle-fill />
    </b-row>
    <b-row class="justify-content-center mt-4">
      <div v-for="i in 7" :key="i" class="dia altern-row">
        <tr>
          <td>2{{i}}/02/2021</td>
        </tr>
        <tr>
          <td>Mario</td>
        </tr>
        <tr>
          <td>Carlos</td>
        </tr>
        <tr>
          <td>María</td>
        </tr>
        <tr>
          <td>José</td>
        </tr>
        <tr>
          <td>Paula</td>
        </tr>
        <tr>
          <td>Andrés</td>
        </tr>
      </div>
    </b-row>
    <br>
    <hr>
    <b-row class="justify-content-center">
      <div v-for="i in 7" :key="i" class="dia res-disp altern-row">
        <tr>
          <td>Res</td>
          <td>Disp</td>
        </tr>
        <tr>
          <td>2</td>
          <td>8</td>
        </tr>
        <tr>
          <td>4</td>
          <td>3</td>
        </tr>
        <tr>
          <td>2</td>
          <td>2</td>
        </tr>
        <tr>
          <td>1</td>
          <td>6</td>
        </tr>
      </div>
      <div class="dia family position-absolute">
        <tr>
          <td>Familia A</td>
        </tr>
        <tr>
          <td>Familia B</td>
        </tr>
        <tr>
          <td>Familia C</td>
        </tr>
        <tr>
          <td>Familia D</td>
        </tr>
      </div>
    </b-row>
    <br><br><br>
    <b-row class="title2">
      <h3 class="m-auto">Asignación masajistas a domicilio</h3>
    </b-row>
    <b-row class="justify-content-center mt-4">
      <div class="tabla-masajistas mr-5">
        <td v-for="i in 4" :key="i">
          <tr v-for="j in 7" :key="j">
            <td v-if="j === 1">
              {{cuadrante[i-1]}}
            </td>
            <td v-else-if="i === 1 && j > 1">
              {{empleados[j-2]}}
            </td>
            <td v-else>
              OK
            </td>

          </tr>
        </td>
      </div>
      <div v-for="i in 5" :key="i" class="dia2 ml-5">
        <td>
          <tr>2{{i}}/02/2021</tr>
          <tr>OK</tr>
          <tr>OK</tr>
          <tr>OK</tr>
          <tr>OK</tr>
          <tr>OK</tr>
          <tr>OK</tr>
        </td>
      </div>
    </b-row>

    <br><br>
  </b-container>
  
</template>

<script>
export default {
  
  beforeMount() {
    if(localStorage.accessToken == '' || localStorage.accessToken == null){
      this.$router.push('/');
    }
  },
  data() {
      return {
        items: [
          { '05/02/2021': 'Mario' },
          { '05/02/2021': 'José' },
          { '05/02/2021': 'Carlos' },
          { '05/02/2021': 'Pedro' }
        ],
        cuadrante: [
          'Empleados',
          'Responsable',
          'Familia',
          'Ubicación'
        ],
        empleados: [
          'Empleado1',
          'Empleado2',
          'Empleado3',
          'Empleado4',
          'Masajista ficticio1',
          'Masajista ficticio2'
        ]
      }
    }

}
</script>

<style lang='scss' scoped>
.gray {
  background-color: #9ea09266 !important;
}
hr {
  height: 3px;
  width: 78.5%;
  background: #000;
}
.dia {
  flex: 0 0 10.5%;
  max-width: 10.5%;
  margin: 5px;
}
.dia2 {
  flex: 0 0 7%;
  max-width: 7%;
  margin: 0px 20px;
  td {
    border: none;
  }
  tr {
    height: 40px;
    display: block;
    padding-top: 9px;
    background: #cfc9cd;
  }
}
.tabla-masajistas {
  flex: 0 0 33%;
  max-width: 33%;
  // margin: 5px;
  &>td {
    border-right: none;
    border-left: none;
  }
  &>td:nth-of-type(4) {
    background: #cfc9cd;
  }
}
.title {
  height: 140px;
  background: #b5b8a78f;
  h1 {
    font-size: 3.5em;
  }
}
.title2 {
  height: 50px;
  background: #b5b8a78f;
  font-size: 2rem;
}
.bi-arrow-left-circle-fill {
  font-size: 55px;
  position: relative;
  top: 50px;
  left: 95px;
}
.bi-arrow-right-circle-fill {
  font-size: 55px;
  position: relative;
  top: 50px;
  right: 95px;
}
td {
  width: 260px;
  height: 40px;
  border: 1px solid #000;
  text-align: center;
  vertical-align: middle;
}
tr {
  width: 100%;
  min-width: 100%;
  border: 1px solid #000;
}
.altern-row {
  tr:nth-of-type(2n+1)>td {
    background:#d5c0c0;
  }
  tr:first-of-type>td {
    background: #68435bcc;
  }
}
td>tr:first-of-type {
  background: #68435bcc;
}
td>tr {
  border-bottom: 1px solid #000;
  // display: block;
}

.res-disp > tr > td:first-of-type {
  border-right: none;
}
.res-disp > tr > td:last-of-type {
  border-left: none;
}
.res-disp > tr:first-of-type > td {
  border-bottom: 1px solid #000;
  background: transparent;
  padding-top: 15px;
  // display: none;
}
.res-disp {
  td {
    border: none;
  }
  tr {
    border: none;
  }
}
.family {
  top: 552px;
  left: 38px;
  font-size: 22px;
  >tr>td{
    border: none;
    // border-bottom: 1px solid #000;
    background: transparent;
  }
}


</style>
