import Vue from "vue";

// import BootstrapVue from "bootstrap-vue";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Vue.use(BootstrapVue);
