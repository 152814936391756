

<template>
  <div id="wrapper">
    <Header
      @login="openLoginModal"
      @logout="logout"
      :logoutButton.sync="logoutButton"
      :links="centerLinks"
    />

    <b-row>
      <b-col class="justify-content-center">
        <b-alert v-model="loggedAlert" variant="success" dismissible fade>
          Ha iniciado sesión exitosamente!
        </b-alert>
        <b-alert v-model="logoutAlert" variant="success" dismissible fade>
          Ha cerrado sesión.
        </b-alert>
      </b-col>
    </b-row>

    <DataInputs
      @send-data="setData"
      @get-services="getServices"
      @close-components="closeComponents()"
      @toogle-loading="toogleLoading"
      :links="centerLinks"
    />
    <ModalLogin 
      :my-modal.sync="modal" 
      @login-success="loginSuccess" 
      @data-client="dataClient"
    />

    <section v-if="showServ" class="listado-tarjetas">
      <b-col class="">
        <br /><br />
        <b-col class="d-flex justify-content-center">
          <span> <b-icon-gear-fill /></span>
        </b-col>
        <h2 class="text-center">SERVICIOS DISPONIBLES</h2>
        <br /><br />
      </b-col>
      <b-container class="bv-example-row">
        <b-row id="divservicios" style="opacity: 1;">
          <ServiceCard
            v-for="serv in services"
            :key="serv.id"
            :service="serv"
            @add-to-cart="updateCart"
          />
        </b-row>
      </b-container>
    </section>

    <TablaReserva
      :items="cart"
      @delete-item="deleteItem"
      :date="chosenDate"
      :hour="chosenHour"
      :clients="clients"
      :showTable="showServ"
      :feeValue="feeValue"
      :totalPrice="totalPrice"
      @calc-price="calcTotalPrice()"
    />
    <TablaRegistro
      :cart="cart"
      :direction="[address, country, province, cp]"
      :date="chosenDate"
      :hour="chosenHour"
      :idClient="idClient"
      :centerId="centerId"
      :feeValue="feeValue"
      @name-client="setNameClient"
      @confirm-reservation="confirmReservation"
    />
    <ModalCartDetail 
      :showCard="showModalCartDetail"
      :items="cart"
      @delete-item="deleteItem"
      :date="chosenDate"
      :hour="chosenHour"
      :clients="clients"
      :first_name="first_name"
      :last_name="last_name"
      :province="province"
      :address="address"
      :showTable="showServ"
      :htmlBtnPay="htmlBtnPay"
      :feeValue="feeValue"
      @confirm-reservation="deleteItem"
      @close-modal="closeModalCartDetail"
      :totalPrice="totalPrice"
    />
    <Footer
      :links="centerLinks"
    />

    <AlertLoading
      :showLoading="showLoading"
      :text="textLoading"
    />
    
    <a v-if="cartBtnShow" href="/carrito" class="btn-flotante">
      <b-icon-cart />
      <div class="badge-cart"></div>
    </a>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import TablaReserva from "@/components/TablaReserva.vue";
import ServiceCard from "@/components/ServiceCard.vue";
import DataInputs from "@/components/DataInputs.vue";
import ModalLogin from "@/components/ModalLogin.vue";
import ModalCartDetail from "@/components/ModalCartDetail.vue";
import TablaRegistro from "@/components/TablaRegistro.vue";
import AlertLoading from "@/components/alerts/AlertLoading.vue"

import { getCenterServices } from "@/api/EventService.js";
import { API_INFO, serv } from "@/constants.js";

import axios from "axios";
import delay from "delay";

export default {
  name: "Home",
  components: {
    Header,
    DataInputs,
    ModalLogin,
    ServiceCard,
    TablaReserva,
    TablaRegistro,
    Footer,
    ModalCartDetail,
    AlertLoading,
  },
  data() {
    return {
      show: false,
      services: "",
      cart: [],
      modal: "",
      chosenDate: "",
      chosenHour: "",
      showServ: false,
      loggedAlert: false,
      logoutAlert: false,
      logoutButton: false,
      centerId: 0,
      feeValue: 0,
      clients: 1,
      idClient: 0,
      cp: "",
      address: "",
      province: "",
      country: "España",
      accepted: false,
      showModalCartDetail: false,
      cartBtnShow: false,
      first_name: '',
      last_name: '',
      reservationData: {},
      htmlBtnPay: '',
      showLoading: false,
      textLoading: '',
      linksGranada: [
        "https://granada.hammamalandalus.com/naturaleza-interior/",
        "https://granada.hammamalandalus.com/carta-de-servicios/",
        "https://granada.hammamalandalus.com/promociones/",
        "https://granada.hammamalandalus.com/viajero-hammam/",
        "https://granadareservas.hammamalandalus.com/seleccion_tarjeta.php",
        "https://granadareservas.hammamalandalus.com/datos-reserva.php",
        "Granada"
      ],
      linksMalaga: [
        "https://malaga.hammamalandalus.com/naturaleza-interior/",
        "https://malaga.hammamalandalus.com/carta-de-servicios/",
        "https://malaga.hammamalandalus.com/promociones/",
        "https://malaga.hammamalandalus.com/viajero-hammam/",
        "https://malagareservas.hammamalandalus.com/seleccion_tarjeta.php",
        "https://malagareservas.hammamalandalus.com/datos-reserva.php",
        "Málaga"
      ],
      linksPalma: [
        "https://palma.hammamalandalus.com/naturaleza-interior/",
        "https://palma.hammamalandalus.com/carta-de-servicios/",
        "https://palma.hammamalandalus.com/promociones/",
        "https://palma.hammamalandalus.com/viajero-hammam/",
        "https://palmareservas.hammamalandalus.com/seleccion_tarjeta.php",
        "https://palmareservas.hammamalandalus.com/datos-reserva.php",
        "Palma"
      ],
      linksMadrid: [
        "https://madrid.hammamalandalus.com/naturaleza-interior/",
        "https://madrid.hammamalandalus.com/carta-de-servicios/",
        "https://madrid.hammamalandalus.com/promociones/",
        "https://madrid.hammamalandalus.com/viajero-hammam/",
        "https://madridreservas.hammamalandalus.com/seleccion_tarjeta.php",
        "https://madridreservas.hammamalandalus.com/datos-reserva.php",
        "Madrid",
        "false"
      ],
      linksCordoba: [
        "https://cordoba.hammamalandalus.com/naturaleza-interior/",
        "https://cordoba.hammamalandalus.com/carta-de-servicios/",
        "https://cordoba.hammamalandalus.com/promociones/",
        "https://cordoba.hammamalandalus.com/viajero-hammam/",
        "https://cordobareservas.hammamalandalus.com/seleccion_tarjeta.php",
        "https://cordobareservas.hammamalandalus.com/datos-reserva.php",
        "Córdoba",
        "false"
      ],
      totalPrice: 0
    };
  },
  
  created() {
    const tokenExist = localStorage.getItem("accessToken");
    if (tokenExist) {
      this.logoutButton = true;
      this.idClient = localStorage.getItem("id");
      this.first_name = localStorage.getItem("first_name");
      this.last_name = localStorage.getItem("last_name");
    }
    const reservation = localStorage.getItem("reservation");
    if(reservation){
        this.cartBtnShow = true;
    }

  },
  computed: {
    countItems() {
      return this.cart.length;
    },
    centerLinks() {
      let url = window.location.search
      let centro = url.split("&")[0];

      switch (centro.toLowerCase()) {
        case "?granada":
          return this.linksGranada
        case "?malaga":
          return this.linksMalaga
        case "?palma":
          return this.linksPalma
        case "?madrid":
          return this.linksMadrid
        case "?cordoba":
          return this.linksCordoba
        default:
          return this.linksMadrid
      }
    }
  },
  mounted: function () {
    const modaLogin = localStorage.getItem("modal");
    if(modaLogin){
      this.modal = 'login'
      localStorage.removeItem("modal");
    }
  },
  watch: {
    cart() {
      this.calcTotalPrice();
    },
  },
  methods: {
    toogleLoading(state){
      this.textLoading = 'Comprobando la disponibilidad de nuestros masajistas'
      this.showLoading = state;
    },
    openLoginModal() {
      this.modal = "login";
    },
    async getServices(data) {
      this.chosenDate= data.date
      this.chosenHour= data.time
      this.centerId = data.centerId
      this.feeValue = data.feeValue
      var body = {
        postal_code: data.cp,
        center_id: data.centerId,
        date: data.date,
        time: data.time
      };
      var req = await getCenterServices(body);
      if (req.status === 200) {
        this.showServ = true;
        this.services = req.data;
        
      } else {
        console.log(req);
      }
    },
    async loginSuccess() {
      this.loggedAlert = true;
      this.modal = "";
      this.logoutButton = true;
      this.callGeoData();
      this.takeReservationActive();
      await delay(3600);
      this.loggedAlert = false;
    },
    async dataClient(item){
      this.first_name = item.first_name
      this.last_name = item.last_name
      this.idClient = item.id
    },
    async logout() {
      const options = {
        headers: { Authorization: "JWT " + localStorage.accessToken },
      };
      try {
        const resp = await axios.post(API_INFO + "/logout/", "", options);
        if (resp.status === 200) {
          this.logoutButton = false;
          localStorage.clear();
          this.logoutAlert = true;
          await delay(4200);
          this.logoutAlert = false;
          this.cartBtnShow = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    setData(item) {
      this.address = item.address;
      this.cp = item.cp;
      this.province = item.province;
      this.clients = item.persons;
    },
    updateCart(item) {
      const itemExist = this.cart.find((serv) => item.id === serv.id);
      if (!itemExist) {
        this.cart.push(item);
        var toKeep = this.services.filter((el) => item.id !== el.id);
        this.services = toKeep;
      }
    },
    deleteItem(item) {
      var toKeep = this.cart.filter((el) => item.id !== el.id);
      this.cart = toKeep;
      this.services.push(item);
    },
    async callGeoData() {
      const options = {
        headers: { Authorization: "JWT " + localStorage.accessToken },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/clients/my-geo-data/",
          options
        );
        if (resp.status === 200) {
          localStorage.setItem("geo-data", resp.data);
        }
      } catch (e) {
        console.log(e);
      }
    },
    closeComponents() {
      this.accepted = false;
      this.showServ = false;
      this.cart = [];
    },
    setNameClient(item)
    {
        this.last_name= item.last_name
        this.first_name= item.first_name
        this.idClient = item.client_id
    },
    confirmReservation(item) {

      this.reservationData = item
      localStorage.setItem("reservation", item.id);
      localStorage.setItem("cart_code", item.cart_code);
      this.showModalCartDetail = true;

      this.takePay();
    },
    async takePay(){
        try {
          const response = await axios.get(API_INFO + "/reservations/"+this.reservationData.id+"/get_info_redsys/");
          if (response.status === 200) { 
            //console.log(response)
            this.htmlBtnPay = response.data
          }
          
        } catch (err) {
          console.error(err)
          return false;
        }
    },
    closeModalCartDetail() {
      this.showModalCartDetail = false
      this.$router.push({ path: '/pago-error' })
    },
    async takeReservationActive() {
      const options = {
        headers: { Authorization: "JWT " + localStorage.accessToken },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/reservations/"+this.idClient+"/slope/"
        );
        if (resp.status === 200) {
          if (resp.data.cart_code != '') {
            this.cartBtnShow = true;
            localStorage.setItem("reservation", resp.data.id);
            localStorage.setItem("cart_code", resp.data.cart_code);
  
          } else {
            this.cartBtnShow = false;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    calcTotalPrice() {
      this.totalPrice = 0;
      this.cart.forEach((element) => {
        var price = element.price * element.quantity;
        this.totalPrice += price;
      });
      this.totalPrice = parseInt(this.totalPrice + this.feeValue);
    },
  },
};
</script>

<style lang='scss'>



.up-modal {
  width: 100%;
  height: 100%;
  background: #0000005e;
  backdrop-filter: blur(4px);

  position: fixed;
  z-index: 1100;
  top: 0;
  align-items: flex-end;
  display: block;
}
.row-modal {
  position: relative;
  top: 20%;
  justify-content: center;
}
.col-modal {
  background: #f3f4f2;
  border: none;
  max-width: 600px;
  min-width: 300px;
  @media (max-width: 1200px) {
    max-width: 700px;
    min-width: 300px;
  }
}
.login {
  justify-content: end;
  display: flex;
  .login-btn1 {
    /*   position: absolute; */
    top: 140px;
    right: 25%;
  }
}
.alert-success {
  position: relative;
  left: 50%;
}
.badge-cart{
  position: absolute;
  top: -2px;
  right: -4px;
  padding: 2px !important;
  background-color: #d42e2e;
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
.btn-flotante {
	font-size: 1.5em; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color:white !important; /* Color del texto */
	border-radius: 100%; /* Borde del boton */
	letter-spacing: 2px; /* Espacio entre letras */
	background-color: #16993d; /* Color de fondo */
	padding: 10px 12px; /* Relleno del boton */
	position: fixed;
	bottom: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
  
}
.btn-flotante:hover {
	background-color: #fff; /* Color de fondo al pasar el cursor */
  color: #16993d !important;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
@media only screen and (max-width: 600px) {
 	.btn-flotante {
		font-size: 1.5em;
		padding: 10px 12px;
		bottom: 20px;
		right: 20px;
	}
} 
</style>
