<template>
  <div v-show="myModal !== ''" class="up-modal">
    <b-row class="row-modal">
      <b-col
        cols="6"
        class="col-modal text-center pt-4 pb-2"
        style="border-radius: 20px 20px 0px 0px;"
      >
        <h1 v-if="myModal === 'login'">Iniciar sesión</h1>
        <h1 v-else-if="myModal === 'register'">Registro</h1>
        <h1 v-else>Recuperar contraseña</h1>
      </b-col>
    </b-row>
    <b-row class="row-modal">
      <b-col
        v-if="myModal === 'login' || myModal === 'register'"
        cols="6"
        class="col-modal py-4"
      >
        <div className="modal-body  ">

          <div class="row">
            <div class="col-xs-12 col-md-offset-3">
              <div class="input-group" >
                  <span class="input-group-addon" id="basic-addon1"><b-icon-envelope /></span>
                  <input
                    class="input-login-register"
                    type="email"
                    v-model="email"
                    placeholder="correo eléctronico"
                  />

              </div><!-- /input-group -->

              <div class="input-group" id="show_hide_password">
                  <span class="input-group-addon" id="basic-addon1"><b-icon-key /></span>
                  <input
                    class="input-login-register"
                    id="myInput"
                    type="password"
                    v-model="password"
                    placeholder="password"
                  />
                <span class="input-group-btn">
                  <button class="btn btn-default" type="button" @click="showPassword"><b-icon-eye /></button>
                </span>
              </div><!-- /input-group -->

            </div>
          </div>
          
  

          <b-alert v-model="error" variant="danger" dismissible fade>
            Correo o contraseña inválidos.
          </b-alert>
        </div>
      </b-col>
      <b-col v-else cols="6" class="col-modal py-4">
        <div class="col-11 pl-0 form-group">
          <label for="exampleInputEmail1">Correo electrónico</label>
          <input type="email" class="input-login-register" v-model="email" />
          <small id="emailHelp" class="form-text text-muted"
            >Enviaremos un email para la recuperación de su cuenta.</small
          >
        </div>
        <b-alert v-model="error" variant="danger" dismissible fade>
          Email no registrado, por favor verifique.
        </b-alert>
        <b-alert v-model="success" variant="success" dismissible fade>
          Se ha enviado un email para reestablecer su contraseña.
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="row-modal">
      <b-col
        cols="6"
        class="col-modal text-right py-4"
        style="border-radius: 0px 0px 20px 20px;"
      >
        <b-row class="footer-modal">
          <b-col lg="6" md="12" sm="12" xs="12">
            <p @click="myModal = 'recovery'">Recuperar contraseña</p>
          </b-col>
          <b-col lg="6" md="12" sm="12" xs="12" class="buttons-col">
            <a @click="hideModal" class="btn-outline-dark">Cancelar</a>
            <a
              class="btn-fill-dark"
              v-on="
                isLogin
                  ? { click: () => login(email, password) }
                  : recovery
                  ? { click: () => resetPass(email) }
                  : { click: () => register() }
              "
            >
              Entrar
            </a>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import delay from "delay";
import EventService from "@/api/EventService.js";
import { API_INFO } from "@/constants.js";

export default {
  name: "ModalLogin",

  props: ["myModal"],
  data: () => ({
    email: "",
    password: "",
    error: false,
    success: false,
    user: {
      id: 0,
      email: '',
      first_name: '',
      last_name:'',
      role: ''
    }
  }),
  computed: {
    isLogin() {
      if (this.myModal === "login") {
        return true;
      }
      return false;
    },
    recovery() {
      if (this.myModal === "recovery") {
        return true;
      }
      return false;
    },
  },
  methods: {
    showPassword() {
      var x = document.getElementById("myInput");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    hideModal() {
      this.$emit("update:myModal", "");
    },
    loginSuccess() {
      this.$emit("login-success");
    },
    async login(email, password) {
      const options = {
        headers: { "Content-Type": "application/json" },
      };
      try {
        const resp = await axios.post(
          API_INFO + "/login/",
          {
            email: this.email,
            password: this.password,
          },
          options
        );
        if (resp.status === 200) {
          this.user = {
            id: resp.data.client_id,
            email: resp.data.email,
            first_name: resp.data.first_name,
            last_name: resp.data.last_name,
            role: resp.data.role
          }
          this.$emit("data-client", this.user);
          localStorage.clear();
          localStorage.setItem("accessToken", resp.data.access);
          localStorage.setItem("refreshToken", resp.data.refresh);
          localStorage.setItem("role", resp.data.role);
          localStorage.setItem("centerId", resp.data.center_id);
          localStorage.setItem("id", resp.data.client_id);
          localStorage.setItem("first_name", resp.data.first_name);
          localStorage.setItem("last_name", resp.data.last_name);
          
          this.loginSuccess();
        }
      } catch {
        this.error = true;
        await delay(4200);
        this.error = false;
      }
    },
    async resetPass(email) {
      const options = {
        headers: { "Content-Type": "application/json" },
      };
      try {
        const resp = await axios.post(
          API_INFO + "/reset-password/",
          {
            email: this.email,
          },
          options
        );
        console.log(resp, "response");
        if (resp.status === 200) {
          this.success = true;
          await delay(5000);
          this.success = false;
        }
      } catch (e) {
        if (e.response.status === 400) {
          this.error = true;
          await delay(4500);
          this.error = false;
        }
      }
    },
    register() {
      console.log("register method");
    },
  },
};
</script>

<style lang="scss" scoped>
$hammam-blue: #82c5bf;

.up-modal {
  box-shadow: 0 0 0 0.2rem rgba(63, 63, 63, 0.25);
}
.col-modal p {
  margin: 0px;
  text-align: initial;
  position: relative;
  left: 10px;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: $hammam-blue;
  }
}
a {
  @media (max-width: 991px) {
    max-width: 160px;
  }
}
.form-control {
  max-width: 30rem;
}

svg {
  cursor: pointer;
}

.alert {
  width: 92%;
  position: initial;
}
.col-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-modal {
  display: flex;
  height: auto;
  p {
    margin: 2rem 0;
  }
  .buttons-col {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 1111px) {
    justify-content: space-between;
  }
}



.input-login-register{
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    margin-bottom: 20px ;
    
}
</style>
