<template>
  <div v-show="showModal" class="up-modal">
    <b-icon-x-square @click="closeModal" />
    <b-row class="justify-content-center">
      <b-col class="justify-content-center d-flex">
        <b-row class="mt-5 location-row">
          <small>No se encontró el cliente, por favor agreguelo.</small>
          <p class="text-center card-title mt-5">Datos del Cliente</p>
          
          <form class="form-horizontal row" v-on:submit.prevent>
                      <div class="col-md-4 form-li">
                        <label for="email" class="control-label">E-mail:</label>
                        <input
                          v-model="email"
                          type="email"
                          class="form-control"
                          id="email"
                          name="email"
                          placeholder="e-mail"
                        />
                      </div>
                      <div class="col-md-12 form-li"></div>
                      <div class="col-md-4 form-li">
                        <label for="email" class="control-label">Nombre:</label>
                        <input
                          v-model="first_name"
                          type="text"
                          class="form-control"
                          id="nombre"
                          name="nombre"
                          placeholder="nombre"
                        />
                      </div>
                      <div class="col-md-4 form-li">
                        <label for="email" class="control-label"
                          >Apellidos:</label
                        >
                        <input
                          v-model="last_name"
                          type="text"
                          class="form-control"
                          id="apellidos"
                          name="apellidos"
                          placeholder="apellidos"
                        />
                      </div>
                      <div class="col-md-12 form-li"></div>
                      <div class="col-md-10 form-li">
                        <label class="control-label form-label"
                          >Dirección:</label
                        >
                        <input
                          type="text"
                          class="form-control float-left long"
                          v-model="direction_r"
                          placeholder="dirección"
                        />
                      </div>
                      <div class="col-md-12 form-li"></div>
                      <div class="col-md-4 form-li">
                        <label for="pais" class="control-label">Pais:</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="country_r"
                          placeholder="país"
                        />
                      </div>
                      <div
                        class="col-md-4 form-li"
                        id="divprovincia"
                      
                      >
                        <label for="email" class="control-label"
                          >Provincia*:</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="city_r"
                          placeholder="provincia"
                        />
                      </div>
                      <div class="col-md-4 form-li" id="divcp">
                        <label for="email" class="control-label">CP*</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="cp_r"
                          placeholder="CP"
                        />
                      </div>
                      <div class="col-md-12 form-li"></div>
                      <div class="col-md-4 form-li">
                        <label for="email" class="control-label"
                          >Tlfno Contacto:</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="tlfno"
                          name="tlfno"
                          v-model="phone"
                          placeholder="teléfono"
                        />
                      </div>
                      <div class="col-md-4 form-li">
                        <label for="email" class="control-label">NIF:</label>
                        <input v-model="nif_nie" type="text" class="form-control" placeholder="nif" />
                      </div>
                      <div class="col-md-12 form-li"></div>
                      <div class="col-md-10 form-li">
                        <label class="control-label form-label">
                          Alergias o consideraciones relevantes:
                        </label>
                        <input
                          type="text"
                          class="form-control float-left long"
                          style="margin: 20px 0px 0px;"
                          v-model="health_considerations"
                          placeholder="Alergias o consideraciones relevantes"
                        />
                        <div class="col-md-12 form-li"></div>
                      </div>

                    </form>
          <b-row class="div-full">
            <b-col cols="12" class="div-full" v-if="showAlertSucces2">
              <div class="alert alert-success margin_top" role="alert" >
                {{msgAlert}}
              </div>
            </b-col>
          </b-row>
          <b-row class="div-full">
            <b-col cols="12" class="div-full" v-if="showAlert2">
              <div class="alert alert-warning margin_top" role="alert" >
                {{msgAlert}}
              </div>
            </b-col>
          </b-row>
          <b-row class="div-full">
            <b-col cols="12" class="div-full">
                <button @click="saveData" type="button" class="btn btn-success margin_top pull-right">
                  <b-spinner small v-if="loading" label="Spinning"></b-spinner> Guardar
                </button>
            </b-col>
          </b-row>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import { API_INFO } from "@/constants.js";


import axios from "axios";
import delay from 'delay';

export default {

  props: {
    showModal: Boolean,
  },
  components: {
 
  },
  data() {
    return {

      email: '',
      nif_nie: '',
      phone: '',
      direction_r: '',
      country_r: 'España',
      city_r:'',
      cp_r:'',
      health_considerations: '',
      last_name:'',
      first_name: '',
      idClient: 0,

      showAlert2: false,
      showAlertSucces2: false,
      showModalDetail:false,
      loading:false,
      msgAlert: '',
    };
  },
  watch: {

  },

  methods: {
    closeModal() {
      this.loading = false;
      this.showAlert2=  false;
      this.showAlertSucces2=  false;
      this.$emit("close-modal")
    },

    async saveData(){
      this.showAlert2=  false;
      this.showAlertSucces2=  false;
      this.loading = true;

      try {

            const password = 'd3f@u1t';
            const password2 = 'd3f@u1t' ;
            
            const resp = await axios.post(
              API_INFO + "/clients/register/",
              {
                email: this.email,
                nif_nie: this.nif_nie,
                phone: this.phone,
                last_name: this.last_name,
                first_name: this.first_name,
                address: this.direction_r,
                postal_code: this.cp_r,
                province: null,
                country_id: 1,
                health_considerations: this.health_considerations,
                password: password,
                password2: password2,
                prospect_id: 1,
                terms_accepted: true,
                news_accepted: true,
              }
            );
            this.loading = false;
            if (resp.status === 201) {
              console.log(resp.data)

              this.$emit("close-modal", {'idClient': resp.data.id, 'first_name': this.first_name, 'last_name': this.last_name})
              //this.reservationRegister();
            } else {
              this.message = resp.data
              this.showAlert = true;
              this.varian = 'warning';
            }
          } catch {
            this.showAlert2=  true;
            this.msgAlert= 'Por favor revise los datos ingresados.';
            this.loading = false;
          }
    }
  },
};
</script>

<style lang="scss" scoped>
.alert-success{
  left: 0px !important;
}
.div-full{
  width: 100%  !important;
}
.margin_top{
  margin-top: 20px;
}
.location-row {
  border-radius: 20px !important;
  border: 4px solid #316d9e;
  background:  #eeeeee;
  padding: 15px 15px 35px 15px;
  max-width: 630px;
  position: relative;

  td {
    width: 42%;
    position: relative;
    right: 5%;
    &:last-of-type {
      tr {
        border: 1px solid #000;
        background: #d9edf7;
        &:last-of-type {
          height: 75px;
          div select {
            min-height: 65px;
          }
        }
      }
    }
  }
  tr {
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 5px;
    text-align: center;
    display: block;
    margin-top: 4px;
    height: 37px;
  }
}
button {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
}


.service-row {
  height: fit-content;
  justify-content: flex-end;
  // background: #F1F1F1;
}
.long-col {
  flex: 0 0 48.333333%;
  max-width: 48.333333%;
  padding: 12px 0px 12px 12px;
  h3 {
    text-align: center;
    font-weight: 900;
    margin: 5px;
    position: relative;
    right: 10px;
  }
  p {
    margin: 8px 0px 10px;
  }
}
.bi-x-square {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 10px;
  right: 25px;
  cursor: pointer;
  background: antiquewhite;
  z-index: 130;
  &:hover {
    background: rgba(255, 117, 98, 0.822);
  }
}
p{
  margin-bottom: 5px !important;
}
.div-scroll{
  width: 100% !important;
  height: 350px !important;
  overflow-y: auto !important;
}
</style>
