import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import Dashboard_EM from "../views/Dashboard_EM.vue";
import Dashboard_GS from "../views/Dashboard_GS.vue";
import Dashboard_CM from "../views/Dashboard_CM.vue";
import Dashboard_AL from "../views/Dashboard_AL.vue";
import PagoError from "../views/PagoError.vue";
import PagoSuccess from "../views/PagoSuccess.vue";
import CartDetail from "../views/CartDetail.vue";
import Dashboard_LO from "../views/Dashboard_LO.vue";
import Dashboard_DIR from "../views/Dashboard_DIR.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/pago-error",
    name: "Pago Error",
    component: PagoError
  },
  {
    path: "/pago-ok",
    name: "Pago Success",
    component: PagoSuccess
  },
  {
    path: "/carrito",
    name: "Carrito",
    component: CartDetail
  },
  {
    path: "/carrito/:id",
    name: "Carrito",
    component: CartDetail
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (localStorage.accessToken == '' || localStorage.accessToken == null) 
        next({ name: 'Dashboard_LO' })
      else if (localStorage.role == 'client')
        next({ name: 'Home' })
      else if (localStorage.role == 'employee')
        next({ name: 'Dashboard_CM' })
      else next()
    }
  },
  {
    path: "/dashboard_al",
    name: "Dashboard_AL",
    component: Dashboard_AL,
    beforeEnter: (to, from, next) => {
      if (localStorage.accessToken == '' || localStorage.accessToken == null) 
        next({ name: 'Dashboard_LO' })
      else if (localStorage.role == 'client')
        next({ name: 'Home' })
      else if (localStorage.role == 'employee')
        next({ name: 'Dashboard_CM' })
      else next()
    }
  },
  {
    path: "/dashboard_em",
    name: "Dashboard_EM",
    component: Dashboard_EM,
    beforeEnter: (to, from, next) => {
      if (localStorage.accessToken == '' || localStorage.accessToken == null) 
        next({ name: 'Dashboard_LO' })
      else if (localStorage.role == 'client')
        next({ name: 'Home' })
      else if (localStorage.role == 'employee')
        next({ name: 'Dashboard_CM' })
      else next()
    }
  },
  {
    path: "/dashboard_gs",
    name: "Dashboard_GS",
    component: Dashboard_GS,
    beforeEnter: (to, from, next) => {
      if (localStorage.accessToken == '' || localStorage.accessToken == null) 
        next({ name: 'Dashboard_LO' })
      else if (localStorage.role == 'client')
        next({ name: 'Home' })
      else if (localStorage.role == 'employee')
        next({ name: 'Dashboard_CM' })
      else next()
    }
  },
  {
    path: "/dashboard_cm",
    name: "Dashboard_CM",
    component: Dashboard_CM,
    beforeEnter: (to, from, next) => {
      if (localStorage.accessToken == '' || localStorage.accessToken == null) 
        next({ name: 'Dashboard_LO' })
      else if (localStorage.role == 'client')
        next({ name: 'Home' })
      else next()
    }
  },
  {
    path: "/dashboard_dir",
    name: "Dashboard_DIR",
    component: Dashboard_DIR,
    beforeEnter: (to, from, next) => {
      if (localStorage.accessToken == '' || localStorage.accessToken == null) 
        next({ name: 'Dashboard_LO' })
      else if (localStorage.role == 'client')
        next({ name: 'Home' })
      else next()
    }
  },
  {
    path: "/dashboard/login",
    name: "Dashboard_LO",
    component: Dashboard_LO,
    beforeEnter: (to, from, next) => {
      if (localStorage.accessToken == '' || localStorage.accessToken == null) 
        next()
      else if (localStorage.role != 'client')
        next({ name: 'Dashboard_AL' })
      else next({ name: 'Home' })
    }
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
