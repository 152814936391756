<template>
  <div>
    <!-- <section id="Calltoaction" class="calltoaction-direccion">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 text-center">
            <i class="fa fa-map-marker" aria-hidden="true"></i
            ><span>C/ Atocha, 14. Madrid</span><span class="hidden-xs"> | </span
            ><span>91 429 90 20</span>
          </div>
        </div>
      </div>
    </section> -->

    <!-- FOOTER -->
    <footer>
      <div class="container">
        <div class="row footer-up">
          <div class="footer-logo">
            <a href="index.html"
              ><img src="../assets/img/logo.png" alt="Hammam Logo"
            /></a>
          </div>
          <div class="footer-donde">
            <ul class="list-unstyled">
              <li><h3>DÓNDE ESTAMOS</h3></li>
              <li>
                <a href="https://granada.hammamalandalus.com/"
                  >Hammam Granada</a
                >
              </li>
              <li>
                <a href="https://cordoba.hammamalandalus.com/"
                  >Hammam Córdoba</a
                >
              </li>
              <li>
                <a href="https://madrid.hammamalandalus.com/">Hammam Madrid</a>
              </li>
              <li>
                <a href="https://malaga.hammamalandalus.com/">Hammam Málaga</a>
              </li>
              <li>
                <a href="https://palma.hammamalandalus.com/">Hammam Palma</a>
              </li>              
            </ul>
          </div>
          <div class="footer-legales">
            <ul class="list-unstyled">
              <li>
                <a href="https://madrid.hammamalandalus.com/faqs/">Faqs</a>
              </li>
              <li>
                <a href="https://madrid.hammamalandalus.com/normas-de-uso/"
                  >Normas de uso</a
                >
              </li>
              <li>
                <a href="https://madrid.hammamalandalus.com/aviso-legal/"
                  >Aviso legal</a
                >
              </li>
            </ul>
          </div>
          <div class="footer-menu">
            <ul class="list-unstyled">
              <li>
                <a href="datos-reserva-con-tarjetab093.html?id=es"
                  >Reserva con tarjeta regalo</a
                >
              </li>
              <li>
                <a
                  href="https://madrid.hammamalandalus.com/proteccion-de-datos/"
                  >Protección de datos</a
                >
              </li>
              <li>
                <a
                  href="https://madrid.hammamalandalus.com/politica-de-cookies/"
                  >Politica de cookies</a
                >
              </li>
              <li>
                <a href="http://zonaprofesional.hammamalandalus.com/madrid/"
                  >Zona profesional</a
                >
              </li>
            </ul>
          </div>
          <div class="footer-redes">
            <ul class="list-unstyled">
              <li>
                <a
                  href="http://www.hammamalandalus.com/blog/"
                  class="redes-blog"
                  target="_blank"
                  >Blog</a
                >
              </li>
              <li>
                <a
                  href="http://www.facebook.com/HammamAlAndalus"
                  class="redes-facebook"
                  target="_blank"
                  >Facebook</a
                >
              </li>
              <li>
                <a
                  href="http://twitter.com/HammamAlAndalus"
                  class="redes-twitter"
                  target="_blank"
                  >Twitter</a
                >
              </li>
              <li>
                <a
                  href="https://www.youtube.com/HammamAlAndalusVideo"
                  class="redes-youtube"
                  target="_blank"
                  >Youtube</a
                >
              </li>
              <li>
                <a
                  href="https://www.instagram.com/hammamalandalus/"
                  class="redes-instagram"
                  target="_blank"
                  >Instagram</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row footer-down">
          <div class="footer-contacto">
            <span class="nombre">HAMMAM AL ÁNDALUS - {{links[6]}}</span> 
          </div>
          <div class="footer-sellos">
            <ul class="list-inline">
              <li>
                <a
                  href="https://www.tripadvisor.es/Attraction_Review-g187514-d2592599-Reviews-Hammam_Al_Andalus_Madrid-Madrid.html"
                  target="new"
                >
                  <img src="../assets/img/tripadvisor-logo.png"
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    links: Array
  },
  data: () => ({
    // ecosystem: [
    //   {
    //     text: "vuetify-loader",
    //     href: "https://github.com/vuetifyjs/vuetify-loader"
            // | C/ Atocha, 14.
            //         Madrid | RESERVAS 91 429 90 20
    //   }
    // ],
  }),
};
</script>

<style lang="scss" scoped>
a,h3,span {
  font-family: "Chap", Helvetica, Arial, sans-serif;
}
</style>
