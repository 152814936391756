<template>
  <div class="full-container">
    <NavBar />
  
  <b-container fluid class="h-100 gray">

    <b-row class="title">
      <b-col 
        col
        xs="12"
        class="d-flex justify-content-center"
      >
        <h1 class="m-auto">Recepción</h1>
      </b-col>

    </b-row>
    <br >
    <b-row>
      <b-col cols="12"  class="justify-content-center">
          <h4>Búsqueda del cliente :</h4>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col col xs="8" sm="6">
        <b-input-group class="mt-3">
            <b-form-input placeholder="búsqueda" v-model="nif_search" @keypress="keyPressNif($event)"></b-form-input>
            <b-input-group-append>
              <b-button  @click="searchNif()" variant="info"><b-icon-search /></b-button>
            </b-input-group-append>
          </b-input-group>
      </b-col>

    </b-row>
    <br>
    <b-row v-if="showDataImputs">
      <b-col cols="12"  class="justify-content-center">
          <h4>Cliente Seleccionado:  {{first_name}} {{last_name}}</h4>
      </b-col>
      <b-col cols="12"  class="justify-content-center bg-white">
          <DataInputs
            @send-data="setData"
            @get-services="getServices"
            @close-components="closeComponents()"
            @toogle-loading="toogleLoading"
            :links="linksGranada"
          />
        </b-col>
    </b-row>

    <section v-if="showServ" class="listado-tarjetas">
      <b-col class="">
        <br /><br />
        <b-col class="d-flex justify-content-center">
          <span> <b-icon-gear-fill /></span>
        </b-col>
        <h2 class="text-center">SERVICIOS DISPONIBLES</h2>
        <br /><br />
      </b-col>
      <b-container class="bv-example-row">
        <b-row id="divservicios" style="opacity: 1;">
          <ServiceCard
            v-for="serv in services"
            :key="serv.id"
            :service="serv"
            @add-to-cart="updateCart"
          />
        </b-row>
      </b-container>
    </section>

    <TablaReserva
      :items="cart"
      @delete-item="deleteItem"
      :date="chosenDate"
      :hour="chosenHour"
      :clients="clients"
      :showTable="showServ"
      :feeValue="feeValue"
      :totalPrice.sync="totalPrice"
      @update-price="updatePrice"
      @calc-price="calcTotalPrice()"
    />

    <b-row>
      <b-col cols="12"  class="justify-content-center bg-white">
        <TablaRegistroWithoutPayment
          :cart="cart"
          :direction="[address, country, province, cp]"
          :date="chosenDate"
          :hour="chosenHour"
          :idClient="idClient"
          :centerId="centerId"
          :feeValue="feeValue"
          @confirm-reservation="confirmReservation"
          :totalPrice.sync="totalPrice"
        />
      </b-col>
    </b-row>

    <b-row v-if="showSms">
      <b-col cols="12"  class="justify-content-center bg-white text-center">
        <button @click="envioSms" type="button" class="btn btn-info">Enviar sms con link de pago</button>
      </b-col>
    </b-row>

    <ModalAlert
      :showCard="showAlert"
      :title="alertMessage"
      :varian="varianAlert"
      @close-modal="() => showAlert = false"
    />
    
    <AlertLoading
      :showLoading="showLoading"
    />

    <ModalRegisterClient
      :showModal="showModalRegister"
      @close-modal="() => showModalRegister = false"
      @client="setDataClient"
    />
  </b-container>
  </div>
</template>

<script>
import ModalAlert from "@/components/alerts/ModalAlert.vue"
import AlertLoading from "@/components/alerts/AlertLoading.vue"
import ModalRegisterClient from "@/components/dashboard/ModalRegisterClient.vue"
import NavBar from "@/components/NavBar.vue"
import { getCenterServices } from "@/api/EventService.js";
import DataInputs from "@/components/DataInputs.vue";
import TablaReserva from "@/components/TablaReserva.vue";
import ServiceCard from "@/components/ServiceCard.vue";
import TablaRegistroWithoutPayment from "@/components/TablaRegistroWithoutPayment.vue";

import { API_INFO } from "@/constants.js";
import axios from "axios";
import delay from 'delay';


export default {
  components: {
    ModalAlert,
    AlertLoading,
    NavBar,
    ModalRegisterClient,
    DataInputs,
    TablaReserva,
    ServiceCard,
    TablaRegistroWithoutPayment
  },
  data() {
    return {
      alertMessage: "",
      showAlert: false,
      showModalDetail:false,
      showLoading: false,
      showModalRegister: false,
      showDataImputs:false,
      varianAlert: '',
      nif_search: '',

      show: false,
      services: "",
      cart: [],
      modal: "",
      chosenDate: "",
      chosenHour: "",
      showServ: false,
      loggedAlert: false,
      logoutAlert: false,
      logoutButton: false,
      centerId: 0,
      feeValue: 0,
      clients: 1,
      idClient: 0,
      cp: "",
      address: "",
      province: "",
      country: "España",
      accepted: false,
      showModalCartDetail: false,
      cartBtnShow: false,
      first_name: '',
      last_name: '',
      reservationData: {},
      showSms: false,
      linksGranada: [
        "https://granada.hammamalandalus.com/naturaleza-interior/",
        "https://granada.hammamalandalus.com/carta-de-servicios/",
        "https://granada.hammamalandalus.com/promociones/",
        "https://granada.hammamalandalus.com/viajero-hammam/",
        "https://granadareservas.hammamalandalus.com/seleccion_tarjeta.php",
        "https://granadareservas.hammamalandalus.com/datos-reserva.php",
        "Granada"
      ],
      totalPrice: 0
    }
  },
  watch: {
    cart() {
      this.calcTotalPrice();
    },
  },
  methods: {
    alertModal(val){
      if (val == 1){
        this.showAlert = true;
        this.alertMessage = 'por favor compruebe su conexión a internet.';
        this.varianAlert = 'warning';
      }
      if (val == 2){
        this.showAlert = true;
        this.alertMessage = 'Datos guardados con éxito.';
        this.varianAlert = 'success';
      }
      if (val == 3){
        this.showAlert = true;
        this.alertMessage = 'Datos incorrectos, por favor revice la información ingresada.';
        this.varianAlert = 'warning';
      }
      if (val == 4){
        this.showAlert = true;
        this.alertMessage = 'Reserva realizada con éxito.';
        this.varianAlert = 'success';
      }
      if (val == 5){
        this.showAlert = true;
        this.alertMessage = 'Se envió el SMS para pago correctamente.';
        this.varianAlert = 'success';
      }
      if (val == 6){
        this.showAlert = true;
        this.alertMessage = 'Problemas con el envió por favor intentelo nuevamente';
        this.varianAlert = 'warning';
      }
    },
    keyPressNif(event){
      if (event.keyCode === 13) {
        if(this.nif_search != '') {
          this.searchNif()
        }
      }
    },
    async searchNif() {
      this.showSms = false;
      this.showDataImputs= false;
      this.showLoading = true;
      try {
        const resp = await axios.get(API_INFO + "/clients/search/?search="+this.nif_search);
        if (resp.status === 200) {
          this.showLoading = false;
          if (resp.data.first_name != '') {
            this.idClient = resp.data.id;
            this.first_name = resp.data.first_name;
            this.last_name = resp.data.last_name;
            this.showDataImputs = true;
          } else {
            this.showModalRegister = true;
          }  
        }
      } catch (e) {
        console.log(e);
        this.showLoading = false;
      }
    },
    setDataClient(item) {
      this.first_name = item.first_name;
      this.last_name = item.last_name;
      this.idClient = item.idClient;
      this.showModalRegister = false;
    },
    setData(item) {
      this.address = item.address;
      this.cp = item.cp;
      this.province = item.province;
      this.clients = item.persons;
    },
    async getServices(data) {
      this.chosenDate= data.date
      this.chosenHour= data.time
      this.centerId = data.centerId
      this.feeValue = data.feeValue
      var body = {
        postal_code: data.cp,
        center_id: data.centerId,
        date: data.date,
        time: data.time
      };
      var req = await getCenterServices(body);
      console.log(req);
      if (req.status === 200) {
        this.showServ = true;
        this.services = req.data;
        
      } else {
        console.log(req);
      }
    },
    closeComponents() {
      this.accepted = false;
      this.showServ = false;
      this.cart = [];
    },
    toogleLoading(state){
      this.showLoading = state;
    },
    updateCart(item) {
      const itemExist = this.cart.find((serv) => item.id === serv.id);
      if (!itemExist) {
        this.cart.push(item);
        var toKeep = this.services.filter((el) => item.id !== el.id);
        this.services = toKeep;
      }
    },
    deleteItem(item) {
      var toKeep = this.cart.filter((el) => item.id !== el.id);
      this.cart = toKeep;
      this.services.push(item);
    },
    confirmReservation(item) {
      this.reservationData = item
      this.showSms = true;
      this.alertModal(4)
    },
    async envioSms(){      
      this.showLoading = true;
      try {
        const resp = await axios.post(API_INFO + "/sms/"+this.reservationData.id+"/payment-by-sms/");
        if (resp.status === 200) {
          this.showLoading = false;
          this.showSms = false;
          this.alertModal(5)
        }
      } catch (e) {
        //console.log(e);
        this.showLoading = false;
        this.alertModal(6)
      }
    },

calcTotalPrice() {
      this.totalPrice = 0;
      this.cart.forEach((element) => {
        var price = element.price * element.quantity;
        this.totalPrice += price;
      });
      this.totalPrice = parseInt(this.totalPrice + this.feeValue);
    },
    updatePrice(val) {
      this.totalPrice = parseInt(val) 
    }
  }
}
</script>

<style lang='scss' scoped>
.full-container{
  width: 100%;
  position: relative;
}
.bg-hammam{
  background-color: #e18435;
}
.margin_top{
  margin-top: 10px !important;
}
.search {
  td:nth-of-type(2n+1) {
    width: 7vw;
  }
  td:nth-of-type(2n+2) {
    width: 12vw;
  }
  select {
    position: relative;
    top: 0px;
  }
}
.ul-search{
  list-style: none;
}

.ul-search li{
  float: left;
}
p {
  font-weight: 500;
}
.gray {
  background-color: #9ea09266 !important;
  min-height: 645px;
}
.title {
  height: 140px;
  background: #b5b8a78f;
  h1 {
    font-size: 2.5em;
  }
}
.title2 {
  padding: 10px;
  background: #b5b8a78f;
  font-size: 2rem;
  display: flex;
  justify-content: space-evenly;
}
.margin-top-20{
  margin-top: 20px;
}
footer{
  padding: 0px !important;
}
.margin-bottom-60{
  margin-bottom: 60px !important;
}
@media (max-width: 600px) {
  .ul-search li{
    float: none;
  }
}
</style>
