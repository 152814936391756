<template>
  <div v-show="addService === true" class="up-modal">
    <b-row class="justify-content-center">
      <b-col class="justify-content-center d-flex">
        <b-row class="mt-5 card-row">
          <p class="text-center card-title mt-5"> ALTA SERVICIOS A DOMICILIO</p>
          <b-col col xs="12" md="7" class="justify-content-center d-flex mb-2 p-0 data-inputs">
            <b-row class="w-100">
              <td class="">
                <tr>Nombre:</tr>
                <tr>Identificador:</tr>
                <tr>Descripción:</tr>
                <tr>Grupo:</tr>
                <tr>Duración (min):</tr>
                <tr>Imagen:</tr>
                <tr>Iva:</tr>
                <tr>Habilitar:</tr>
              </td>
              <td class="">
                <tr><input v-model="name" type="text" /></tr>
                <tr><input v-model="identifier" placeholder="terminado en '_DOM'" type="text" /></tr>
                <tr><input v-model="description" type="text" /></tr>
                <tr class="pt-0">
                  <div v-if="groups !== ''">
                    <b-form-select v-model="selectedGroup" size="lg">
                      <b-form-select-option value="null">Seleccionar grupo</b-form-select-option>
                      <b-form-select-option 
                        v-for="i in groups"
                        :key="i.id"
                        :value="i.id"
                      >
                        {{i.name}}
                      </b-form-select-option>
                    </b-form-select>
                  </div>
                </tr>
                <tr><input v-model="duration" type="text" /></tr>
                <tr><input v-model="image" type="text" /></tr>
                <tr><input v-model="iva" type="text" /></tr>
                <tr class="checkbox"><b-form-checkbox v-model="published" switch size="lg" /></tr>
              </td>
              <b-row class="w-100 justify-content-center mt-5">
                <b-col cols="10" class="checks">
                  <p>Precio por centro:</p>
                  <td class="">
                    <tr v-for="(i, j) in centers" :key="j">
                      {{i.name}}
                    </tr>
                  </td>
                  <td class="">
                    <tr v-for="(i, j) in this.centerPrices" :key="j">
                      <input v-model="i.price" type="text"/>
                    </tr>
                  </td>
                </b-col>
              </b-row>
              <b-row class="w-100 justify-content-center mt-5">
                <b-col cols="10" class="checks">
                  <p>Equipamiento:</p>
                  <div v-for="i in equipments" :key="i.id" class="form-check m-2 mx-4">
                    <input 
                      class="form-check-input"
                      type="checkbox"
                      :value="i.id" 
                      v-model="selectedEq"
                    >
                    <label class="form-check-label">
                      {{i.name}}
                    </label>
                  </div>
                  <p style="" class="my-4">Equipamiento extra:</p>
                  <td>
                    <tr><input v-model="extraEquipment" type="text" /></tr>
                  </td>
                </b-col>
              </b-row>
            </b-row>
          </b-col>
          <b-col col xs="12" md="5" class="modal-table">
            
            <p class="table-title">Ubicaciones habilitadas</p>
            <td>
              <th class="">Provincia</th>
              <tr v-for="(i, iter) in locations" :key="iter" class="">
                {{i.province}}
              </tr>
            </td>
            <td>
              <th class="">Localidad</th>
              <tr v-for="(i, iter) in locations" :key="iter" class="">
                {{i.locality}}
              </tr>
            </td>
            <td>
              <th class="">CP</th>
              <tr v-for="(i, iter) in locations" :key="iter" class="">
                {{i.postal_code}}
              </tr>
            </td>
            <td>
              <th class="">Habilitar</th>
              <tr v-for="(i, iter) in locations" :key="iter" class="">
                <b-form-checkbox 
                  switch
                  size="lg"
                  v-model="selectedLocations"
                  :value="i.postal_code"
                />
              </tr>
            </td>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-center">
            <b-button 
              v-if="service !== ''"
              @click="delItem(service.id)"
              class="mt-5 mx-4"
              variant="danger"
            >
              Eliminar
            </b-button> 
            <b-button 
              @click="closeModal" 
              class="mt-5 mx-4"
              variant="outline-primary"
            >
              Cancelar
            </b-button> 
            <b-button @click="createOrEditService" class="mt-5 mx-4" variant="success">
              <b-icon-check2-square class="mr-3" />
              Guardar Cambios
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <AlertError 
      :message="errorMessage"
      :showAlert="showAlertError"
    />
  </div>
</template>
<script>
import { API_INFO } from "@/constants.js";
import AlertError from "@/components/alerts/AlertError.vue"

import axios from "axios";
import delay from "delay";

export default {
  
  props: {
    addService: Boolean,
    service: Object,
    locations: Array,
    centers: Array
  },
  components: {
    AlertError
  },
  data() {
    return {
      id: "",
      name: "",
      description: "",
      identifier: "",
      groups: "",
      selectedGroup: null,
      duration: "",
      image: "",
      price: "",
      iva: "",
      equipments: "",
      selectedEq: [],
      selectedLocations: [],
      arrEquipment: [],
      published: false,
      
      initName: "",
      initIdentifier: "",
      initDescription: "",
      initDuration: "",
      initImage: "",
      initPrice: "",
      initIva: "",
      initSelectedGroup: null,
      initSelectedLocations: [],
      initSelectedEq: [],
      initPublished: false,

      patchBody: {},
      reqMethod: "",
      successMessage: "",
      errorMessage: "",
      showAlertError: false,
      extraEquipment: "",
      centerPrices: []
    };
  },
  mounted() {
    this.getEquipment()
    this.getGroups()
  },
  watch: {
    addService() {
      this.checkServiceExist()
      this.createCenterPrices()
    } 
  },
  computed: {
    isEnabled() {
      for( var i in this.service){
        return true
      }
      return true
    },
  },
  methods: {
    closeModal() {
      this.name = ""
      this.description = ""
      this.identifier = ""
      this.selectedGroup = null
      this.duration = ""
      this.image = ""
      this.price = ""
      this.iva = ""
      this.selectedEq = []
      this.selectedLocations = []
      this.published = false
      this.obj = []
      this.$emit("close-modal-service", {
        message: this.successMessage
      })
      this.successMessage = ""
    },
    checkServiceExist() {
      if(Object.keys(this.service).length > 0) {
        this.id = this.service.id
        this.name = this.service.name
        this.identifier = this.service.identifier
        this.description = this.service.description
        this.duration = this.service.duration
        this.image = this.service.image
        this.price = this.service.price
        this.iva = this.service.iva
        this.selectedGroup = this.service.group_id
        this.selectedLocations = this.service.enable_postal_codes
        this.published = this.service.published

        this.initName = this.service.name
        this.initIdentifier = this.service.identifier
        this.initDescription = this.service.description
        this.initDuration = this.service.duration
        this.initImage = this.service.image
        this.initPrice = this.service.price
        this.initIva = this.service.iva
        this.initSelectedGroup = this.service.group_id
        this.initSelectedLocations = this.service.enable_postal_codes
        this.initPublished = this.service.published

        this.service.equipment.forEach(el => this.selectedEq.push(el.id))
        this.service.equipment.forEach(el => this.initSelectedEq.push(el.id))
        this.reqMethod = "PATCH"
      } else {
        this.reqMethod = "POST"
      }
    },
    checkModifiedField() {
      if(this.initName != this.name) {
        this.patchBody.name = this.name
      }
      if(this.initIdentifier != this.identifier) {
        this.patchBody.identifier = this.identifier
      }
      if(this.initDescription != this.description) {
        this.patchBody.description = this.description
      }
      if(this.initDuration != this.duration) {
        this.patchBody.duration = this.duration
      }
      if(this.initPrice) {
        this.patchBody.price = this.centerPrices
      }
      if(this.initIva != this.iva) {
        this.patchBody.iva = this.iva
      }
      if(this.initSelectedGroup != this.selectedGroup) {
        this.patchBody.group_id = this.selectedGroup
      }
      if(this.initSelectedLocations) {
        this.patchBody.enable_postal_codes = this.selectedLocations
      }
      if (this.initPublished != this.published) {
        this.patchBody.published = this.published
      }
      this.patchBody.required_equipment = this.arrEquipment
    },
    addEquipmentToArray() {
      this.arrEquipment = []
      this.selectedEq.forEach(el => {
        this.arrEquipment.push({id: el})
      })
    },
    delItem(id) {
      var conf = confirm("¿Confirma que desea eliminar este ítem?")
      if(conf) {
        this.deleteService(id)
      }
    },
    async createOrEditService() {
      this.addEquipmentToArray()
      let resp
      const options = {
        headers: { Authorization: "JWT " + localStorage.accessToken },
      }
      const body = {
        identifier: this.identifier,
        name: this.name,
        description: this.description,
        duration: this.duration,
        price: this.centerPrices,
        iva: this.iva,
        group_id: this.selectedGroup,
        required_equipment: this.arrEquipment,
        enable_postal_codes: this.selectedLocations,
        published: this.published
      }
      try {
        if(this.reqMethod === "POST") {
          resp = await axios.post(
            API_INFO + "/dashboard/services/",
            body,
            options
          );
        } else {
          this.checkModifiedField()
          resp = await axios.patch(
            API_INFO + `/dashboard/services/${this.id}/`,
            this.patchBody,
            options
          );          
        }
        if (resp.status === 201 || resp.status === 200) {
          console.log("OK!");
          this.successMessage = "¡Elemento guardado exitosamente!"
          this.closeModal()
          this.patchBody = {}
        } else if (resp.status === 500) {
          this.errorMessage = "Error al intentar guardar"
          this.showAlertError = true
          await delay(3600)
          this.showAlertError = false
        }
      } catch (e) {
        // console.log(e.response);
        let message = []
        let errorDetail = ""
        for(let i in e.response.data) {
          errorDetail = ""
          errorDetail = i + ": " + e.response.data[i][0]
          message.push(errorDetail)
        }
        this.errorMessage = message
        this.showAlertError = true
        await delay(4600)
        this.showAlertError = false
      }
    },
    async deleteService(id) {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.delete(
          API_INFO + "/dashboard/services/" + `${id}/`,
          options
        );
        if (resp.status === 204) {
          this.successMessage = "¡Elemento eliminado!"
          this.closeModal()
        }
      } catch (e) {
        console.log(e);
        this.errorMessage = "No ha sido posible procesar su petición"
        this.showAlertError = true
        await delay(3600)
        this.showAlertError = false
      }
    },
    async getEquipment() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/equipment/",
          options
        );
        if (resp.status === 200) {
          this.equipments = resp.data
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getGroups() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/services/groups/",
          options
        );
        if (resp.status === 200) {
          this.groups = resp.data
        }
      } catch (e) {
        console.log(e);
      }
    },
    async createCenterPrices() {
      await delay(400)
      if(Object.keys(this.centers).length == 0) {
        await delay(200)
        this.createCenterPrices()
      } else {
        this.centerPrices = []
        let priceExist = false
        this.centers.forEach(el => {
          if(this.service.price) {
            this.service.price.forEach(element => {
              if(el.id == element[2]) {
                this.centerPrices.push(Object({
                  center_id: el.id,
                  name: el.name,
                  price: element[1]
                }))
                priceExist = true
              }
            })
          }
          if(!priceExist) {
            this.centerPrices.push(Object({
              center_id: el.id,
              name: el.name,
              price: 0
            }))
          }
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.card-row {
  border-radius: 20px !important;
  border: 4px solid #316d9e;
  background:  #eeeeee;
  padding: 15px 15px 35px 15px;
  max-width: 1030px;
  position: relative;
  top: 4vh;
  max-height: 84vh;
  overflow: auto;
  .data-inputs {
    td {
      width: 48%;
      position: relative;
      right: 5%;
      &:last-of-type {
        tr {
          border: 1px solid #000;
          background: #d9edf7;
        }
      }
    }
    tr {
      border: 1px solid rgba(0, 0, 0, 0);
      padding: 5px;
      text-align: center;
      display: block;
      margin-top: 4px;
    }
  }
  .modal-table {
    padding-right: 50px;
    td {
      width: 1%;
    }
    tr, th {
      display: block;
      border: 1px solid #316d9e;
      padding: 4px;
      text-align: center;
      background: #f8f8f8;
    }
    th {
      background: rgb(212, 212, 212);
    }
    .table-title {
      background: rgb(75, 145, 250);
      text-align: center;
      width: 100%;
      font-weight: 900;
      border-radius: 4px 4px 0px 0px;
      margin: 0px;
    }
  }
}
button {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
}
input {
  border: none;
  background: transparent;
  height: 22px;
  text-align: center;
  &:focus {
    outline: none;
  }
}
.form-check-input {
  position: relative;
  right: 8px;
  top: 6px;
}
.checks {
  p {
    background: rgb(75, 145, 250);
    width: 35%;
    position: relative;
    left: 10px;
    text-align: center;
    font-weight: 900;
    border-radius: 5px;
    margin: 0px;
    &:last-of-type {
      width: 44%;
    }
  }
  td,tr {
    width: 70%;
    position: relative;
    left: 2%;
  }
}
.form-check {
  display: inline-block;
}
select {
  max-height: 31px;
  background:#d9edf7;
  border: none;
  font-size: 15.5px;
  text-align: center !important;
  text-align-last:center;
  color: rgb(27, 27, 27);
}
.custom-control-label::before {
  height: 2.2rem !important;
  width: 4rem !important;
  border-radius: 1rem !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(2.1rem) !important;
}
.custom-control-label::after {
  width: calc( 1.25rem + 4px ) !important;
  height: calc( 1.25rem + 4px ) !important;
}
.checkbox {
  border: none !important;
  background: transparent !important;
  div .custom-control-label::before,
  .custom-control-label::after {
    display: none !important;
  }
}

@media (max-width: 600px) {

  .mt-5{
    margin: 10px 5px !important;
    padding: 5px !important;
    font-size: 0.8em !important;
  }
}
</style>
