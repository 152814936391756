import axios from "axios";
import { API_INFO } from "@/constants.js";

export async function getCenter(body) {
  let response = await axios.post(API_INFO + "/centers/search/", body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
}

export async function getCenterServices(body) {
  let response = await axios.post(API_INFO + "/centers/services/", body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
}

export async function getAvailableHours(payload) {
  let response = await axios.post(
    API_INFO + "/centers/availability-schedule/",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
}
