<template>
  <div v-show="showModal" class="up-modal">
    <b-icon-x-square @click="closeModal" />
    <b-row class="justify-content-center">
      <b-col class="justify-content-center d-flex">
        <b-row class="mt-5 location-row">
          <p class="text-center card-title mt-5">Detalle de la reserva</p>
          <b-row class="div-scroll">
            <b-col cols="12">
              <p><strong>Datos del cliente:</strong></p>
            </b-col>
            <b-col col xs="12" md="6" >
              <p><strong>Nombre cliente:</strong> {{reservation.client_name}}</p>
            </b-col>
            <b-col col xs="12" md="6" >
              <p><strong>Teléfono:</strong> {{reservation.contact_phone}}</p>
            </b-col>
            <b-col col xs="12" md="4" >

                <label for="alergias"><strong> Alergias:</strong></label>
            </b-col>
            <b-col col xs="12" md="8" >
                {{reservation.allergies}}
                <!--<b-form-input class="form-control" placeholder="alergias" id="alergias" v-model="reservation.allergies" type="text"></b-form-input>-->


            </b-col>
            <b-col cols="12">
              <p><strong>Masajista:</strong></p>
              
              <b-form-select class="form-control" v-model="reservation.employee" :options="list_employee"></b-form-select>
            </b-col>
            <b-col cols="12">
              <p><strong>Detalle de la reserva:</strong></p>
            </b-col>

     
              <b-col xs="12" md="6">
                <div>
                  <label for="dirección"><strong> Realizada por recepción:</strong> </label>
                  <span v-if="reservation.reception">SI</span>
                  <span v-else>NO</span>
                  <!--<b-form-input class="form-control" placeholder="dirección" id="dirección" v-model="reservation.address" type="text"></b-form-input>-->
                </div>
                <div>
                  <label for="dirección"><strong> Dirección:</strong> </label> {{reservation.address}}
                  <!--<b-form-input class="form-control" placeholder="dirección" id="dirección" v-model="reservation.address" type="text"></b-form-input>-->
                </div>
                <div>
                  <label for="fecha"> Fecha: </label> {{reservation.date}}
                  <!--<b-form-input class="form-control" @change="takeMasajistas" placeholder="fecha" id="fecha" v-model="reservation.date" type="date"></b-form-input>-->
                </div>
                <div>
                  <label for="ini"><strong> Inicio de reserva:</strong> </label>{{reservation.begin_service}}
                  <!--<b-form-input class="form-control" placeholder="--:--" id="ini" v-model="reservation.begin_service" type="time"></b-form-input>-->
                </div>
                <div>
                  <label for="fin"><strong> Fin de reserva:</strong> </label>{{reservation.end_service}}
                  <!--<b-form-input class="form-control" placeholder="--:--" id="fin" v-model="reservation.end_service" type="time"></b-form-input>-->
                </div>
                <div>
                  <label for="ini2"><strong> Inicio masaje:</strong> </label>{{reservation.begin_massage}}
                  <!--<b-form-input class="form-control" placeholder="--:--" id="ini2" v-model="reservation.begin_massage" type="time"></b-form-input>-->
                </div>
                <div>
                  <label for="fin2"><strong> Fin masaje:</strong> </label>{{reservation.end_massage}}
                  <!--<b-form-input class="form-control" placeholder="--:--" id="fin2" v-model="reservation.end_massage" type="time"></b-form-input>-->
                </div>
              </b-col>
              <b-col xs="12" md="6">
                <div>
                  <label for="personas"><strong>Personas:</strong> </label>{{reservation.people}}
                  <!--<b-form-input class="form-control" placeholder="personas" id="personas" v-model="reservation.people" type="number"></b-form-input>-->
                </div>
                
                <p><strong>Proveedor de transporte:</strong> {{reservation.transport_provider}}</p>

                <p><strong>Servicios:</strong></p>
                <ul>
                  <li v-for="j in reservation.service_ids" :key="j">{{j[1]}} - {{j[3]}} €</li>
                </ul>

                
                <p><strong>Importe:</strong> {{reservation.total}} €</p>
                <p><strong>Estado:</strong></p>
                <b-form-select v-model="reservation.status" :options="options"></b-form-select>
              </b-col>
          
          </b-row>
          <b-row class="div-full">
            <b-col cols="12" class="div-full" v-if="showAlertSucces2">
              <div class="alert alert-success margin_top" role="alert" >
                {{msgAlert}}
              </div>
            </b-col>
          </b-row>
          <b-row class="div-full">
            <b-col cols="12" class="div-full" v-if="showAlert2">
              <div class="alert alert-warning margin_top" role="alert" >
                {{msgAlert}}
              </div>
            </b-col>
          </b-row>
          <b-row class="div-full">
            <b-col cols="12" class="div-full">
                <button @click="saveData" type="button" class="btn btn-success margin_top pull-right">
                  <b-spinner small v-if="loading" label="Spinning"></b-spinner> Guardar
                </button>
            </b-col>
          </b-row>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import { API_INFO } from "@/constants.js";


import axios from "axios";
import delay from 'delay';

export default {

  props: {
    showModal: Boolean,
    reservation: Object,
    list_employee: Object,
    showAlertSucces: Boolean,
    showAlert: Boolean,
  },
  components: {
 
  },
  data() {
    return {

      options: [
          { value: 1, text: 'Interesado' },
          { value: 2, text: 'Reserva' },
          { value: 3, text: 'Pendiente Confirmación' },
          { value: 4, text: 'Cancelado' },
          { value: 5, text: 'Modificada' },
          { value: 6, text: 'Ampliación' },
          { value: 7, text: 'Reducción' },
          { value: 8, text: 'Devolución' },
          { value: 9, text: 'No presentado' },
      ],
      optionsMasajista: [],
      showAlert2: this.showAlert,
      showAlertSucces2: this.showAlertSucces,
      showModalDetail:false,
      loading:false,
      msgAlert: '',
    };
  },
  watch: {

  },

  methods: {
    closeModal() {
      this.loading = false;
      this.showAlert2=  false;
      this.showAlertSucces2=  false;
      this.$emit("close-modal")
    },
    async takeMasajistas(){
      const options = {
          headers: { 
            Authorization: "JWT " + localStorage.accessToken,
          },
        };
        try {

          const resp = await axios.get(API_INFO + "/dashboard/employees/working-hours/?begin_date="+this.reservation.date+
                                      '&end_date='+this.reservation.date, options);
          if (resp.status === 200) {
            // console.log(resp.data)
            this.list_employee = resp.data
          }
        } catch (error) {
          //this.$emit("alert", 1)
        }
    },
    async saveData(){
      this.showAlert2=  false;
      this.showAlertSucces2=  false;
      this.loading = true;
      const options = {
          headers: { 
            Authorization: "JWT " + localStorage.accessToken,
          },
        };
        try {
          if(this.reservation.status == ''){
              this.showAlert2=  true;
              this.msgAlert= 'Datos incorrectos, por favor revice la información ingresada.';
              this.loading = false;
              return false;
          }
          /*if(this.reservation.status == '' || this.reservation.address == '' || this.reservation.date == ''
            || this.reservation.people == '' || this.reservation.begin_journey == '' || this.reservation.end_journey == '' 
            || this.reservation.begin_service == '' || this.reservation.end_service == ''){
              this.showAlert=  true;
              this.msgAlert= 'Datos incorrectos, por favor revice la información ingresada.';
              this.loading = false;
              return false;
          }*/
          const data = {
            employee: this.reservation.employee,
            status: this.reservation.status,
            /*address: this.reservation.address,
            date: this.reservation.date,
            people: this.reservation.people,
            id: this.reservation.id,
            begin_journey: this.reservation.date + ' ' +this.reservation.begin_service,
            end_journey: this.reservation.date + ' ' +this.reservation.end_service,
            begin_service: this.reservation.date + ' ' +this.reservation.begin_massage,
            end_service: this.reservation.date + ' ' +this.reservation.end_massage,*/
          }
          const resp = await axios.put(API_INFO + "/dashboard/reservations/"+this.reservation.id+"/update-elements/", data, options);
          if (resp.status === 200) {
            this.showAlertSucces2=  true;
            this.msgAlert= 'Datos guardados con éxito.';
            this.loading = false;
            this.$emit("loading")
          }
        } catch (error) {
          this.showAlert2=  true;
          this.msgAlert= 'Por favor revise los datos ingresados.';
          this.loading = false;
        }
    }
  },
};
</script>

<style lang="scss" scoped>
.alert-success{
  left: 0px !important;
}
.div-full{
  width: 100%  !important;
}
.margin_top{
  margin-top: 20px;
}
.location-row {
  border-radius: 20px !important;
  border: 4px solid #316d9e;
  background:  #eeeeee;
  padding: 15px 15px 35px 15px;
  max-width: 630px;
  position: relative;

  td {
    width: 42%;
    position: relative;
    right: 5%;
    &:last-of-type {
      tr {
        border: 1px solid #000;
        background: #d9edf7;
        &:last-of-type {
          height: 75px;
          div select {
            min-height: 65px;
          }
        }
      }
    }
  }
  tr {
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 5px;
    text-align: center;
    display: block;
    margin-top: 4px;
    height: 37px;
  }
}
button {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
}
input {
  border: none;
  background: transparent;
  height: 22px;
  text-align: center;
  &:focus {
    outline: none;
  }
}
select {
  max-height: 31px;
  background:#d9edf7;
  border: none;
  font-size: 15.5px;
  text-align-last:center;
  color: rgb(27, 27, 27);
}
.checkbox {
  border: none !important;
  background: transparent !important;
  div .custom-control-label::before,
  .custom-control-label::after {
    display: none !important;
  }
}

.service-row {
  height: fit-content;
  justify-content: flex-end;
  // background: #F1F1F1;
}
.long-col {
  flex: 0 0 48.333333%;
  max-width: 48.333333%;
  padding: 12px 0px 12px 12px;
  h3 {
    text-align: center;
    font-weight: 900;
    margin: 5px;
    position: relative;
    right: 10px;
  }
  p {
    margin: 8px 0px 10px;
  }
}
.bi-x-square {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 10px;
  right: 25px;
  cursor: pointer;
  background: antiquewhite;
  z-index: 130;
  &:hover {
    background: rgba(255, 117, 98, 0.822);
  }
}
p{
  margin-bottom: 5px !important;
}
.div-scroll{
  width: 100% !important;
  height: 350px !important;
  overflow-y: auto !important;
}
</style>
