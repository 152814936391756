<template>
  <div id="wrapper">

    <Header
      @login="openLoginModal"
      @logout="logout"
      :logoutButton.sync="logoutButton"
    />
    <b-row>
      <b-col class="justify-content-center div-pay" v-if="reservationShow">
        <!--<b-icon-check2-circle class="mr-3" />-->
        <b-icon-exclamation-triangle class="alert-icon" />

        <h2>No hemos podido procesar tu pago</h2>
        <p>Para intentar de nuevo, vuelve a la <a href="/carrito">selección de servicios</a> </p>
      </b-col>

      <b-col class="justify-content-center div-pay" v-else>
        <b-icon-alarm  class="alert-icon" />


        <h2>No tiene ninguna reserva pendiente para pago.</h2>
        <p>Puede ir a nuestra página de <a href="/">inicio</a> para seguir viendo nuestros servicios. </p>
      </b-col>
    </b-row>

    <ModalLogin 
      :my-modal.sync="modal" 
      @login-success="loginSuccess" 
      @data-client="dataClient"
    />
    
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import ModalLogin from "@/components/ModalLogin.vue";
import { getCenterServices } from "@/api/EventService.js";
import { API_INFO, serv } from "@/constants.js";

import axios from "axios";
import delay from "delay";

export default {
  name: "PagoError",
  components: {
    ModalLogin,
    Header,
    Footer,
  
  },
  data() {
   return {

      logoutButton: false,
      modal: "",
      loggedAlert: false,
      reservationShow: true,
  
    };
  },
  created() {
    const tokenExist = localStorage.getItem("accessToken");
    if (tokenExist) {
      this.logoutButton = true;
    }
    const reservation = localStorage.getItem("reservation");
    const cart_code = localStorage.getItem("cart_code");
    const error_tvp = localStorage.getItem("error_tvp");

    if(error_tvp == false){
      this.reservationShow = false;
    } else {
      if(reservation){
        this.payReservationError(reservation, cart_code);
      } else {
        this.reservationShow = false;
      }
    }
    
  },
  computed: {

  },
  methods: {
  
    async loginSuccess() {
      this.loggedAlert = true;
      this.modal = "";
      this.logoutButton = true;
      this.callGeoData();
      await delay(3600);
      this.loggedAlert = false;
    },
    async callGeoData() {
      const options = {
        headers: { Authorization: "JWT " + localStorage.accessToken },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/clients/my-geo-data/",
          options
        );
        if (resp.status === 200) {
          // console.log(resp, "response");
          //console.log(resp.data)
          localStorage.setItem("geo-data", resp.data);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async logout() {
      const options = {
        headers: { Authorization: "JWT " + localStorage.accessToken },
      };
      try {
        const resp = await axios.post(API_INFO + "/logout/", "", options);
        if (resp.status === 200) {
          this.logoutButton = false;
          localStorage.clear();
          this.logoutAlert = true;
          await delay(4200);
          this.logoutAlert = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async dataClient(item){
      this.first_name = item.first_name
      this.last_name = item.last_name
      this.idClient = item.id
      // console.log(this.first_name)
    },
    openLoginModal() {
      this.modal = "login";
    },
    async payReservationError(id, code) {

      try {
        const resp = await axios.post(
          API_INFO + "/reservations/fail-payment/", {id, cart_code: code}
        );
        if (resp.status === 200) {
          // localStorage.removeItem("reservation");
          // localStorage.removeItem("cart_code");
          localStorage.setItem("cart_code", resp.data.active_cart_code);
          localStorage.setItem("error_tvp", true);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
.alert-icon{
  font-size: 6em !important;
  color: 	#ffcc00 !important;
}
.up-modal {
  width: 100%;
  height: 100%;
  background: #0000005e;
  backdrop-filter: blur(4px);

  position: fixed;
  z-index: 1100;
  top: 0;
  align-items: flex-end;
  display: block;
}
.row-modal {
  position: relative;
  top: 20%;
  justify-content: center;
}
.col-modal {
  background: #f3f4f2;
  border: none;
  max-width: 600px;
  min-width: 300px;
  @media (max-width: 1200px) {
    max-width: 700px;
    min-width: 300px;
  }
}
.login {
  justify-content: end;
  display: flex;
  .login-btn1 {
    /*   position: absolute; */
    top: 140px;
    right: 25%;
  }
}
.alert-success {
  position: relative;
  left: 50%;
}

.div-pay{
  text-align: center  !important;
  padding: 60px  !important;
}
</style>
