<template>
  <div v-if="showModal === true" class="up-modal" style="right: 0px">
    <b-row class="justify-content-center card-row">
      <b-icon-x-square @click="closeModal" />
      <b-col cols="11" class="p-0 justify-content-center d-flex">
        <b-col 
          cols="12"
          class="employee-card pt-4"
        >
          <b-col>
            <b-row class="mt-5">
              <b-col>
                <div class="hour-title">
                  <h3 @click="logReservs">Total de horas:</h3>  
                </div>
              </b-col>
            </b-row>
            <b-row class="w-100 m-0 days-row">
              <div v-for="(i,l) in tableDays" :key="l" class="hours-col">
                <div>
                  <div 
                    @click="selectDate(l)"
                    class="hours-div"
                    :class="employeeReservations[l].length == 0 ? 'no-reserv':'show-reserv'"
                  >
                    <p>{{i.slice(-5)}}</p>
                  </div>
                </div>
              </div>
              <div v-if="selectedDate.length !== 0" class="pt-5 mb-5">
                <div class="hours-div show-reserv p-4">
                  <ul v-for="(i,l) in selectedDate" :key="l">
                    <li>
                      {{i}}
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="selectedDate.length == 0 & hasSelectedDate" class="pt-5 mb-5">
                <div class="hours-div no-reserv py-5 px-4">
                  No hay reservas para la fecha seleccionada.
                </div>
              </div>
              <div v-if="!hasSelectedDate" class="pt-5 mb-5" style="background: #ebebeb;">
                <div class="hours-div p-4">
                  Seleccione una fecha.
                </div>
              </div>
            </b-row>
          </b-col>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { API_INFO } from "@/constants.js";
import axios from "axios";

export default {
  
  props: {
    showModal: Boolean,
    tableDays: Array,
    employeeReservations: Array
  },
  data() {
    return {
      selectedDate: "",
      hasSelectedDate: false
    };
  },
  mounted() {
    // this.getCenters()
  },
  methods: {
    closeModal() {
      this.hasSelectedDate = false
      this.$emit("close-modal")
    },
    logReservs() {
      this.selectedDate = [];
    },
    selectDate(num) {
      this.hasSelectedDate = true
      this.selectedDate = this.employeeReservations[num]
    }
  },
};
</script>

<style lang="scss" scoped>
.card-row {
  position: relative;
  top: 20vh;
}

.employee-card {
  background:rgb(241, 241, 241);
  border-radius: 15px;
  border: 4px solid rgb(151, 159, 173);
  min-height: 270px;
  width: 25vw;
  p {
    font-weight: 700;
    margin-bottom: 10px;
    padding-top: 18px;
    padding-bottom: 10px;
    text-align: center;
  }
  @media (max-width: 600px) {
    padding: 10px 0px 0px 0px
  }
}

.bi-x-square {
  width: 34px;
  height: 34px;
  position: absolute;
  top: -14%;
  right: 45px;
  cursor: pointer;
  background: antiquewhite;
  z-index: 130;
  &:hover {
    background: rgba(255, 117, 98, 0.822);
  }
}

.hours-col {
  flex: 0 0 13.78%;
  max-width: 13.78%;
  @media (max-width: 1260px) {
    left: 14px;
    flex: 0 0 14.28%;
    max-width: 14.28%;
  }
}
.days-row {
  justify-content: center;
}
.hour-title {
  text-align: center;
  width: 100%;
  height: 45px;
  // float: right;
  border: 1px solid #6d6d6d;
  background: #f9f9f9;
  padding-bottom: 7px;
  @media (max-width: 1260px) {
    width: 100%;
  }
  h3 {
    position: relative;
    top: -10px;
  }
}
.hours-div {
  // width: inherit;
  border: 1.5px solid #4e4e4e;;
}
.show-reserv {
  background: #b7f3a8;
  li {
    font-weight: 900;
    font-size: 15px;
  }
  ul {
    padding-left: 10px;
  }
}
.no-reserv {
  background:#ffdede;  
  list-style: none;
  font-weight: 900;
  font-size: 15px;
}

</style>
