<template>
  <div>
    <section v-if="showTable" class="tabla-compra">
      <div class="container">
        <div id="carrito" class="row" style="">
          <div class="col-md-12 tabla-compra-titular">
            Fecha, hora y servicios seleccionados - Hammam {{centerName}}
          </div>
          <div v-if="items.length === 0" class="col-md-12 tabla-compra-listado">
            
            <div class="table-hover tabla-servicios">
              <table class="table">
                <thead>
                  <tr>
                    <th class="td-servicio"></th>
                    <th class="td-cantidad"></th>
                    <th class="td-precio"></th>
                    <th class="td-borrar">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="td-servicio">No hay servicios seleccionados.</td>
                    <td class="td-cantidad">
                      <p></p>
                      <ul class="list-unstyled">
                        <li></li>
                        <li></li>
                      </ul>
                    </td>
                    <td class="td-precio"></td>
                    <td class="td-borrar"></td>
                  </tr>
                  <tr class="finalizar">
                    <td class="td-servicio">
                      <p class="peque">
                        - POLÍTICA DE CANCELACIÓN: se podrá cancelar la reserva hasta 48 horas antes a la fecha reservada, de lo contrario se perderá la totalidad del importe.<br/>
                        - El masajista llegará 15 minutos antes de la hora reservada para el masaje para realizar la correcta preparación del espacio y empezar puntual el servicio.<br/>
                        - No obstante, por razones de tráfico imprevisible, el masajista dispondrá de un intervalo de +- 15 minutos para llegar con adelanto o retraso a la cita convenida según lo especificado en la reserva. El retraso en más de 15 minutos sobre la hora convenida dará derecho al cliente a recibir un descuento del 30% del precio del servicio. El retraso de más de 30 minutos le dará derecho a anular el servicio y recibir la devolución del importe pagado por el mismo.<br/>
                        - Nuestro masajista se pondrá en contacto contigo con suficiente anticipación a la cita para confirmar que se cumplen todos los requerimientos de espacio y seguridad necesarios para el correcto desarrollo del servicio.<br/>
                        - IVA vigente 21% Incluido.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-else>
            <div class="col-md-12 tabla-compra-listado">
              <div class="table-hover tabla-servicios">
                <ul class="list-inline text-left tabla-general">
                  <li>
                    <p class="tit">Fecha:</p>
                    <p class="dato">{{ date }}</p>
                  </li>
                  <li>
                    <p class="tit">Turno:</p>
                    <p class="dato">{{ hour }}</p>
                  </li>
                </ul>
                <table class="table">
                  <thead>
                    <tr>
                      <th class="td-servicio">Servicios</th>
                      <th class="td-precio">Personas </th>
                      <th class="td-precio">Cantidad</th>
                      <th class="td-precio">Precio*</th>
                      <th class="td-precio">Valor</th>
                      <th class="td-borrar">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items" :key="item.id">
                      <td class="td-servicio">
                        {{ item.name }} -
                        Precio especial de lanzamiento
                      </td>
                      <td class="td-precio">
                        <p>{{ clients }}</p>
                      </td>
                      <td >
                        <b-form-select 
                          @input="calcPrice()"
                          class="input-number font-select"
                          v-model="item.quantity"
                          :options="optionsCount"
                        >
                        </b-form-select> 
                      </td>
                      <td class="td-precio">{{ formatPrice(item.price) }} €</td>
                      <td class="td-precio">{{ formatPrice(item.price * item.quantity) }} €</td>
                      <td class="td-borrar">
                        <button
                          id="borrar"
                          value="10029"
                          class="btn btn-borrar"
                          data-tarifa="0"
                          @click="deleteFromCart(item)"
                        ></button>
                      </td>
                    </tr>
                    <tr >
                      <td class="td-servicio">Coste adicional de desplazamiento (según distancia al Hammam)</td>
                      <td class="td-precio">
                  
                      </td>
                      <td >1</td>
                      <td class="td-precio"> {{formatPrice(feeValue)}} €</td>
                      <td class="td-precio"> {{formatPrice(feeValue)}} €</td>
                      <td class="td-borrar">
       
                      </td>
                    </tr>
                    <tr class="total">
                      <td
                        class="td-total"
                        style="padding-bottom: 25px;"
                        colspan="5"
                        v-if="fromReception == false"
                      >
                        <span>TOTAL:</span>
                        {{formatPrice(totalPrice)}} €
                      </td>
                      <td
                        class="td-total"
                        style="padding-bottom: 25px;"
                        colspan="5"
                        v-else
                      >
                        <span>TOTAL:</span>
                        <input
                          @input="updatePrice()"
                          v-model="totalPrice"
                          type="text"
                          style="width: 50px"
                        > €
                      </td>
                      <td class="td-borrar" style="padding-bottom: 25px;">
                        &nbsp;
                      </td>
                    </tr>
                    <tr class="finalizar">
                      <td class="td-servicio" colspan="3">
                        <p class="peque">
                        - POLÍTICA DE CANCELACIÓN: se podrá cancelar la reserva hasta 48 horas antes a la fecha reservada, de lo contrario se perderá la totalidad del importe.<br/>
                        - El masajista llegará 15 minutos antes de la hora reservada para el masaje para realizar la correcta preparación del espacio y empezar puntual el servicio.<br/>
                        - No obstante, por razones de tráfico imprevisible, el masajista dispondrá de un intervalo de +- 15 minutos para llegar con adelanto o retraso a la cita convenida según lo especificado en la reserva. El retraso en más de 15 minutos sobre la hora convenida dará derecho al cliente a recibir un descuento del 30% del precio del servicio. El retraso de más de 30 minutos le dará derecho a anular el servicio y recibir la devolución del importe pagado por el mismo.<br/>
                        - Nuestro masajista se pondrá en contacto contigo con suficiente anticipación a la cita para confirmar que se cumplen todos los requerimientos de espacio y seguridad necesarios para el correcto desarrollo del servicio.<br/>
                        - IVA vigente 21% Incluido.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4"></td>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <div
                          class="col-md-12 seleccion"
                          id="cajacodigodescuento"
                        >
                          <form class="form-horizontal row">
                            <div class="col-md-5">
                              <label
                                style="margin-bottom:0px; padding-left:38px;padding-top: 10px;"
                                >Si dispones de un código de descuento,
                                valídalo:</label
                              >
                            </div>
                            <div class="col-md-3">
                              <input
                                type="text"
                                style="width: 95%; text-transform: uppercase;"
                                v-model="code"
                              />
                            </div>
                            <div class="col-md-3">
                              <button
                                class="btn btn-comprar"
                                @click.prevent="validateCode(code)"
                                :disabled="codeValid"
                              >
                                Validar Código
                              </button>
                            </div>
                          </form>
                        </div>
                      </td>
                    </tr>
                    <b-row class="alert-row">
                      <b-col class="justify-content-center mt-4">
                        <b-alert
                          v-model="codeValidAlert"
                          variant="success"
                          dismissible
                          fade
                        >
                          CUPÓN DE DESCUENTO APLICADO! PRECIO FINAL:
                          {{ formatPrice(totalPrice) }} €
                        </b-alert>
                        <b-alert
                          v-model="codeErrorAlert"
                          variant="danger"
                          dismissible
                          fade
                        >
                          CUPÓN NO VÁLIDO.
                        </b-alert>
                      </b-col>
                    </b-row>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div id="loadercarrito" style="display: none;">
          <h4>Procesando, espera por favor...</h4>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import delay from "delay";
import { API_INFO } from "@/constants.js";

export default {
  name: "TablaReserva",

  props: {
    items: Array,
    date: String,
    hour: String,
    clients: Number,
    showTable: Boolean,
    feeValue: Number,
    totalPrice: Number,
  },
  data: () => ({
    code: "",
    codeValid: false,
    codeValidAlert: false,
    codeErrorAlert: false,
    optionsCount: [
          { value: '1', text: '1' },
          { value: '2', text: '2' }
    ],
    // totalPrice: "",
    fromReception: false
  }),
  mounted() {
    this.isReception()
  },
  watch: {
    totalPrice() {
      if(isNaN(this.totalPrice)) {
        this.totalPrice = 0
      }
    },
  },
  computed: {
    // totalPrice() {
    //   var total = 0;
    //   this.items.forEach((element) => {
    //     var price = element.price * element.quantity;
    //     total += price;
    //   });
    //   total = total + this.feeValue;
    //   return total;
    // },
    centerName() {
      let url = window.location.search
      let centro = url.split("&")[0];

      switch (centro.toLowerCase()) {
        case "?Granada":
          return "Granada"
        case "?Malaga":
          return "Malaga"
        case "?Palma":
          return "Palma"
        case "?Madrid":
          return "Madrid"
        case "?Cordoba":
          return "Cordoba"
        default:
          return ""
      }
    }
  },
  methods: {
    /*     checkForSpecialServices() {
      const hasSpecialRate = this.items.filter(
        (item) => item.rate !== "normal"
      );
      if (hasSpecialRate.length > 0) {
        this.specialRate = true;
      } else {
        this.specialRate = false;
      }
    }, */
    deleteFromCart(item) {
      this.$emit("delete-item", {
        id: item.id,
        name: item.name,
        price: item.price,
      });
    },
    async validateCode(code) {
      const options = {
        headers: { "Content-Type": "application/json" },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/info-discount-code/" + code + "/",
          "",
          options
        );
        if (resp.status === 200) {
          this.totalPrice = (
            this.totalPrice -
            this.totalPrice * (resp.data.percentage / 100)
          ).toFixed(2);
          this.codeValid = true;
          this.codeValidAlert = true;
          await delay(4200);
          this.codeValidAlert = false;
        }
      } catch (e) {
        this.codeErrorAlert = true;
        await delay(4200);
        this.codeErrorAlert = false;
      }
    },
    // calcTotalPrice() {
    //   this.totalPrice = 0;
    //   this.items.forEach((element) => {
    //     var price = element.price * element.quantity;
    //     this.totalPrice += price;
    //   });
    //   this.totalPrice = this.totalPrice + this.feeValue;
    // },
    isReception() {
      let url = window.location.pathname
      console.log(url);
      if(url == "/dashboard_dir") {
        this.fromReception = true
      }
    },
    updatePrice() {
      this.$emit("update-price", this.totalPrice)
    },
    calcPrice() {
      this.$emit("calc-price")
    },
    formatPrice(value) { 
    let val = (value/1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },  
  },
};
</script>

<style lang="scss" scoped>
.font-select{
  font-size: 1.6rem  !important;
}
label,
td {
  font-family: "Chap", Helvetica, Arial, sans-serif;
}
.alert-row {
  margin: auto;
  left: 10%;
  position: relative;
  .alert-success {
    left: 0%;
  }
}
.tabla-compra {
  padding: 0px;
}
.input-number{
  width: 50px !important;
}
</style>
