<template>
  <div v-show="showModal" class="up-modal" style="right: 0px">
    <b-icon-x-square @click="closeModal" />
    <b-row class="justify-content-center">
      <b-col class="justify-content-center d-flex">
        <b-row class="mt-5 location-row">
          <p class="text-center card-title mt-5">Reserva Id: {{reservation.id}}</p>
          <b-row class="w-100 service-row m-0 my-2">
            <div cols="4" class="long-col">
              <p><strong>Dirección:</strong> {{reservation.address}}</p>
              <p><strong>Servicios:</strong> 
                <span
                  v-for="j in reservation.service_ids[0]"
                  :key="j"
                >
                {{j}}, 
                </span>
              </p>
              <p><strong>Inicio de reserva:</strong> {{reservation.begin_service.slice(0, -3)}}hs</p>
              <p><strong>Fin de reserva:</strong> {{reservation.end_service.slice(0, -3)}}hs</p>
              <p><strong>Inicio masaje:</strong> {{reservation.begin_massage.slice(0, -3)}}hs</p>
              <p><strong>Fin masaje:</strong> {{reservation.end_massage.slice(0, -3)}}hs</p>
              <p><strong>Fecha:</strong> {{reservation.date}}</p>

            </div>
            <div class="long-col">
              <p><strong>Nombre cliente:</strong> {{reservation.client_name}}</p>
              <p><strong>Teléfono:</strong> {{reservation.contact_phone}}</p>
              <p><strong>Importe:</strong> {{reservation.total}} €</p>
              <p><strong>Proveedor de transporte:</strong> {{reservation.transport_provider}}</p>
              <p><strong> Alergias:</strong> {{reservation.allergies}}</p>
              <p><strong> Masajista:</strong> {{reservation.employee_name}}</p>
              <p><strong> Status:</strong> {{reservation.status_detail}}</p>
            </div>
            <button @click="envioCorreoConfirmacion" type="button" class="btn btn-info">Envia correo confirmación</button>
          </b-row>
        </b-row>
      </b-col>
    </b-row>
      <ModalAlert
      :showCard="showAlert"
      :title="alertMessage"
      :varian="varianAlert"
      @close-modal="() => showAlert = false"
    />
    
    <AlertLoading
      :showLoading="showLoading"
    />
 
  </div>
</template>
<script>
import { API_INFO } from "@/constants.js";
import ModalAlert from "@/components/alerts/ModalAlert.vue"
import AlertLoading from "@/components/alerts/AlertLoading.vue"

import axios from "axios";
import delay from 'delay';

export default {

  props: {
    showModal: Boolean,
    reservation: Object
  },
  components: {
    ModalAlert,
    AlertLoading
  },
  data() {
    return {
      alertMessage: "",
      showAlert: false,
      showModalDetail:false,
      showLoading: false,
      
      id: "",
      locality: "",
      cp: "",
      ratePercent: "",
      excludedServ: [],
      center: null,
      active: false,

      initLocality: "",
      initCp: "",
      initRatePercent: "",
      initExcludedServ: [],
      initCenter: null,
      initActive: false,

      reqMethod: "",
      patchBody: {},
      successMessage: "",
      errorMessage: "",
      showAlertError: false
    };
  },
  watch: {
    addLocation() {
      this.checkLocationExist()
    } 
  },
  computed: {
    selectedProvince() {
      if(this.center !== null & this.centers !== "") {
        var selected = this.centers.filter(el => el.id === this.center)
      }
      try {
        return selected[0].province
      } catch {
        return selected
      }
    }
  },
  methods: {
    alertModal(val){
      if (val == 1){
        this.showAlert = true;
        this.alertMessage = 'La confirmación de reserva se envió correctamente.';
        this.varianAlert = 'success';
      }
      if (val == 2){
        this.showAlert = true;
        this.alertMessage = 'Problemas con el envío de la confirmación por favor intentelo nuevamente';
        this.varianAlert = 'warning';
      }      
    },    
    closeModal() {
      this.$emit("close-modal")
    },

    async envioCorreoConfirmacion(){
      this.showLoading = true;
      try {
        const resp = await axios.get(API_INFO + "/send-email-confirmation/"+this.reservation.id+"/");
        if (resp.status === 200) {
          this.showLoading = false;
          this.alertModal(1)
        }
      } catch (e) {
        //console.log(e);
        this.showLoading = false;
        this.alertModal(2)
      }
    },    
  },
};
</script>

<style lang="scss" scoped>
.location-row {
  border-radius: 20px !important;
  border: 4px solid #316d9e;
  background:  #eeeeee;
  padding: 15px 15px 35px 15px;
  max-width: 630px;
  position: relative;
  top: 9vh;
  td {
    width: 42%;
    position: relative;
    right: 5%;
    &:last-of-type {
      tr {
        border: 1px solid #000;
        background: #d9edf7;
        &:last-of-type {
          height: 75px;
          div select {
            min-height: 65px;
          }
        }
      }
    }
  }
  tr {
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 5px;
    text-align: center;
    display: block;
    margin-top: 4px;
    height: 37px;
  }
}
button {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
}
input {
  border: none;
  background: transparent;
  height: 22px;
  text-align: center;
  &:focus {
    outline: none;
  }
}
select {
  max-height: 31px;
  background:#d9edf7;
  border: none;
  font-size: 15.5px;
  text-align-last:center;
  color: rgb(27, 27, 27);
}
.checkbox {
  border: none !important;
  background: transparent !important;
  div .custom-control-label::before,
  .custom-control-label::after {
    display: none !important;
  }
}

.service-row {
  height: fit-content;
  justify-content: flex-end;
  // background: #F1F1F1;
}
.long-col {
  flex: 0 0 48.333333%;
  max-width: 48.333333%;
  padding: 12px 0px 12px 12px;
  h3 {
    text-align: center;
    font-weight: 900;
    margin: 5px;
    position: relative;
    right: 10px;
  }
  p {
    margin: 8px 0px 10px;
  }
}
.bi-x-square {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 10px;
  right: 25px;
  cursor: pointer;
  background: antiquewhite;
  z-index: 130;
  &:hover {
    background: rgba(255, 117, 98, 0.822);
  }
}
</style>
