<template >
  <div class="full-container" @mouseleave="chamgeViewPage()">
  <NavBar />
  <b-container  fluid class="h-100 gray">

    <b-row class="title mt-5">
      <img class="img-top" src="../assets/img/hammam_Palma.jpg" alt="" />
      <h1 class="">Equipo Masajistas {{center_name}} </h1>

      <b-row class="div-date">
        <b-col cols="12" class="ml-5 mt-4">
          <b-input-group size="lg" class="mb-4 mt-2">
            <b-form-datepicker
              class="input-basic date-hour-inputs"
              v-model="date"
              placeholder="Fecha"
              :start-weekday="1"
              @input="getEmployeesTeams();
                      getEmployeesNoTeam();
                      getEmployeesUnavailable()
                      "   
            ></b-form-datepicker>
          </b-input-group>
          <b-form-select
            v-model="filterOptionsSelect"
            size="lg"
            class="my-3 persons-input input"
            @change="filterOptionsValidation()"
          >
            <b-form-select-option 
              value="null"
              class="placeholder-font"
              
            >
            Seleccionar filtro
            </b-form-select-option>
            <b-form-select-option value="empleados">Tipo empleado</b-form-select-option>
            <b-form-select-option value="servicios">Tipo servicio</b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
    </b-row>
    <br>
    <br>
    <b-container fluid class="" >
      <b-row class="justify-content-center">
        <b-col cols="6" class="justify-content-center"> 
          <div v-if="filterOptions === 'empleados'">
            <b-col cols="10" class="d-flex justify-content-center my-4 title-head first-head">
              <p>Empleados disponibles por equipo</p>
            </b-col>                      
              <b-col v-for="(key, value) in teams" :key="value" cols="12" class="d-flex justify-content-center">
                <b-row class="d-flex justify-content-center w-100 my-4 team-row">
                  <b-col cols="12" class="d-flex justify-content-center">
                    <p class="team-title">{{key.name}}</p>
                  </b-col>
                  <b-row class="w-50 d-block justify-content-center">
                    <b-col 
                      v-for="i in key.regular"
                      :key="i.id"
                      cols="9"
                      class="d-flex justify-content-center empl-col"
                      style="left: 13%"
                    >
                      <p 
                        class="employee-bar"
                        style="cursor: default;"
                        @click="type = 'employee';
                                getEmployeeDetail(i.id);
                              "  
                      >
                        {{i.name}}
                      </p> <br>
                    </b-col>
                  </b-row>
                  <b-row class="w-50 d-block justify-content-center">
                    <b-col 
                      v-for="i in key.ett"
                      :key="i.id"
                      cols="9"
                      class="d-flex justify-content-center empl-col"
                      style="left: 13%"
                    >
                      <p 
                        class="employee-bar ett-bar"
                        style="cursor: default;"
                        @click="type = 'ett';
                                getEmployeeDetail(i.id);
                                "
                        >
                        {{i.name}}
                      </p> <br>
                    </b-col>
                  </b-row>
                </b-row>
              </b-col>
          </div> 
          <div v-else-if="filterOptions === 'servicios'">
            <b-col cols="10" class="d-flex justify-content-center my-4 title-head first-head">
              <p>Empleados disponibles por equipo</p>
            </b-col>
              <b-col v-for="(value, k) in teams" :key="k" cols="12" class="d-flex justify-content-center">
                <b-row class="d-flex justify-content-center w-100 my-4 team-row">
                  <b-col cols="12" class="d-flex justify-content-center">
                    <p class="team-title">{{value.name}}</p>
                  </b-col>
                  <b-row class="w-50 d-block justify-content-center">
                    <Drop 
                      class="w-100"
                      @drop="handleDrop(dropCenter, value, ...arguments)"
                    >
                      <b-col 
                        cols="9"
                        class="d-flex justify-content-center empl-col"
                        style="left: 13%"
                      >
                        <p class="service-place" value='title'>Centro</p>
                      </b-col>
                      <div v-if="value.centro.length > 0">
                        <b-col 
                          v-for="(val2, i) in value.centro"
                          :key="i"
                          cols="9"
                          class="d-flex justify-content-center empl-col "
                          style="left: 13%"
                        >
                          <Drag 
                            class="w-100"
                            :transfer-data="{ val2, value, dropCenter }"  
                          >
                            <p 
                              v-if="val2.is_ett === false"
                              class="employee-bar"
                              @click="type = 'employee';
                                      getEmployeeDetail(val2.id);
                                    "
                            >
                            {{val2.name}}
                            </p>
                            <p 
                              v-if="val2.is_ett === true"
                              class="employee-bar ett-bar"
                              @click="type = 'ett';
                                      getEmployeeDetail(val2.id);
                                      "
                            >
                            {{val2.name}}
                            </p> <br>
                          </Drag>
                        </b-col>
                      </div>
                      <div v-else>
                        <b-col 
                          v-for="i in 2"
                          :key="i"
                          cols="9"
                          class="d-flex justify-content-center empl-col"
                          style="left: 13%"
                        >
                            <p class="no-items mt-4" v-if="i == 1">No hay coincidencias</p>
                        </b-col>
                      </div>  
                    </Drop>
                  </b-row>
                  <hr>
                  <b-row class="w-50 d-block justify-content-center">
                    <Drop 
                      class="w-100"
                      @drop="handleDrop(dropHome, value, ...arguments)"
                    >
                      <b-col 
                        cols="9"
                        class="d-flex justify-content-center empl-col"
                        style="left: 13%"
                      >
                        <p class="service-place" value='title'>Domicilio</p>
                      </b-col>
                      <div v-if="value.domicilio.length > 0">
                        <b-col 
                          v-for="(val2, i) in value.domicilio"
                          :key="i"
                          cols="9"
                          class="d-flex justify-content-center empl-col"
                          style="left: 13%"
                        >
                          <Drag 
                            class="w-100"
                            :transfer-data="{ val2, value, dropHome }"  
                          >
                            <p 
                              v-if="val2.is_ett === false"
                              class="employee-bar"
                              @click="type = 'employee';
                                      getEmployeeDetail(val2.id);
                                      "
                            >
                            {{val2.name}}
                            </p>
                            <p 
                              v-if="val2.is_ett === true"
                              class="employee-bar ett-bar"
                              @click="type = 'ett';
                                      getEmployeeDetail(val2.id);
                                    "
                            >
                            {{val2.name}}
                            </p> <br>
                          </Drag>
                        </b-col>
                      </div>
                      <div v-else>
                        <b-col 
                          v-for="i in 2"
                          :key="i"
                          cols="9"
                          class="d-flex justify-content-center empl-col"
                          style="left: 13%"
                        >
                            <p class="no-items mt-4" v-if="i == 1">No hay coincidencias</p>
                        </b-col>
                      </div>   
                    </Drop>
                  </b-row>
                </b-row>
              </b-col>
          </div>
        </b-col>

        <b-col cols="6"  class="p-0 mt-4">
          <b-row class="second-empl-row">
            <b-col cols="6" class="">
              <p  class="title-head second-head">Empleados disponibles sin equipo</p>
              <b-row class="employee-row mb-5">
                <Drop 
                  class="w-100"
                  @drop="handleDrop(noTeam, ...arguments)"
                >
                  <div v-if="emplNoTeam.length > 0" >
                    <b-col 
                      v-for="(i, val) in emplNoTeam"
                      :key="i.id"
                      cols="9"
                      :style="[val === 0 ? {'margin-top': '35px'} : '']"
                      class="d-flex justify-content-center empl-col margin_bottom_10"
                      style="left: 13%"
                    >
                      <Drag 
                        class="w-100 "
                        :data="1"
                        :transfer-data="{ i, val, noTeam }"
                      >
                        <p 
                          v-if="i.is_ett === false"
                          :style="[filterOptions === 'empleados' ? {'cursor': 'default'} : '']"
                          class="employee-bar"
                          @click="type = 'employee';
                                  getEmployeeDetail(i.id);
                                  "
                        >
                        {{i.name}}
                        </p>
                        <p 
                          v-if="i.is_ett === true"
                          :style="[filterOptions === 'empleados' ? {'cursor': 'default'} : '']"
                          class="employee-bar ett-bar"
                          @click="type = 'ett';
                                  getEmployeeDetail(i.id);
                                "
                        >
                        {{i.name}}
                        </p> 
                      </Drag>
                    </b-col>
                  </div>
                  <div v-else>
                    <b-col 
                      v-for="i in 2"
                      :key="i"
                      cols="9"
                      class="d-flex justify-content-center empl-col"
                      style="left: 13%"
                    >
                        <p class="no-items mt-4" v-if="i == 1">No hay coincidencias</p>
                    </b-col>
                  </div>  
                </Drop>
              </b-row>
            </b-col>
            <b-col cols="6" class="">
              <p class="third-head">Empleados no disponibles</p>
              <b-row 
                class="employee-row"
                v-bind:class="{ divResponsive: emplUnavailable.length > 6 }"
              >
                <Drop 
                  class="w-100"
                  @drop="handleDrop(noAvailable, ...arguments)"
                >
                  <b-col 
                    v-for="(i, val) in emplUnavailable"
                    :key="i.id"
                    cols="9"
                    :style="[val === 0 ? {'margin-top': '35px'} : '']"
                    class="d-flex justify-content-center empl-col"
                    style="left: 13%;"                  
                  >
                    <Drag 
                      class="w-100 "
                      :data="1"
                      :transfer-data="{ i, val, noAvailable }"
                    >
                      <p 
                        v-if="i.is_ett === false"
                        class="employee-bar margin_bottom_10"
                        style="cursor: default;" 
                        @click="type = 'employee';
                                getEmployeeDetail(i.id);
                                "
                      >
                      {{i.name}}
                      </p>
                      <p 
                        v-if="i.is_ett === true"
                        class="employee-bar ett-bar"
                        style="cursor: default;"
                        @click="type = 'ett';
                                getEmployeeDetail(i.id);
                              "
                      >
                      {{i.name}}
                      </p> 
                      <br>
                    </Drag>
                  </b-col>
                </Drop>
              </b-row>
            </b-col>
          </b-row>
        </b-col> 
        <b-button type="button" @click="()=> {this.validationBtn = true; confirmDate('dragAndDrop')}" class="mt-5 mx-4" variant="success">
          <b-icon-check2-square class="mr-3" />
          Guardar Cambios
        </b-button>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-row class="justify-content-center">
        <hr class="division">
        <b-col cols="12" class="justify-content-center my-5"> 
          <div class="search">
            <ul class="ul-search">
              <li>Nombre:</li>
              <li><input
                  class="form-control"
                  placeholder="Nombre"
                  type="text"
                  v-model="filterName"
                /></li>
              <li>Domicilio:</li>
              <li> <input
                  class="form-control"
                  placeholder="Domicilio"
                  type="text"
                  v-model="filterAddress"
                /></li>
              <li>Sede:</li>
              <li> <input
                  class="form-control"
                  placeholder="Sede"
                  type="text"
                  v-model="filterLocation"
                /></li>
            </ul>
            
          </div>
          <div class="mb-5">
            <b-button type="button" @click="getEmployeesList" class="ml-5 table-btn" variant="primary">
              <b-icon-search class="mr-3" />
              Buscar
            </b-button>
            <b-button @click="clearSearch" v-if="showClearSearch"  class="table-btn clear-btn" variant="danger">
                <b-icon-trash class="icon" />
              </b-button>
            <b-button type="button" @click="()=> {this.validationBtn = true; confirmDate('table')}" class="mt-5 mx-4 table-btn" variant="success">
              <b-icon-check2-square class="mr-3" />
              Guardar Cambios
            </b-button>
          </div>
          <div class="empl-board">
            <table class="table table-responsive">
            <tr>
              <td>
                <p>{{tableFields[0]}}</p>
              </td>
              <td>
                <p>{{tableFields[1]}}</p>
              </td>
              <td>
                <p>{{tableFields[2]}}</p>
              </td>
              
    
              <td>
                <p>{{tableFields[3]}}</p>
              </td>

              <td>
                <p>{{tableFields[4]}}</p>
              </td>

              <td>
                <p>{{tableFields[5]}}</p>
              </td>

              <td>
                <p>{{tableFields[6]}}</p>
              </td>

              <td>
                <p>{{tableFields[7]}}</p>
              </td>
              <td>
                <p>{{tableFields[8]}}</p>
              </td>
              <td>
                <p>{{tableFields[9]}}</p>
              </td>
              <td>
                <p>Eliminar</p>
              </td>
            </tr>
            <tr v-for="(i, num) in employeeList" :key="num">
              
              <td >
                <p>{{i["id"]}}</p>
              </td>
              <td >
                <p>{{i["first_name"]}}</p>
              </td>
              <td >
                <p>{{i["last_name"]}}</p>
              </td>
              <td >
                <p>{{i["hq"]}}</p>
              </td>
              <td >
                <p>{{i["company_phone"]}}</p>
              </td>
              <td >
                <p>{{i["contract_hours"]}}</p>
              </td>
              <td >
                <p>{{i["team"]}}</p>
              </td>
              <td >
                <p>{{i["halmma"]}}</p>
              </td>
              <td >
                <p>
                  <input v-model="employeeHours[num]['begin']" type="text" class="" />
                </p>
              </td>
              <td >
                <p>
                  <input v-model="employeeHours[num]['end']" type="text" class="" />
                </p>
              </td>
              <td >
                <p> 
                  <b-icon-trash-fill 
                    @click="deleteEmployee(i.id)"
                    style="cursor: pointer"
                  /> 
                </p>
              </td>
            </tr>

            </table>
          </div>
        </b-col> 
      </b-row>
    </b-container>

    <EmployeeDetail
      :typeWorker="employee"
      :type="type"
      :showCard="showEmplCard"
      @close-modal="showEmplCard = false"
    />
    <DateInputs 
      :showCard="showModalDate"
      :saveFrom="saveFrom"
      @close-modal="showModalDate = false"
      @save-team-changes="saveChanges"
      @save-hours-changes="setModifiedEmployees"
    />
    <ModalConfirmation 
      :title="titleConfirmation"
      :showCard="showModalConfirmation"
      @close-modal="showModalConfirmation = false; validationChange =false;"
      @save-changes="confirmDate"
    />

  </b-container>
  </div>
</template>

<script>
import EmployeeDetail from "@/components/dashboard/EmployeeDetail.vue"
import DateInputs from "@/components/dashboard/DateInputs.vue"
import ModalConfirmation from "@/components/dashboard/ModalConfirmation.vue"
import NavBar from "@/components/NavBar.vue"
import { Drag, Drop } from 'vue-drag-drop';

import { API_INFO } from "@/constants.js";
import axios from "axios";
import yesno from "yesno-dialog";
import delay from 'delay'


export default {
  components: {
    EmployeeDetail,
    DateInputs,
    ModalConfirmation,
    Drag,
    Drop,
    NavBar  },
  data() {
    return {
      center_name: "",
      filterOptions: "servicios",
      filterOptionsSelect: "servicios",
      showEmplCard: false,
      phone: "",
      teams: [],
      emplNoTeam: [],
      emplUnavailable: [],
      employee: [],
      type: "employee",
      selectedWorker: this.employee,
      tableFields: [
        "-",
        "Nombre",
        "Apellido",
        "Sede",
        "Teléfono",
        "Horas Contrato",
        "Equipo",
        "Halmma",
        "Horario entrada",
        "Horario salida"
      ],
      employeeList: [],
      visible: null,
      noAvailable: "noAvailable",
      noTeam: "noTeam",
      dropCenter: "centro",
      dropHome: "domicilio",
      date: new Date().toISOString().split('T')[0],
      showModalDate: false,
      showModalConfirmation: false,
      teamsArray: [],
      centerArray: [],
      homeArray: [],
      noAvailableArray: [],
      beginDate: "",
      endDate: "",

      filterName: "",
      filterAddress: "",
      filterLocation: "",
      employeeFilter: "",

      titleConfirmation: "Tiene cambios sin guardar, ¿Desea guardarlos?",
      validationChange: false,
      employeeHours: [],
      initialEmployeeHours: [],
      modifiedEmployee: [],
      saveFrom: "",
      appliedChanges: 0,
      validationBtn: false,
      showClearSearch: false,
      centerId: localStorage.centerId,
      centers: []
    }
  },
  mounted() {
    this.getEmployeesTeams()
    this.getEmployeesNoTeam()
    this.getEmployeesUnavailable()
    this.getEmployeesList()
    this.getCenters()
  },
  watch: {
    filterOptions() {

        this.getEmployeesTeams()

    } 
  },
  computed: {
    typeWorker() {
      if(this.type === "employee") {
        return this.employee
      }
      return this.ett
    }
  },
  methods: {
    handleDrop(signal, drop, empl) {
      let destiny
      let origin
      let quit
      this.validationChange = true;
      // cambios de "sin equipo" a "equipos":
      if(empl["noTeam"] && signal !== this.noTeam) {
        quit = this.emplNoTeam.filter(el => el.id != empl.i.id)
        this.emplNoTeam = quit
        this.teams.forEach(element => {
          if(element.id === drop.id) {
           destiny = element
          }    
        });
        destiny[signal].push(Object(empl.i))
      // cambios de "no disponible" a "equipos":
      } else if(empl["noAvailable"] && signal !== this.noTeam && signal !== this.noAvailable) {
        quit = this.emplUnavailable.filter(el => el.id != empl.i.id)
        this.emplUnavailable = quit
        this.teams.forEach(element => {
          if(element.id === drop.id) {
           destiny = element
          }    
        });
        destiny[signal].push(Object(empl.i))
      // cambios a "sin equipo":
      } else if (signal === this.noTeam) {
        if(drop["dropCenter"] || drop["dropHome"]) {
          this.teams.forEach(element => {
            if(element.id === drop.value.id) {
              destiny = element
            }
          })
        }
        // Cambios de "equipos" a "sin equipo":
        if(drop["dropCenter"]) {
          quit = destiny.centro.filter(el => el.id != drop.val2.id)
          destiny.centro = quit
          this.emplNoTeam.push(Object(drop.val2))
        } else if (drop["dropHome"]) {
          quit = destiny.domicilio.filter(el => el.id != drop.val2.id)
          destiny.domicilio = quit
          this.emplNoTeam.push(Object(drop.val2))
        // Cambios de "no disponible" a "sin equipo":
        } else if (drop["noAvailable"]) {
          quit = this.emplUnavailable.filter(el => el.id != drop.i.id)
          this.emplUnavailable = quit
          this.emplNoTeam.push(Object(drop.i))
        }
      // cambios entre equipos, y laterales entre centro y domicilio:
      } else if (signal === this.dropHome || signal === this.dropCenter) {
        this.teams.forEach(element => {
          if(element.id === empl.value.id) {
           origin = element
          }    
        });
        if(empl["dropCenter"]) {
          quit = origin.centro.filter(el => el.id != empl.val2.id)
          origin.centro = quit
        } else {
          quit = origin.domicilio.filter(el => el.id != empl.val2.id)
          origin.domicilio = quit
        }
        this.teams.forEach(element => {
          if(element.id === drop.id) {
           destiny = element
          }    
        });
        destiny[signal].push(Object(empl.val2))
      // Cambios de "sin equipo" a "no disponible":
      } else if(drop["noTeam"] && signal == this.noAvailable) {
        quit = this.emplNoTeam.filter(el => el.id != drop.i.id)
        this.emplNoTeam = quit
        this.emplUnavailable.push(Object(drop.i))
      // Cambios de "equipos" a "no disponible":
      } else if (signal == this.noAvailable) {
        this.teams.forEach(element => {
          if(element.id === drop.value.id) {
            destiny = element
          }
        })
        if(drop["dropCenter"]) {
          quit = destiny.centro.filter(el => el.id != drop.val2.id)
          destiny.centro = quit
          this.emplUnavailable.push(Object(drop.val2))
        } else if (drop["dropHome"]) {
          quit = destiny.domicilio.filter(el => el.id != drop.val2.id)
          destiny.domicilio = quit
          this.emplUnavailable.push(Object(drop.val2))
        }
      }
    },
    async getEmployeesTeams() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/employees/available-in-team/" +
          `?filter=${this.filterOptions}&date=${this.date}`,
          options);
        if (resp.status === 200) {
          this.teams = resp.data
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getEmployeesNoTeam() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/employees/available-no-team/" +
          `?date=${this.date}`,
          options);
        if (resp.status === 200) {
          this.emplNoTeam = resp.data
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getEmployeesUnavailable() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/employees/unavailable/" +
          `?date=${this.date}`,
          options
        )
        if (resp.status === 200) {
          this.emplUnavailable = resp.data
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getEmployeesList() {
      this.createEmployeeFilter()
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/employees/" + this.employeeFilter, 
          options
        )
        if (resp.status === 200) {
          this.employeeList = resp.data
          this.setEmployeeHours()
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getEmployeeDetail(id) {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/employees/" + `${id}/`,
          options
        );
        if (resp.status === 200) {
          this.employee = resp.data
          this.showEmplCard = true
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deleteEmployee(id) {
      alert("Por el momento esta función no se encuentra habilitada")
      // const options = {
      //   headers: { 
      //     Authorization: "JWT " + localStorage.accessToken,
      //   },
      // };
      // var conf = confirm("¿Confirma que desea eliminar este ítem?")
      // if (conf) {
      //   try {
      //     const resp = await axios.delete(
      //       API_INFO + "/dashboard/employees/" + `${id}/`,
      //       options
      //     );
      //     if (resp.status === 204) {
      //       // this.successMessage = "¡Elemento eliminado!"
      //       console.log("¡Elemento eliminado!");
      //       this.getEmployeesList()
      //     }
      //   } catch (e) {
      //     console.log(e);
      //     this.errorMessage = "No ha sido posible procesar su petición"
      //     // this.showAlertError = true
      //     // await delay(3600)
      //     // this.showAlertError = false
      //   }
      // }
    },
    createEmployeeFilter() {
      this.employeeFilter = "?"
      let params = []
      if(this.filterName !== "") {
        params.push(`name=${this.filterName}`)
      }
      if(this.filterAddress !== "") {
        params.push(`domicilio=${this.filterAddress}`)
      }
      if(this.filterLocation !== "") {
        params.push(`sede=${this.filterLocation}`)
      }
      params.forEach(el => {
        this.employeeFilter += el + "&"
      })
      this.employeeFilter = this.employeeFilter.slice(0, -1);
      if(
        this.filterLocation === "" 
        & this.filterAddress === "" 
        & this.filterName === ""
      ) {
        this.showClearSearch = false
      } else {
        this.showClearSearch = true
      }
    },
    async confirmDate(value) {
      
      this.showModalConfirmation = false;
      
      let msg = "¿Desea guardar los cambios para la fecha actual o elegir rango de fechas?"
      let ok = await yesno({
        bodyText: msg,
        labelYes: "Fecha actual",
        labelNo: "Elegir fechas"
      })
      if(ok) {
        if(value === "table") {
          this.setModifiedEmployees(this.date)
        } else {
          this.saveChanges(this.date)
        }
      } else {
        this.saveFrom = value
        this.showModalDate = true
      }
    },
    async saveChanges(dates) {
      this.setQueryDate(dates)
      this.createTeamsArray()
      this.createCenterOrHomeArray()
      this.createNoAvailableArray()
      let statusOk = 0
      const options = {
        headers: {
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      const bodyTeams = {
        begin_date: this.beginDate,
        end_date: this.endDate,
        teams: this.teamsArray
      }
      const bodyCenterOrHome = {
        begin_date: this.beginDate,
        end_date: this.endDate,
        on_center: this.centerArray,
        on_delivery: this.homeArray
      }
      const bodyNoAvailable = {
        begin_date: this.beginDate,
        end_date: this.endDate,
        employees: this.noAvailableArray
      }
      try {
        const resp = await axios.post(
          API_INFO + "/dashboard/employees/assign-location-for-service/",
          bodyCenterOrHome,
          options
        );
        if (resp.status === 200) {
          statusOk += 1;
        }
      } catch (e) {
        console.log(e);
      }
      try {
        const resp = await axios.post(
          API_INFO + "/dashboard/employees/assign-team/",
          bodyTeams,
          options
        );
        if (resp.status === 200) {

          statusOk += 1;
        }
      } catch (e) {
        console.log(e);
      }
      try {
        const resp = await axios.post(
          API_INFO + "/dashboard/employees/remove-hours/",
          bodyNoAvailable,
          options
        );
        if (resp.status === 200) {
          statusOk += 1;
        }
      } catch (e) {
        console.log(e);
      }
      if(statusOk === 3) {
        this.validationChange = false;
        this.showModalConfirmaton = false;
        alert("Cambios guardados exitosamente!");
        
      } else {
        alert("Lo sentimos, no hemos podido procesar su consulta.")
      }
    },
    setQueryDate(dates) {
      if(typeof(dates) === "object") {
        this.beginDate = dates["start"]
        this.endDate = dates["end"]
      } else {
        this.beginDate = dates
        this.endDate = dates
      }
    },
    createTeamsArray() {
      this.teamsArray = []
      this.teams.forEach(el => {
        this.teamsArray.push({team_id: el.id, members: []})
      })
      for(var i in this.teams) {
        this.teams[i].centro.forEach(el => {
          this.teamsArray[i].members.push(el.id)
        })
        this.teams[i].domicilio.forEach(el => {
          this.teamsArray[i].members.push(el.id)
        })
      }
    },
    createCenterOrHomeArray() { 
      this.centerArray = []
      this.homeArray = []
      for(var i in this.teams) {
        this.teams[i].centro.forEach(el => {
          this.centerArray.push(el.id)
        })
        this.teams[i].domicilio.forEach(el => {
          this.homeArray.push(el.id)
        })
      }
    },
    createNoAvailableArray() {
      this.noAvailableArray = []
      this.emplUnavailable.forEach(el => {
        this.noAvailableArray.push(el.id)
      })
    },
    filterOptionsValidation() {
      if(this.validationChange == true){
        this.showModalConfirmation = true;
        this.filterOptionsSelect = this.filterOptions
        return false;
      } else {
        this.filterOptions = this.filterOptionsSelect;
      }

    },
    chamgeViewPage(){
      if(this.validationChange == true){
        if (this.validationBtn == false){
          this.showModalConfirmation = true;
        } else {
          this.validationBtn = false;
        }
        
      } 
    },
    setEmployeeHours() {
      for(let i of this.employeeList){
        if(i.working_hours.begin) {
          this.employeeHours.push(
            Object(
              {
                id: i.id,
                begin: i.working_hours.begin.slice(0, -3),
                end: i.working_hours.end.slice(0, -3)
              }  
            )
          )
        } else {
          this.employeeHours.push(
            Object(
              {
                id: i.id,
                begin: "-",
                end: "-"
              }  
            )
          )
        }
      }
      for(let i of this.employeeHours) {
        this.initialEmployeeHours.push(Object(
          {
            id: i.id,
            begin: i.begin,
            end: i.end
          }
        ))
      }
    },
    async setModifiedEmployees(date) {
      this.setQueryDate(date)
      this.modifiedEmployee = []
      for(let i in this.employeeHours) {
        if(this.initialEmployeeHours[i]["begin"] !== this.employeeHours[i]["begin"] || this.initialEmployeeHours[i]["end"] !== this.employeeHours[i]["end"]) {
          this.modifiedEmployee.push(this.employeeHours[i])
        }
      }
      this.appliedChanges = 0
      for(let i of this.modifiedEmployee) {
        await this.saveHourChanges(i)
      }
      if(this.appliedChanges === this.modifiedEmployee.length) {
        alert("Cambios guardados con éxito!")
      } else if (this.appliedChanges < this.modifiedEmployee.length & this.appliedChanges > 0) {
        alert("Algunos cambios no pudieron ser guardados.")
      } else if (this.appliedChanges == 0) {
        alert("Los cambios no pudieron ser guardados.")
      }

      this.getEmployeesList()
    },
    async saveHourChanges(params) {
      const options = {
        headers: {
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      const body = {
        begin_date: this.beginDate,
        end_date: this.endDate,
        begin_time: params.begin,
        end_time: params.end
      }
      try {
        const resp = await axios.post(
          API_INFO + "/dashboard/employees/" + 
          `${params.id}/assign-working-hours/`,
          body,
          options
        );
        if (resp.status === 200) {
          this.appliedChanges += 1
        }
      } catch {
        console.log("ERROR");
      }
    },
    clearSearch() {
      this.filterName = ""
      this.filterAddress = ""
      this.filterLocation = ""
      this.getEmployeesList()
    },
    async getCenters() {
      const options = {
        headers: {Authorization: "JWT " + localStorage.accessToken}
      };
      try {
        if(this.centers.length === 0) {
          const resp = await axios.get(API_INFO + "/dashboard/centers/", options);
          if (resp.status === 200) {
            this.centers = resp.data
            this.getCenterTitle()
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    getCenterTitle() {
      let userCenter = this.centers.filter((el) => el.id == this.centerId )
      if(userCenter.length) {
        this.center_name = userCenter[0].name
      } else {
        this.center_name = ""
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.full-container{
  width: 100%;
  position: relative;
}
p {
  font-weight: 500;
}
.gray {
  background-color: #9ea09266 !important;
}
.title {
  height: 140px;
  background: #b5b8a78f;
  h1 {
    font-size: 3.5em;
    position: relative;
    width: 45%;
    text-align: center;

  }
}
.title2 {
  height: 50px;
  background: #b5b8a78f;
  font-size: 2rem;
  display: flex;
  justify-content: space-evenly;
  h3 {
    font-size: 35px;
    position: relative;
    top: -10px;
  }
}
img {
  width: 10%;
  height: 130px;
  position: relative;
  top: 5px;
  
}
.team-title {
  width: 60%;
  text-align: center;
  background: rgb(199, 199, 199);
  border-radius: 2px;
  font-weight: 900;
}
select {
  font-size: 20px;
  position: relative;
  top: -5px;
  width: 100%;
}
.employee-bar {
  border-radius: 5px;
  height: auto !important;
  width: 95%;
  text-align: center;
  background: rgb(159, 187, 238);
  border: 3px solid rgb(75, 122, 209);
  color:  rgb(57, 93, 160);
  cursor: pointer;
  margin-bottom: 0px !important;
}
.ett-bar {
  background: #d4bfb5;
  border: 3px solid rgb(206, 120, 86);
  color: rgb(173, 114, 59);
  cursor: pointer;
}
.empl-col {
  height: auto !important;
}
.team-row {
  background:rgb(241, 241, 241);
  width: 87% !important;
  padding-bottom: 20px;
  border-radius: 10px;
  border: 2px solid rgb(177, 177, 177);
  position: relative;
  right: 5%;
}
.img-col {
  background: url(../assets/img/iaFace.jpeg);
  background-size: contain;
  height: 6.8vw;
  width: 6.8vw;
  background-repeat: no-repeat;
  position: relative;
  left: 7%;
}
td {
  width: 14vw;
  tr {
    padding: 5px;
    text-align: center;
    display: block;
  }
}
.employee-card {
  background:rgb(241, 241, 241);
  border-radius: 65px;
  border: 4px solid rgb(75, 122, 209);
  p {
    font-weight: 700;
  }
}
.ett-card{
  border: 4px solid rgb(206, 120, 86) ;
}
.ett-card-title {
  background: #d4bfb5;
}
.turnos {
  background: rgb(122, 179, 65);
  width: 60%;
  border-radius: 4px;
  margin: 0px auto 10px;
  color: rgb(221, 221, 221);
}
.description {
  padding-left: 35px;
}
hr {
  height: 75%;
  position: relative;
  top: -20px;
  border-right: dashed 3px rgb(177, 177, 177);
}
.service-place {
  font-weight: 900;
  font-size: 18.5px;
}
drop {
  width: 100%;
}
drag {
  width: 100%;
}
.title-head {
  height: auto  !important;
  border: 3px solid rgb(100, 155, 45);
  border-radius: 4px;
  background: rgb(208, 226, 205);
  color: rgb(100, 155, 45);
  font-weight: 900;
  p {
    font-size: 22px;
    font-weight: 900;
  }
  font-size: 22px;
}
.first-head {
  position: relative;
  left: 3.6% !important;
}
.second-head {
  position: relative;
  right: 3.6% !important;
  text-align: center;
}
.third-head {
  height: auto !important;
  border: 3px solid rgb(233, 61, 45);
  border-radius: 4px;
  background: rgb(243, 221, 218);
  color: rgb(246, 62, 62);
  font-weight: 900;
  text-align: center;
  font-size: 22px;
}
.second-empl-row {
  position: relative;
  right: 5%;
}
.employee-row {
  background:rgb(241, 241, 241);
  padding-bottom: 20px;
  border-radius: 10px;
  border: 2px solid rgb(177, 177, 177);
  width: 100%;
}
.empl-board {
  tr:nth-of-type(2n+1) {
    background:#d5c0c0;
  }
  tr:first-of-type {
    background: #68435bcc;
    p {
      font-weight: 900; 
      margin-bottom: 0px;
    }
  }
  tr td p {
    text-align: center;
    margin: 0 0 20px;
    input {
      border: none;
      background: transparent;
      text-align: center;
      width: 95%;
    }
  }
  tr td:first-of-type {
    width: 6%;
  }
  tr td:nth-of-type(2),
  tr td:nth-of-type(6) {
    width: 8%;
  }
  tr td:nth-of-type(3),
  tr td:nth-of-type(4) {
    width: 12%;
  }
  tr td:nth-of-type(5),
  tr td:nth-of-type(9),
  tr td:nth-of-type(10) {
    width: 10%;
  }
  tr td:nth-of-type(7) {
    width: 13%;
  }
  tr td:nth-of-type(8) {
    width: 4%;
  }
}
.search {
  td:nth-of-type(2n+1) {
    width: 7vw;
  }
  td:nth-of-type(2n+2) {
    width: 12vw;
  }
  select {
    position: relative;
    top: 0px;
  }
}
.table-btn {
  width: 14vw;
  margin: 0px 0px 0px 13px !important;
}
.clear-btn {
  width: 6vw;
  margin: 0px 0px 0px 13px !important;
}
.division {
  border-top: 3px dashed rgba(182, 182, 182, 0.856);
  width: 90%;
  position: relative;
  top: 12px;
}
.row div button {
  
  font-size: 16px;
  padding: 3px 6px 7px;
  font-weight: 900;
  &:first-of-type {
    padding-top: 9px;
  }
  &:last-of-type svg {
    width: 28px;
    height: 28px;
    position: relative;
    top: 4px;
  }
}
.no-items {
  background: #ddddddb7;
  border: 1px solid #c5c5c5;
  text-align: center;
  height: 38px;
  font-weight: 600;
  width: 100%;
}
button {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
  width: 20vw;
}

.margin_bottom_10{
  margin-bottom: 10px !important;
}

.divResponsive{
  width: auto !important;
  height: 600px !important;
  overflow-x: hidden;
  overflow-y: visible;
}

.img-top{
  margin-left: 14%;
}

.div-date{
  width: 22%;
}

.ul-search{
  list-style: none;
}

.ul-search li{
  float: left;
}
/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
  /* CSS */
  .img-top{
    margin-left: 12%;
  }
  .div-date{
    width: 27%;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  /* CSS */
  .img-top{
    margin-left: 8%;
  }
  .div-date{
    width: 32%;
  }
}

@media (max-width: 600px) {
  
  /* CSS */
  .img-top{
    margin-left: 5%;
    width: auto  !important;
    height: 60px !important;
  }
  .title h1 {
    font-size: 1.5em;
    width: 60% !important;
  }
  .div-date{
    width: 90%;
  }

  .title {
    height: auto !important;
  }
  .title-head{
    font-weight: 400;
    font-size: 1em;
    padding: 5px;
    word-wrap: break-word;
  }
  .third-head{
    font-weight: 400;
    font-size: 1em;
    padding: 5px;
    word-wrap: break-word;
  }
  .title-head p{
    font-weight: 400;
    font-size: 1em;

  }
  .container-fluid{
    padding-top: 0;
    padding-left: 5px;
    padding-right: 20px;
    margin: 0;
  }
  .team-title{
    width: auto;
  }

  .empl-col {
    padding: 0px;
    word-wrap: break-word;
  }

  .w-50{
    width: 100% !important;
  }
  hr{
    height: 0%;
  }
  .service-place{
    font-size: 1em;
    margin-bottom: 5px;
  }
  .no-items{
    height: auto;
    margin-bottom: 5px;
  }
  button {
    width: auto;
  }
  .ul-search li{
    float: none;
  }
}
</style>
