<template>
  <div class="d-flex align-center">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",

  components: {
    // HelloWorld
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
$hammam-grey: #f3f4f2;
$dark-grey: #babab4;
$hammam-blue: #82c5bf;

body {
  font-family: "Chap", Helvetica, Arial, sans-serif;
}

a {
  cursor: pointer;
  background-color: transparent;
}

#wrapper {
  overflow: hidden;
  background-color: $hammam-grey;
}

%button {
  font-family: "Chap", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  line-height: 1;
  display: inline-block;
  text-transform: uppercase;
  padding: 1.2rem 2.4rem;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #b77c63;
  text-decoration: none;
  letter-spacing: 2px;
}

.btn-outline-dark {
  @extend %button;
  border: 1px solid $dark-grey;
  color: $dark-grey;
  background-color: transparent;
  &:hover {
    background-color: #403d3d;
    color: #fff;
  }
}

.btn-outline-light {
  @extend %button;
  border: 1px solid #fff;
  color: #fff;
  background-color: transparent;
  &:hover {
    background-color: #fff;
    color: #9ea092;
  }
}

.btn-fill-light {
  @extend %button;
  color: #403d3d;
  background-color: #fff;
  border: 1px solid #fff;
  &:hover {
    background-color: #403d3d;
    color: #fff;
  }
}

.btn-fill-dark {
  @extend %button;
  color: #fff;
  background-color: #403d3d;
  border: 1px solid #403d3d;
  &:hover {
    background-color: #fff;
    color: #403d3d !important;
  }
}

button[disabled] {
  color: #fff;
  background-color: rgb(168, 168, 168);
}

select {
  background-color: transparent;
}

.input-basic {
  border: 1px solid #646869 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  background-color: transparent;
  padding: 6px 12px;
  &:focus {
    border: 1px solid #646869 !important;
  }
}

.input-group-text {
  background-color: $dark-grey;
  color: white;
  margin: 0.2rem;
  background-color: transparent;
}

.show-data-input {
  border-bottom: 1px solid rgb(119, 119, 119) !important;
  border: none;
  @media (max-width: 991px) {
    max-width: 160px;
  }
}

.baseline {
  align-items: baseline;
}

.placeholder-font {
  color: #757575a6;
  font-weight: 300;
  font-size: 16px;
}

.card-title {
  background: rgb(159, 187, 238);
  width: 80%;
  border-radius: 4px;
  margin: 25px auto;
  font-weight: 900;
}
</style>
