<template>
  <div v-show="legalConditions === true" class="up-modal">
  <article>
    
    <div class="card" >
      <div class="card-style">
        <b-progress :value="progress"></b-progress>
      </div>
      <b-icon-x-square @click="closeModal" />
      <b-card v-if="current_step == 1" class="card-style">
        <b-card-title
          ><b-icon icon="file-text-fill"></b-icon>Derechos servicio a domicilio
        </b-card-title>
        <div class="single-condition">
     
          <div>
            <p>
              1. Recibir antes de comenzar el masaje la explicación del servicio
              así como elegir los aromas y el tipo de presión que se prefiere
            </p>
            <p>
              2. Recibir este masaje por parte de profesional experto en
              plantilla de Hammmam Al Ándalus y, especialmente, formado para el
              mismo de acuerdo con sus características y objetivos emocionales.
            </p>
            <p>
              3. Puede parar el masaje en cualquier momento, si algo no le
              parece satisfactorio, sin derecho a reclamar por ello el importe
              pagado por el mismo.
            </p>
          </div>
        </div>
        <button
          class="btn-fill-dark"

          @click="onClickNext"
        >
          Siguiente
        </button>
      </b-card>
      <!-- <b-card v-if="current_step == 2" class="card-style">
        <b-card-title>
          <b-icon icon="search"></b-icon> Términos y condiciones de uso
        </b-card-title>
        <div class="single-condition">

          <p>
            Estoy de acuerdo con las
            <a href="https://hammamalandalus.com/proteccion-de-datos/" target="_blank">políticas</a>
             de protección de datos de Hammam Al Ándalus
          </p>
        </div>
        <button class="btn-outline-dark" @click="onClickBack">Atrás</button>
        <button
          class="btn-fill-dark"

          @click="onClickNext"
        >
          Siguiente
        </button>
      </b-card> -->
      <b-card v-if="current_step == 2" class="card-style">
        <b-card-title>
          <b-icon icon="person-check-fill"></b-icon> Necesidades para el
          correcto desarrollo del servicio
        </b-card-title>
        <div class="single-condition">

          <p>
            1. SITUACIÓN FÍSICA. Este masaje no está indicado para personas que
            sufran alteraciones hemorrágicas graves y fragilidad capilar,
            flebitis, trombosis, linfagitis, cardiopatías desconpensadas,
            afecciones tumorales, fracturas no consolidadas, brotes reumáticos
            agudos, lesiones de importancia osteaomuscular y en general
            cualquier enfermedad aguda en fase evolutiva hacia la agravacikón,
            por lo que deberán advertir al masajista de cualquier cuestión en
            este ámbito antes del inicio del masaje
          </p>
        </div>
        <div class="single-condition">
      
          <p>
            2. VESTIMENTA. Deberá presentarse al mismo con un bañador o atuendo
            sustitutivo, nunca desnudo ni en ropa interior
          </p>
        </div>
        <div class="single-condition">
 
          <p>
            3. ESPACIO. Se recomienda un espacio mínimo de al menos 11 m² donde
            se pueda evitar el máximo ruido posible y con la posibilidad de
            controlar la iluminación y climatización. El cliente es responsable
            de disponer de un espacio limpio y expedito para facilitar los
            movimientos y manipulación del masajista. En el supuesto de que sea
            imposible realizar el servicio en el espacio elegido por el
            contratante, el masajista ofrecerá la mejor alternativa para la
            realización del masaje contratado. En ningún caso, la no realización
            del masaje o la modificación de este como consecuencia de la
            inadecuación del espacio para la prestación del servicio dará
            derecho al contratante a reclamar la devolución del importe abonado
          </p>
        </div>
        <div class="single-condition">
    
          <p>
            4. AMBIENTE. El masajista sobre esta base de espacio aportará
            elementos y dispositivos que propicien el ambiente necesario en el
            que debe transcurrir el servicio para cumplir sus objetivos: velas y
            luz ambiente, música, aromas, etc.
          </p>
        </div>
        <div class="single-condition">

          <p>
            5. Todos los aceites, aromas, elementos textiles y otros productos
            necesarios para la sesión de masaje serán siempre aportados por
            HAMMAM AL ÁNDALUS (cumpliendo los más altos estándares de salud y
            compromiso ambiental) declinando la utilización previa y expresa de
            Hammam Al Ándalus
          </p>
        </div>
        <button class="btn-outline-dark" @click="onClickBack">Atrás</button>
        <button
          class="btn-fill-dark"

          @click="onClickNext"
        >
          Siguiente
        </button>
      </b-card>
      <b-card v-if="current_step == 3" class="card-style">
        <b-card-title
          ><b-icon icon="file-text-fill"></b-icon>Términos y condiciones de uso
        </b-card-title>
        <div class="single-condition">
          <input
            type="checkbox"
            class="checkbox"
            v-model="checkedConsentFirst"
          /><p>Quiero recibir las mejores ofertas y novedades de Hammam Al Ándalus.</p>
        </div>
        <!-- <div class="single-condition">
          <p>
            Utilización de los datos personales (imagen) mediante fotografías y
            material audiovisual, facilitados dentro de la relación con la
            entidad, filmaciones destinadas a la difusión comercial, impresión
            en revistas o publicaciones relacionadas con nuestro sector
          </p>

        </div> -->
        <div class="single-condition">
          <input
            type="checkbox"
            class="checkbox"
            v-model="checkedConsentSecond"
          /> <p>Acepto todos los <a href="https://hammamalandalus.com/proteccion-de-datos/" target="_blank">terminos y condiciones</a> de Hammam Al Ándalus</p>
        </div>
        <div class="single-condition">
          <p v-show="showAlertConsent" style="color: red; font-weight: bold;" >Debe aceptar las condiciones para poder continuar.</p>
        </div>
        <button class="btn-outline-dark" @click="onClickBack">Atrás</button>
        <button class="btn-fill-dark" @click="onClickFinal">
          Aceptar 
        </button>
      </b-card>
    </div>
  </article>
  </div>
</template>
<script>
export default {
  name: "SimpleStepper",
  props: {
    legalConditions: Boolean,
  },
  data() {
    return {
      current_step: 1,
      max_step: 3,
      checkedRights: false,
      checkedTermsConditionsFirst: false,
      checkedTermsConditionsSecond: false,
      checkedPhysicalCondition: false,
      checkedClothing: false,
      checkedSpace: false,
      checkedAmbience: false,
      checkedMaterial: false,
      checkedConsentFirst: false,
      checkedConsentSecond: false,
      showAlertConsent: false,
    };
  },
  computed: {
    progress() {
      return Math.round(100 / this.max_step) * this.current_step;
    },
  },
  methods: {
    onClickNext() {
      this.current_step++;
    },
    onClickBack() {
      this.current_step--;
    },
    onClickFirst() {
      this.current_step = 1;
    },
    async onClickFinal() {
      if (this.checkedConsentSecond == true){
            this.$emit("send-success");  
      } else {
        this.showAlertConsent = true;
      }

    },
    closeModal() {
      this.$emit("close-modal")
    }
  },
};
</script>

<style lang="scss" scoped>
article {
  display: flex;
  justify-content: center;
  position: relative;
  top: 50px;
}
.card {
  width: 60%;
  margin: 4rem 0rem;
  max-height: 80vh;
  overflow: auto;

}
.card-style {
  width: 100%;
  margin: 0 auto;
  .card-body {
    padding: 4rem;
    .card-title {
      margin-bottom: 3rem;
      font-size: 20px;
    }
  }
  .single-condition {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 4rem;
  }
}

input[type="checkbox"] {
  margin-right: 2rem;
  display: flex;
  width: auto;
}

p {
  margin: 0 !important;
  font-family: Antwerp;
}

.row {
  margin-bottom: 5rem;
}

.btn-outline-dark {
  float: left;
  margin-top: 3rem;
}

.btn-fill-dark {
  float: right;
  margin-top: 3rem;
}

.b-icon {
  margin-right: 1rem;
}

.bi-x-square {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 42px;
  right: -11px;
  cursor: pointer;
  background: antiquewhite;
  z-index: 130;
  &:hover {
    background: rgba(255, 117, 98, 0.822);
  }
}
@media (max-width: 600px) {
  .card {
    width: 95% !important;

  }

  .card-style{
    margin: 0px !important;
  }

  .card-style .card-body{
    padding: 0px !important;
  }

  .card-title{
    margin-left: 15px  !important;
    margin-right: 15px  !important;
  }

  .card-style .single-condition{
    margin-left: 15px  !important;
    margin-right: 15px  !important;
  }
}
</style>

