<template>
  <div>
    <div class="nav bg-hammam">
      <input type="checkbox" id="nav-check">
      <div class="nav-header">
        <div class="nav-title">
          Hammam
        </div>
      </div>
      <div class="nav-btn">
        <label for="nav-check">
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>
      
      <div class="nav-links">
        <a v-if="role !== 'employee'" href="/dashboard_al">Alertas</a>
        <a href="/dashboard_dir">Recepción</a>
        <a v-if="role !== 'employee'" href="/dashboard_gs">Gestión Servicios</a>
        <a v-if="role !== 'employee'" href="/dashboard_em">Equipos Masajistas</a>
        <a href="/dashboard_cm">Calendario</a>
        
      </div>
      <div class="nav-links">
        <a @click="logout" class="logout-btn">Cerrar Sesión</a>
      </div>
    </div>
  </div>
</template>

<script>

import { API_INFO } from "@/constants.js";
import axios from "axios";
import delay from 'delay';

export default {
  data: () => ({
    role: localStorage.role
  }),
  methods: {
    async logout() {
      const options = {
        headers: { Authorization: "JWT " + localStorage.accessToken },
      };
      try {
        const resp = await axios.post(API_INFO + "/logout/", "", options);
        if (resp.status === 200) {
         
          localStorage.clear();
          this.$router.push('/dashboard/login')
        }
      } catch (e) {
        console.log(e);
      }
    },
  }
};
</script>

<style scoped>
.title{
  height: auto !important;
}
.nav {
  height: 50px;
  width: 100%;
  background-color: #e18435;
  position: relative;
  justify-content: flex-end;
  z-index: 100;
}

.nav > .nav-header {
  margin-right: auto;
}

.nav > .nav-header > .nav-title {
  display: inline-block;
  font-size: 22px;
  color: #fff;
  padding: 10px 10px 10px 10px;
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: inline;
  float: right;
  font-size: 18px;
}

.nav > .nav-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #efefef;
  font-size: 1em;
}

.nav > .nav-links > a:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.nav > #nav-check {
  display: none;
}

.logout-btn{
  margin-left: 35px !important;
  color: #eaeaea !important;

}

@media (max-width:600px) {
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }
  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #333;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
  }
  .nav > .nav-links > a {
    display: block;
    width: 100%;
  }
  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .nav > #nav-check:checked ~ .nav-links {
    height: auto;
    overflow-y: auto;
  }

  .logout-btn{
    margin-left: 0px !important;

  }
}
</style>
