<template>
  <div v-if="showLoading === true" class="up-modal">
    <b-row class="justify-content-center card-row">
      <b-col cols="5" class="p-0 justify-content-center d-flex">
        <b-col 
          cols="12"
          class="loading-card text-center"
        >
          <p v-if="text">{{text}}</p>
          <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { API_INFO } from "@/constants.js";
import axios from "axios";

export default {

  props: {
    showLoading: Boolean,
    text: String,

  },
  data() {

  },
  mounted() {
    
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.card-row {
  position: relative;
  top: 45%;

  // max-height: 84vh;
  // overflow: auto; 
}

.loading-card {
  background:rgb(241, 241, 241);
  padding: 20px !important;
  width: 50px;
}



</style>

