<template>
  <div v-if="showModal === true" class="up-modal" style="right: 0px">
    <b-row class="justify-content-center card-row">
      <!-- <b-icon-x-square @click="closeModal" /> -->
      <b-col cols="10" class="p-0 justify-content-center d-flex">
        <b-col 
          cols="12"
          class="employee-card pt-4"
        >
          <h3 class="mt-4">Seleccione un centro Hammam:</h3>
          <b-row class="mt-4 justify-content-center">
            <b-col cols="9">
              <div v-if="centers !== ''">
                  <b-form-select v-model="selectedCenter" size="lg">
                    <b-form-select-option value="null">Seleccionar centro</b-form-select-option>
                    <b-form-select-option 
                      v-for="i in centers"
                      :key="i.id"
                      :value="i.id"
                    >
                      {{i.name}}
                    </b-form-select-option>
                  </b-form-select>
                </div>
            </b-col>
          </b-row>
          <b-row class="mt-4 justify-content-center">
            <b-col cols="10" class="d-flex justify-content-center">
              <b-button @click="applyFilter" class="mt-5 mx-4" variant="success">
                <b-icon-check2-square class="mr-3" />
                Aceptar
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { API_INFO } from "@/constants.js";
import axios from "axios";

export default {
  
  props: {
    showModal: Boolean
  },
  data() {
    return {
      centers: "",
      selectedCenter: "null"
    };
  },
  mounted() {
    this.getCenters()
  },
  methods: {
    closeModal() {
      this.$emit("close-modal")
    },
    applyFilter() {      
      this.$emit("apply-filter", this.selectedCenter)
      this.closeModal()
    },
    async getCenters() {
      const options = {
        headers: { 
          Authorization: "JWT " + localStorage.accessToken,
        },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/dashboard/centers",
          options
        );
        if (resp.status === 200) {
          this.centers = resp.data
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-row {
  position: relative;
  top: 20vh;
}

.employee-card {
  background:rgb(241, 241, 241);
  border-radius: 15px;
  border: 4px solid rgb(151, 159, 173);
  min-height: 270px;
  width: 25vw;
  p {
    font-weight: 700;
    margin-bottom: 10px;
  }
  h3 {
    position: relative;
    right: -8%;
  }
}

.bi-x-square {
  width: 34px;
  height: 34px;
  position: absolute;
  top: -14%;
  right: 45px;
  cursor: pointer;
  background: antiquewhite;
  z-index: 130;
  &:hover {
    background: rgba(255, 117, 98, 0.822);
  }
}
button {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
  position: relative;
  right: -30%;
  top: -20%;
  @media (max-width: 699px) {
    right: -12%;
  }
}

</style>
