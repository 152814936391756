import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueHead from "vue-head";
import $ from "expose-loader?exposes[]=$&exposes[]=jQuery!jquery";
import "jquery-ui";

// CSS
import "./assets/css/bootstrap.min.css";
// import './assets/css/owl.carousel.min.css'
// import './assets/css/owl.theme.default.min.css'
// import './assets/css/magnific-popup.css'
// import './assets/css/jquery-ui.min.css'
// import './assets/css/jquery.fs.wallpaper.css'
import "./assets/css/hammam_brida.css";
import "./assets/css/hammam.css";
import "./assets/css/style.scss";
import "./assets/fonts/fonts.css";
// Javascript
// import './assets/js/jquery.js'
// import './assets/js/jquery-ui.js'--
import "./assets/js/jquery.tubular.1.0.js";
import "./assets/js/jquery.magnific-popup.min.js";
import "./assets/js/jquery.easing.min.js";
import "./assets/js/jquery.fs.wallpaper.min.js";
// import './assets/js/bootstrap.min.js'--
// import './assets/js/isotope.pkgd.min.js'--
import "./assets/js/smoothscroll.min.js";
import "./assets/js/scrollreveal.min.js";
import "./assets/js/owl.carousel.min.js";
import "./assets/js/fitvids.min.js";
import "./assets/js/retina.min.js";
// import './assets/js/modernizr.custom.js'--
import "./assets/js/hammam.nav.js";
import "./assets/js/calendario.js";
// import './assets/js/datos-reserva-tarjeta.js'
import "./assets/js/hammam.js";
// import './assets/js/servicios.js'

Vue.use(VueHead);
Vue.config.productionTip = false;
window.jQuery = window.$ = $;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
