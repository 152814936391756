<template>
  <div v-if="showCard === true" class="up-modal" style="right: 0px">
    <b-row class="justify-content-center card-row">
      <b-icon-x-square @click="closeModal" />
      <b-col cols="5" class="p-0 justify-content-center d-flex">
        <b-col 
          cols="12"
          class="employee-card pt-4"
        >
          <h3 class="mt-4">{{title}}</h3>
          <b-row class="mt-4 justify-content-center">
            <b-col cols="5" class="d-flex justify-content-center">
              <b-button @click="saveChanges" class="mt-5 mx-4" variant="success">
                <b-icon-check2-square class="mr-3" />
                Guardar Cambios
              </b-button>
            </b-col>

            <b-col cols="5" class="d-flex justify-content-center">
              <b-button @click="closeModal" class="mt-5 mx-4" variant="warning">
                
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { API_INFO } from "@/constants.js";
import axios from "axios";

export default {

  props: {
    title: String,
    showCard: Boolean
  },
  data() {

  },
  mounted() {
    
  },
  methods: {

    closeModal() {
      this.$emit("close-modal")
    },
    saveChanges() {
      this.$emit("save-changes")
    },
  },
};
</script>

<style lang="scss" scoped>
.card-row {
  position: relative;
  top: 20vh;
  // max-height: 84vh;
  // overflow: auto; 
}

.employee-card {
  background:rgb(241, 241, 241);
  border-radius: 15px;
  border: 4px solid rgb(151, 159, 173);
  min-height: 270px;
  width: 25vw;
  p {
    font-weight: 700;
    margin-bottom: 10px;
  }
  h3 {
    position: relative;
    right: -8%;
  }
}

.bi-x-square {
  width: 34px;
  height: 34px;
  position: absolute;
  top: -35%;
  right: 45px;
  cursor: pointer;
  background: antiquewhite;
  z-index: 130;
  &:hover {
    background: rgba(255, 117, 98, 0.822);
  }
}
button {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
  position: relative;
  right: -30%;
  top: -20%;
}

</style>
