<template>
  <div v-if="showAlert" class="alert-container">
    <div v-if="typeof message === 'string'">
      <b-alert v-model="showAlert" variant="danger" dismissible fade>
        {{message}}
      </b-alert>
    </div>
    <div v-else>
      <b-alert v-model="showAlert" variant="danger" dismissible fade>
        <h3>Por favor, verifica los siguientes campos:</h3>
        <p v-for="(i, j) in message" :key="j">{{i}}</p>

        <br>
      </b-alert>
    </div>
    
  </div>
</template>

<script>
export default {
  props: {
    message: [String, Array],
    showAlert: Boolean
  }

}
</script>

<style lang='scss' scoped>

.alert-danger {
  position: fixed;
  left: 7%;
  top: 22%;
  width: 40vw;
  margin: 40px;
  border: 2px solid rgb(197, 72, 72);
  font-size: 22px;
}
.alert-container {
  z-index: 100;
  position: fixed;
  left: 24%;
  top: 16%;
  width: 55vw;
  height: 260px;
  background: rgba(199, 199, 199, 0.116);
  backdrop-filter: blur(2px);
}
</style>