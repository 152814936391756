<template>
  <b-col xs="12" sm="6" md="6" lg="4" class="tarjetas-item">
    <a class="anadir" title="Pulsa para Añadir a la cesta">
      <div class="imagen">
        <img :src="service.image" class="img-responsive" />
      </div>
    </a>
    <div class="texto text-center anadir">
      <h2 style="margin-bottom: 10px;">
        {{ service.name }}
      </h2>
      <h2 style="font-size: 20px;"></h2>
      <p>{{ service.description }}</p>
      <p>PRECIO ESPECIAL DE LANZAMIENTO </p>
    </div>
    <!-- <b-row class="bg-white">
      <b-col cols="4">
        Cantidad:
      </b-col>
      <b-col cols="8">
        <b-form-select @change="changeSelect" class="form-control margin_top_0" v-model="service.quantity" :options="optionsCount"></b-form-select>
      </b-col>
    </b-row> -->

    <ul class="tarjetas-item-botones list-unstyled">
      <li>
        <span class="precio">{{ formatPrice(service.price) }} €</span>
      </li>
      <li>
        <div class="btn btn-comprar" @click="addToCart">
          Añadir
        </div>
      </li>
    </ul>


      <!-- <b-col cols="12" class="div-full" v-if="showAlert">
              <div class="alert alert-warning margin_top" role="alert" >
                Seleccione la cantidad
              </div>
      </b-col> -->
  
  </b-col>
</template>

<script>
export default {
  data: () => (
    {
    defaultServiceImage:
      "https://halmmareservas.hammamalandalus.com/imagenes_servicios/halmma60.jpg",
    defaultServiceImage1:
      "https://post.healthline.com/wp-content/uploads/2020/09/stone_massage_woman-732x549-thumbnail-732x549.jpg",
    optionsCount: [
          { value: '1', text: '1' },
          { value: '2', text: '2' }
    ],
    showAlert: false
  }
  ),
  props: {
    service: Object,
  },
  methods: {
    changeSelect(){
      this.showAlert = false;
    },
    addToCart() {
      /*this.$emit("add-to-cart", {
          id: this.service.id,
          name: this.service.name,
          price: this.service.price
      });*/
      this.showAlert = false;
        this.service.quantity = 1
        this.$emit("add-to-cart", {
          id: this.service.id,
          name: this.service.name,
          price: this.service.price,
          quantity: this.service.quantity
        });
      
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  },
};
</script>

<style lang="scss" type="text/css" scoped>
ul {
  margin-top: 0;
  margin-bottom: 0px !important;
}
p,
select {
  font-family: "Chap", Helvetica, Arial, sans-serif;
}

.tarjetas-item {
  padding-right: 1rem;
}

.margin_top_0{
  margin-top: 0px !important;
}
</style>
