<template>
  <div id="wrapper">

    <Header
      @login="openLoginModal"
      @logout="logout"
      :logoutButton.sync="logoutButton"
      :links="centerLinks"
    />
    <b-row>
      <b-col class="justify-content-center div-pay" v-if="reservationShow">
        <!--<b-icon-check2-circle class="mr-3" />-->


        <h3 class="mt-4"><strong>Detalle de la reserva:</strong></h3>
        
        <p>Fecha: {{reservationData.date}}, hora: {{reservationData.time}}</p>
        <p>Ciudad: {{reservationData.locality}}, dirección: {{reservationData.address}}</p>

              <table class="table">
                <thead>
                  <tr>
                    <th class="td-servicio">Servicios:</th>
                    <th class="td-servicio">Cantidad:</th>
                    <th class="td-servicio">Precio:</th>
                  </tr>

                </thead>
                <tbody>
                  <tr v-for="item in services" :key="item.id">
                    <td  class="td-servicio">- {{item[0]}}</td>
                    <td  class="td-servicio">{{item[1]}}</td>
                    <td  class="td-servicio">{{formatPrice(item[2])}} €</td>

                  </tr>

                  <tr >
                    <td  class="td-servicio">- Desplazamiento</td>
                    <td  class="td-servicio">1</td>
                    <td  class="td-servicio">{{ formatPrice(reservationData.displacement_fee)}} €</td>

                  </tr>
                  
                </tbody>
              </table>

         <p><strong>Total a pagar:</strong> {{ formatPrice(reservationData.total)}} €</p>
        <p v-html="htmlBtnPay"></p>
        <p ><button class="btn btn-danger" type="button" @click="cancelReservation(reservationData.id)">Cancelar</button></p>
  
      </b-col>

      <b-col class="justify-content-center div-pay" v-else>
        <b-icon-alarm  class="alert-icon" />


        <h2>No tiene ninguna reserva pendiente para pago.</h2>
        <p>Puede ir a nuestra página de <a href="/">inicio</a> para seguir viendo nuestros servicios. </p>
      </b-col>
    </b-row>

    <ModalLogin 
      :my-modal.sync="modal" 
      @login-success="loginSuccess" 
      @data-client="dataClient"
    />
    
    <Footer
      :links="centerLinks"
    />
    <ModalAlert
        :showCard="showMsg"
        :title="messageAlert"
        :varian="varianAlert"
        @close-modal="() => showMsg = false"
      />

    <AlertLoading
      :showLoading="loading"
    />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import ModalLogin from "@/components/ModalLogin.vue";
import AlertLoading from "@/components/alerts/AlertLoading.vue"
import ModalAlert from "@/components/alerts/ModalAlert.vue";
import { getCenterServices } from "@/api/EventService.js";
import { API_INFO, serv } from "@/constants.js";

import axios from "axios";
import delay from "delay";

export default {
  name: "CartDetail",
  components: {
    ModalLogin,
    Header,
    Footer,
    AlertLoading,
    ModalAlert
  
  },
  data() {
   return {
     showMsg: false,
     messageAlert: '',
     varianAlert: '',
      logoutButton: false,
      modal: "",
      loggedAlert: false,
      reservationShow: true,
      reservationData: {},
      services: [],
      htmlBtnPay: "",
      loading: false,
      idparam: '',
      linksGranada: [
        "https://granada.hammamalandalus.com/naturaleza-interior/",
        "https://granada.hammamalandalus.com/carta-de-servicios/",
        "https://granada.hammamalandalus.com/promociones/",
        "https://granada.hammamalandalus.com/viajero-hammam/",
        "https://granadareservas.hammamalandalus.com/seleccion_tarjeta.php",
        "https://granadareservas.hammamalandalus.com/datos-reserva.php",
        "Granada"
      ],
      linksMalaga: [
        "https://malaga.hammamalandalus.com/naturaleza-interior/",
        "https://malaga.hammamalandalus.com/carta-de-servicios/",
        "https://malaga.hammamalandalus.com/promociones/",
        "https://malaga.hammamalandalus.com/viajero-hammam/",
        "https://malagareservas.hammamalandalus.com/seleccion_tarjeta.php",
        "https://malagareservas.hammamalandalus.com/datos-reserva.php",
        "Málaga"
      ],
      linksPalma: [
        "https://palma.hammamalandalus.com/naturaleza-interior/",
        "https://palma.hammamalandalus.com/carta-de-servicios/",
        "https://palma.hammamalandalus.com/promociones/",
        "https://palma.hammamalandalus.com/viajero-hammam/",
        "https://palmareservas.hammamalandalus.com/seleccion_tarjeta.php",
        "https://palmareservas.hammamalandalus.com/datos-reserva.php",
        "Palma"
      ],
      linksMadrid: [
        "https://madrid.hammamalandalus.com/naturaleza-interior/",
        "https://madrid.hammamalandalus.com/carta-de-servicios/",
        "https://madrid.hammamalandalus.com/promociones/",
        "https://madrid.hammamalandalus.com/viajero-hammam/",
        "https://madridreservas.hammamalandalus.com/seleccion_tarjeta.php",
        "https://madridreservas.hammamalandalus.com/datos-reserva.php",
        "Madrid",
        "false"
      ],
      linksCordoba: [
        "https://cordoba.hammamalandalus.com/naturaleza-interior/",
        "https://cordoba.hammamalandalus.com/carta-de-servicios/",
        "https://cordoba.hammamalandalus.com/promociones/",
        "https://cordoba.hammamalandalus.com/viajero-hammam/",
        "https://cordobareservas.hammamalandalus.com/seleccion_tarjeta.php",
        "https://cordobareservas.hammamalandalus.com/datos-reserva.php",
        "Córdoba",
        "false"
      ],
    };
  },
  created() {
    this.idparam = this.$route.params.id;
    //console.log(this.idparam)
    if (this.idparam != '' && this.idparam != undefined){
      this.idparam = atob(this.idparam)
    }
    
    
    const tokenExist = localStorage.getItem("accessToken");
    if (tokenExist) {
      this.logoutButton = true;
    }
    const reservation = localStorage.getItem("reservation");
    //console.log(reservation)
    if(reservation){
        this.takeReservation(reservation);
        //console.log('entro 1')
    } else if (this.idparam != '' && this.idparam != undefined) {
        this.takeReservation(this.idparam);
    } else {
      this.reservationShow =  false;
    }
    
  },
  computed: {
    centerLinks() {
      let url = window.location.search
      let centro = url.split("&")[0];

      switch (centro.toLowerCase()) {
        case "?granada":
          return this.linksGranada
        case "?malaga":
          return this.linksMalaga
        case "?palma":
          return this.linksPalma
        case "?madrid":
          return this.linksMadrid
        case "?cordoba":
          return this.linksCordoba
        default:
          return this.linksMadrid
      }
    }
  },
  methods: {
  
    async loginSuccess() {
      this.loggedAlert = true;
      this.modal = "";
      this.logoutButton = true;
      this.callGeoData();
      await delay(3600);
      this.loggedAlert = false;
    },
    async callGeoData() {
      const options = {
        headers: { Authorization: "JWT " + localStorage.accessToken },
      };
      try {
        const resp = await axios.get(
          API_INFO + "/clients/my-geo-data/",
          options
        );
        if (resp.status === 200) {
          // console.log(resp, "response");
          //console.log(resp.data)
          localStorage.setItem("geo-data", resp.data);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async logout() {
      const options = {
        headers: { Authorization: "JWT " + localStorage.accessToken },
      };
      try {
        const resp = await axios.post(API_INFO + "/logout/", "", options);
        if (resp.status === 200) {
          this.logoutButton = false;
          localStorage.clear();
          this.logoutAlert = true;
          await delay(4200);
          this.logoutAlert = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async dataClient(item){
      this.first_name = item.first_name
      this.last_name = item.last_name
      this.idClient = item.id
      // console.log(this.first_name)
    },
    openLoginModal() {
      this.modal = "login";
    },
    async takeReservation(id) {
      this.reservationShow =  true;
      //console.log(id)
      try {
        const resp = await axios.get(
          API_INFO + "/reservations/"+id+"/detail-process/",
        );
        if (resp.status === 200) {
       
            this.reservationData = resp.data
            this.services = this.reservationData.services
            this.takePay();
        } else {
            this.reservationShow =  false;
        }
          
        
      } catch (e) {
        console.log(e);
      }
    },
    async takePay(){
        try {
          const response = await axios.get(API_INFO + "/reservations/"+this.reservationData.id+"/get_info_redsys/");
          if (response.status === 200) { 
            //console.log(response)
            this.htmlBtnPay = response.data
          }
          
        } catch (err) {
          console.error(err)
          return false;
        }
    },
    async cancelReservation(id){
      this.loading = true;
      try {
          const response = await axios.put(API_INFO + "/reservations/"+this.reservationData.id+"/cancel/", {status: 4});
          if (response.status === 200) { 
            //console.log(response)
            localStorage.removeItem("reservation");
            localStorage.removeItem("cart_code");
            localStorage.removeItem("error_tvp");
            
            this.loading = false;
            this.showMsg = true;
            this.messageAlert = 'Reserva cancelada.'
            this.varianAlert = 'success';
            this.$router.push({ path: '/' })

          }
          
        } catch (err) {
          this.loading = false;
          this.showMsg = true;
          this.messageAlert = 'No se pudo cancelar esta reserva.'
          this.varianAlert = 'warning';
          return false;
        }
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  },
};
</script>

<style lang="scss">
.alert-icon{
  font-size: 6em !important;
  color: 	#ffcc00 !important;
}
.up-modal {
  width: 100%;
  height: 100%;
  background: #0000005e;
  backdrop-filter: blur(4px);

  position: fixed;
  z-index: 1100;
  top: 0;
  align-items: flex-end;
  display: block;
}
.row-modal {
  position: relative;
  top: 20%;
  justify-content: center;
}
.col-modal {
  background: #f3f4f2;
  border: none;
  max-width: 600px;
  min-width: 300px;
  @media (max-width: 1200px) {
    max-width: 700px;
    min-width: 300px;
  }
}
.login {
  justify-content: end;
  display: flex;
  .login-btn1 {
    /*   position: absolute; */
    top: 140px;
    right: 25%;
  }
}
.alert-success {
  position: relative;
  left: 50%;
}

.div-pay{
  text-align: left !important;
  padding: 60px  !important;
}
</style>
