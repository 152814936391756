<template>
  <div>
    <nav class="navbar navbar-dark navbar-fixed-top" role="navigation">
      <div class="container-fluid" style="height: 75px;">
        <div class="navbar-header page-scroll">
          <button
            type="button"
            class="navbar-toggle"
            data-toggle="collapse"
            data-target=".navbar-main-collapse"
            @click="showNav"
          >
            <!-- <i class="fa fa-bars"></i> -->
            <b-icon-menu-button-wide />
          </button>
          <a class="navbar-brand" href="https://madrid.hammamalandalus.com/">
            <span class="logo" id="anima_hamman">
              <img src="../assets/img/logo.png" alt="" />
            </span>
          </a>
        </div>
        <div
          class="navbar-collapse navbar-main-collapse text-center collapse"
          :class="collapseNav ? '' : 'in'"
        >
          <ul class="nav navbar-nav nav-center">
            <li class="hidden active">
              <a href="#page-top"></a>
            </li>
            <li>
              <a :href="links[0]"
                >Naturaleza interior</a
              >
            </li>
            <li>
              <a :href="links[1]"
                >Carta de servicios</a
              >
            </li>
            <li>
              <a :href="links[2]"
                >Promociones</a
              >
            </li>
            <li><a :href="links[4]">Tarjeta Regalo</a></li>
            <li>
              <a :href="links[3]"
                >Viajero Hammam</a
              >
            </li>
            <li class="menu-reservas">
              <a :href="links[5]">RESERVAS ONLINE</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <header
      id="myCarousel"
      class="carousel slide carousel-fade intro-slider-half"
    >
      <div class="carousel-inner">
        <div class="item active">
          <div class="fill bg-img"></div>
          <div class="overlay"></div>
          <div class="carousel-caption">
            <div class="intro-body">
              <h1 class="brand-heading especial">
                HALMMA
              </h1>
              <p>Reserva tu masaje a domicilio</p>
            </div>
            <!-- <div class="login-header" style="visibility: collapse">
              <p v-show="!logoutButton" style="visibility: collapse">¿Ya eres usuario?</p>
              <button
                v-show="logoutButton"
                @click="logout"
                class="btn-outline-dark"
              >
                CERRAR SESIÓN
              </button>
              <button
                v-show="!logoutButton"
                @click="login"
                class="btn-outline-light"
              >
                ENTRA
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  props: {
    logoutButton: Boolean,
    links: Array
  },
  data: () => ({
    collapseNav: true,
  }),
  methods: {
    showNav() {
      this.collapseNav = !this.collapseNav;
    },
    login() {
      this.$emit("login");
    },
    logout() {
      this.$emit("logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-header {
  position: absolute;
  left: 20px;
  width: 95%;
  top: 6px;
  @media (min-width: 1025px) {
    left: 60px;
  }
  @media (max-width: 767px) {
    top: 3px;
  }
  @media (max-width: 510px) {
    left: 5px;
  }
}
button {
  font-size: 20px;
}
.collapse.in {
  display: block !important;
  position: relative;
  top: 74px;
}
.navbar-collapse {
  position: relative;
  top: -15px;
}
h1 {
  position: relative;
  top: -25px;
  @media (max-width: 1025px) {
    top: -5px;
  }
}
.login-header {
  position: relative;
  top: 25px;
  display: none;
  // @media (max-width: 768px) {
  //   top: 130px;
  // }
}
.intro-body {
  @media (max-width: 768px) {
    top: 100px !important;
  }
  p {
    font-size: 20px;
    position: relative;
    top: -10px;
    font-family: "Chap", Helvetica, Arial, sans-serif;
    @media (max-width: 768px) {
      top: 90px;
    }
  } 
}
</style>
