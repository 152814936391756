<template>
  <div class="full-container">
    <h2>Hammam</h2>
    <div class="container" id="container">
      <div class="form-container sign-in-container">
        <form action="#">
          <h1>Iniciar sesión</h1>

          <input v-model="email" type="email" placeholder="usuario" />
          <input v-model="password" type="password" placeholder="contraseña" />
		  
		  <b-row class="div-full">
            <b-col cols="12" class="div-full" v-if="success">
              <div class="alert alert-success margin_top" role="alert" >
                {{msgAlert}}
              </div>
            </b-col>
          </b-row>
          <b-row class="div-full">
            <b-col cols="12" class="div-full" v-if="error">
              <div class="alert alert-warning margin_top" role="alert" >
                {{msgAlert}}
              </div>
            </b-col>
          </b-row>
		  
          <button @click="login" type="button"><b-spinner v-if="loading" small label="Spinning"></b-spinner> Ingresar</button>
        </form>
      </div>
      <div class="overlay-container">
        <div class="overlay">

          <div class="overlay-panel overlay-right">
            <h1>HOLA !</h1>
            <p>para poder iniciar sesión necesita los datos de acceso.</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import axios from "axios";
import delay from "delay";
import { API_INFO } from "@/constants.js";

export default {
  

  data() {
      return {
		email: "",
		password: "",
		msgAlert: '',
		loading: false,
		error: false,
    	success: false,
		user: {
			id: 0,
			email: '',
			first_name: '',
			last_name:'',
			role: ''
		}
      }
    },
methods: {

    async login() {
	  this.success = false;
	  this.error = false;
	  if(this.email == '' || this.password == ''){
		  this.msgAlert = 'Por favor revise los datos ingresados.'
		  this.error = true;
		  return false;
	  }


	  this.loading = true;
      const options = {
        headers: { "Content-Type": "application/json" },
      };
      try {
        const resp = await axios.post(
          API_INFO + "/login/",
          {
            email: this.email,
            password: this.password,
          },
          options
        );
        if (resp.status === 200) {
			if(resp.data.role =='client'){
				this.error = true;
				this.msgAlert = 'Su usuario no tiene permisos para entrar al Dashboard.'
				this.loading = false;
			} else {
				this.user = {
					id: resp.data.client_id,
					email: resp.data.email,
					first_name: resp.data.first_name,
					last_name: resp.data.last_name,
					role: resp.data.role
				}
				localStorage.clear();
				localStorage.setItem("accessToken", resp.data.access);
				localStorage.setItem("refreshToken", resp.data.refresh);
				localStorage.setItem("role", resp.data.role);
				localStorage.setItem("centerId", resp.data.center_id);
				localStorage.setItem("id", resp.data.id);
				localStorage.setItem("first_name", resp.data.first_name);
				localStorage.setItem("last_name", resp.data.last_name);
				this.success = true;
				this.msgAlert = 'Datos correctos.'
				this.loading = false;
				this.$router.push({ path: '/dashboard_al' })
			}

          

        }
      } catch {
        this.error = true;
		this.loading = false;
		this.msgAlert = 'Datos incorrectos, intentelo de nuevo.'
      }
    },
    async resetPass(email) {
      const options = {
        headers: { "Content-Type": "application/json" },
      };
      try {
        const resp = await axios.post(
          API_INFO + "/reset-password/",
          {
            email: this.email,
          },
          options
        );
        console.log(resp, "response");
        if (resp.status === 200) {
          this.success = true;
          await delay(5000);
          this.success = false;
        }
      } catch (e) {
        if (e.response.status === 400) {
          this.error = true;
          await delay(4500);
          this.error = false;
        }
      }
    },

  },
}
</script>

<style lang='scss' scoped>
.margin_top{
	margin-top: 10px;
}
* {
	box-sizing: border-box;
}

body {
	background: #f6f5f7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;
}

h1 {
	font-weight: bold;
	margin: 0;
}

h2 {
	text-align: center;
}

p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

span {
	font-size: 12px;
}

a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

button {
	border-radius: 20px;
	border: 1px solid #cf670d;
	background-color: #e67e22;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.full-container{
	position: relative;
	width: 100% !important;
}

.container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background: #f79540;
	background: -webkit-linear-gradient(to right, #753904, #e67e22);
	background: linear-gradient(to right, #753904, #e67e22);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.alert-success{
	left: 0%;
}

@media (max-width: 600px) {
	.overlay{
		visibility: hidden;
	}
	.sign-in-container{
		width: 100%;
	}

	.container{
		width: 350px;
	}
}

</style>
