<template>
  <div v-if="showModal === true" class="up-modal" style="right: 0px">
    <b-row class="justify-content-center card-row">
      <b-icon-x-square @click="closeModal" />
      <b-col cols="6" class="p-0 justify-content-center d-flex">
        <b-col 
          cols="12"
          class="employee-card pt-4"
        >
          <h2 class="mt-4">Filtrar reservas por:</h2>
          <b-row class="mt-4 justify-content-center">
            <b-col style="top: 20px" cols="5">
              <p class="mb-4">Horarios de empleados:</p>
              <b-form-select
                v-model="selectedTeam"
                size="lg"
                class="my-3 persons-input input"
                @input="restartEmployee"
              >
                <b-form-select-option
                  value="null"
                  class="placeholder-font"
                >
                  Seleccionar equipo
                </b-form-select-option>
                <b-form-select-option
                  value="all"
                  class="placeholder-font"
                >
                  Todos los empleados
                </b-form-select-option>
                <b-form-select-option
                  v-for="i in teams"
                  :key="i.id"
                  :value="i.name"
                >
                {{i.name}}
                </b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col style="top: 20px" cols="5">
              <p class="mb-4">Reservas:</p>
              <b-form-select
                v-model="selectedEmployee"
                size="lg"
                class="my-3 persons-input input"
                @input="restartTeam"
              >
                <b-form-select-option
                  value="null"
                  class="placeholder-font"
                >
                  Seleccionar empleado
                </b-form-select-option>
                <b-form-select-option
                  value="all"
                  class="placeholder-font"
                >
                  Todos las reservas
                </b-form-select-option>
                <b-form-select-option
                  v-for="i in employees"
                  :key="i.id"
                  :value="i.id"
                >
                {{i.name}}
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="mt-4 justify-content-center">
            <b-col cols="10" class="d-flex justify-content-center">
              <b-button @click="saveChanges" class="my-5 mx-4" variant="success">
                Aceptar
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { API_INFO } from "@/constants.js";
import axios from "axios";

export default {
  
  props: {
    showModal: Boolean,
    employees: Array,
    teams: Array
  },
  data() {
    return {
      selectedTeam: "null",
      selectedEmployee: "null"
    };
  },
  mounted() {
    
  },
  methods: {
    closeModal() {
      this.$emit("close-modal")
      this.selectedTeam = "null",
      this.selectedEmployee = "null"
    },
    filterBy() {
      this.$emit("filter-by",{
        team: this.selectedTeam,
        employee: this.selectedEmployee
      })
      this.closeModal()
    },
    saveChanges() {
      if(this.selectedTeam !== "null" & this.selectedEmployee !== "null") {
        alert("Seleccione un solo parámetro para filtrar.")
      } else if (this.selectedTeam === "null" & this.selectedEmployee === "null") {
        alert("Seleccione un parámetro para filtrar.")
      } else {
        this.filterBy()
      }
    },
    restartEmployee() {
      let keept = this.selectedTeam
      if(this.selectedTeam !== "null") {
        this.selectedEmployee = "null"
      }
      this.selectedTeam = keept
    },
    restartTeam() {
      let keept = this.selectedEmployee
      if(this.selectedEmployee !== "null") {
        this.selectedTeam = "null"
      }
      this.selectedEmployee = keept
    }
  },
};
</script>

<style lang="scss" scoped>
.card-row {
  position: relative;
  top: 8vh;
}

.employee-card {
  background:rgb(241, 241, 241);
  border-radius: 15px;
  border: 4px solid rgb(151, 159, 173);
  min-height: 270px;
  width: 25vw;
  p {
    font-weight: 700;
    margin-bottom: 10px;
  }
  h2 {
    position: relative;
    right: -8%;
  }
}

.bi-x-square {
  width: 34px;
  height: 34px;
  position: absolute;
  top: -11%;
  right: 45px;
  cursor: pointer;
  background: antiquewhite;
  z-index: 130;
  &:hover {
    background: rgba(255, 117, 98, 0.822);
  }
}
button {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
  position: relative;
  right: -30%;
}

</style>
